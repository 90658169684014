<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div class="A4" id="browserA">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo" >
                    <img *ngIf="  !printer.logo_hidden" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name" *ngIf="!printer.name_store_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                    <div class="header-store-info-contact">
                        <div class="header-store-info-contact-address" *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                        <div class="header-store-info-contact-phone" *ngIf="!printer.phone_number_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                    </div>
                    <div class="header-store-info-invoice" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{ tempBill.title | translate | uppercase }} {{printer.name | translate | uppercase }}</div>
                </div>
            </div>
            <div class="header-invoice">
                <div class="header-invoice-line">
                    <div  *ngIf="!printer.bill_code_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.bill_code}}</b>
                    </div>
                    <div  *ngIf="!printer.date_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div *ngIf="!printer.customer_name_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Name" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['customer'].name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" class=" header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['employee'].name}}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div  class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container  *ngIf="!printer.customer_address_hidden">
                            {{'Address' | translate}} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ tempBill['customer'].address}}</b>
                        </ng-container>
                        <br>
                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            {{'Phone number' | translate}} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['customer'].phone}}</b>
                        </ng-container>
                        
                        
                    </div>
                    <div class="header-invoice-line-right" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-a4"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <table>
                <thead>
                    <th style="max-width: 5%; width: 5%"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "No." | translate }}</th>
                    <th style="max-width: 35%; width: 35%; "  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Tên voucher" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15%; text-align: center"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Mã voucher" | translate }}
                    </th>
                    <th style="max-width: 25%; width: 25%; text-align: right"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Unit price" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15; text-align: right"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Amount"| translate }}
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tempBill.bill_details; let i = index">
                        <td style="max-width: 5%; width: 5%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{i + 1}}</td>
                        <td style="max-width: 48%; width: 48%;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                        <td style="max-width: 10%; width: 10%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.unit}}</td>
                        <td style="max-width: 7%; width: 7%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                        <td style="max-width: 15%; width: 15%; text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(item.price)}}
                            <br>
                            <span class="price-origin" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngIf="item.price < item.price_origin">
                                {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                            </span>
                        </td>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="max-width: 15%; width: 15%; text-align: end;">
                            {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                        </td>
                    </tr>
                    <tr>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" colspan="3" style="text-align: center"><b>{{"SubTotal" | translate}}</b></td>
                        <td style="text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.qty_total}}</b></td>
                        <td colspan="2" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.root_total)}}</b>
                        </td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_discount') : !printer.discount_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Discount" | translate}}</td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.discount)}}
                        </td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_fee') : printer.fee_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Fee" |translate}}</td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.fee)}}</td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_tax') : printer.tax_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Tax" | translate}} ({{vhAlgorithm.vhpercent(tempBill.tax/100)}})
                        </td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(tempBill.tax_value)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total" | translate}}</b></td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            <b>{{vhAlgorithm.vhcurrencyunit(tempBill.total_atax)}}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"PAYMENT" | translate}}</b></td>
                        <td colspan="3" style="text-align: end" >
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(tempBill.payment)}}</b>
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_rest') : printer.fee_hidden">
                        <td colspan="3" style="text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"REST" | translate}}</b></td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax - tempBill.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(11000000) }}
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                           {{vhAlgorithm.vhcurrencyunit(71000)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-note" *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_note') : printer.note_hidden">
                {{"Note" | translate}}: {{tempBill.note}}
            </div>
            <div class="content-note">
                <pre  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
           </div>
        </div>
        <div *ngIf="printer.name == 'Warranty, repair'">
            <div class="content-note">
                <div class="content-note-title">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                </div>
                <div class="content-note-content" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    + Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58
                </div>
            </div>
            <div class="content-note">
                <div class="content-note-title">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                </div>
                <div class="content-note-content" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    Trầy xướt nhẹ...
                </div>
            </div>
        </div>
        
        <div class="signature">
            <ng-container>
                <div *ngIf=" printer.name === 'Sales' ? vhAuth.localStorageGET('show_signature_buyer') : printer.signature_buyer_hidden" class="signature-detail" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Buyer'|translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <div *ngIf="printer.name === 'Sales' ? vhAuth.localStorageGET('show_signature_shipper') : printer.signature_creator_hidden " class="signature-detail" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Deliver'|translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <div *ngIf="printer.name === 'Sales' ?vhAuth.localStorageGET('show_signature_creator') : printer.signature_shipper_hidden" class="signature-detail" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by' |translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <!-- <div class="signature-detail"><b>{{'Stocker'|translate}}</b><br><em>({{'Sign, write full name'|translate}})</em></div> -->
                <div *ngIf=" printer.name === 'Sales' ?vhAuth.localStorageGET('show_signature_accountancy') : printer.signature_accountancy_hidden" class="signature-detail" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Chief accountant'|translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
            </ng-container>
        </div>
    </div>
    <div class="A4" id="electron-browserA" style="display: none;">
        <div  >
            <div style=" display: grid;
            grid-template-columns: 7% 93%;">
                <div style="text-align: center;" >
                    <img style="width: 100%;" *ngIf="  !printer.logo_hidden" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div  >
                    <div  *ngIf="!printer.name_store_hidden" style="
                    padding: 2px 16px;"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                    <div style=" display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div  *ngIf="!printer.address_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                        <div  *ngIf="!printer.phone_number_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                    </div>
                    <div style="
                    text-align: center;
                    padding: 2px 0;
                    font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{ tempBill.title | translate | uppercase }} {{printer.name | translate | uppercase }}</div>
                </div>
            </div>
            <div >
                <div style="  display: grid;
                grid-template-columns: 50% 50%;">
                    <div  *ngIf="!printer.bill_code_hidden" style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.bill_code}}</b>
                    </div>
                    <div  *ngIf="!printer.date_hidden" style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div style="  display: grid;
                grid-template-columns: 50% 50%;">
                    <div  *ngIf="!printer.customer_name_hidden" style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Name" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['customer'].name}}</b>
                    </div>
                    <div  *ngIf="!printer.employee_name_hidden" style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['employee'].name}}</b>
                    </div>
                </div>
                <div  style="  display: grid;
                grid-template-columns: 50% 50%;">
                    <div  style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{'Address' | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ tempBill['customer'].address}}</b>
                        <br> {{'Phone number' | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill['customer'].phone}}</b>
                    </div>
                    <div  style="text-align: end;" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-a4"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div >
            <table style="width: 100%;border-collapse: collapse;">
                <thead>
                    <th style="max-width: 5%; width: 5%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"No."|translate}}</th>
                    <th style="max-width: 48%; width: 48%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Product name" | translate}}</th>
                    <th style="max-width: 10%; width: 10%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Unit" | translate}}</th>
                    <th style="max-width: 7%; width: 7%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Quantity" | translate}}</th>
                    <th style="max-width: 15%; width: 15%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Unit price" | translate}}</th>
                    <th style="max-width: 15%; width: 15%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Amount" | translate}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tempBill.bill_details; let i = index">
                        <td style="max-width: 5%; width: 5%; text-align: center; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{i + 1}}</td>
                        <td style="max-width: 48%; width: 48%; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                        <td style="max-width: 10%; width: 10%; text-align: center; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.unit}}</td>
                        <td style="max-width: 7%; width: 7%; text-align: center; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                        <td style="max-width: 15%; width: 15%; text-align: end; border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(item.price)}}
                            <br>
                            <span class="price-origin" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="item.price < item.price_origin">
                                {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                            </span>
                        </td>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="max-width: 15%; width: 15%; text-align: end; border: 1px black solid;padding: 2px;">
                            {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                        </td>
                    </tr>
                    <tr>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center"><b>{{"SubTotal" | translate}}</b></td>
                        <td style=" border: 1px black solid;padding: 2px;text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{tempBill.qty_total}}</b></td>
                        <td colspan="2" style=" border: 1px black solid;padding: 2px;text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.root_total)}}</b>
                        </td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_discount') : !printer.discount_hidden">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Discount" | translate}}</td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.discount)}}
                        </td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_fee') : printer.fee_hidden">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Fee" |translate}}</td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.fee)}}</td>
                    </tr>
                    <tr  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_tax') : printer.tax_hidden">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Tax" | translate}} ({{vhAlgorithm.vhpercent(tempBill.tax/100)}})
                        </td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(tempBill.tax_value)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Total" | translate}}</b></td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{vhAlgorithm.vhcurrencyunit(tempBill.total_atax)}}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"PAYMENT" | translate}}</b></td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" >
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.payment)}}</b>
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_rest') : printer.fee_hidden">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"REST" | translate}}</b></td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax - tempBill.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(11000000) }}
                        </td>
                    </tr>
                    <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="3" style=" border: 1px black solid;padding: 2px;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                           {{vhAlgorithm.vhcurrencyunit(71000)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_note') : printer.note_hidden">
                {{"Note" | translate}}: {{tempBill.note}}
            </div>
            <div >
                <pre  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
           </div>
        </div>
        <div *ngIf="printer.name == 'Warranty, repair'">
            <div >
                <div >
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    + Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58
                </div>
            </div>
            <div >
                <div class="content-note-title">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    Trầy xướt nhẹ...
                </div>
            </div>
        </div>
        
        <div style="padding: 16px;
        display: flex;
        justify-content: space-between;">
            <ng-container>
                <div *ngIf=" printer.name === 'Sales' ? vhAuth.localStorageGET('show_signature_buyer') : printer.signature_buyer_hidden" style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  >{{'Buyer'|translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <div *ngIf="printer.name === 'Sales' ? vhAuth.localStorageGET('show_signature_shipper') : printer.signature_creator_hidden " style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'Deliver'|translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <div *ngIf="printer.name === 'Sales' ?vhAuth.localStorageGET('show_signature_creator') : printer.signature_shipper_hidden" style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  >{{'Prepared by' |translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
                <!-- <div class="signature-detail"><b>{{'Stocker'|translate}}</b><br><em>({{'Sign, write full name'|translate}})</em></div> -->
                <div *ngIf=" printer.name === 'Sales' ?vhAuth.localStorageGET('show_signature_accountancy') : printer.signature_accountancy_hidden" class="signature-detail" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'Chief accountant'|translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em></div>
            </ng-container>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
<ng-container></ng-container>