<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="ios" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div  id="expenses-k80a" class="K80">
        <div class="header" *ngIf="infoStore">
            <div class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store-info">
                <div class="header-store-name"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div class="header-store-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}</div>
            </div>
        </div>

        <div class="content">
            <div class="content-header">
                <div class="content-header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{ printer.name | translate | uppercase}}</b></div>
                <div class="content-header-info">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Pay for liabilities / others'"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Expenses code" | translate}}</b>:
                        {{billInfo.bill_code}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt code" |
                            translate}}</b>: {{billInfo.bill_code}}</div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div class="content-header-info" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> : {{billInfo.employee}}</div>
            </div>


            <div class="content-info-detail">
                <div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Pay for liabilities / others'">
                        {{"Receiver" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'">
                        {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.phone}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.address}}</b>
                    </div>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Total amount" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b></div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
            </div>


            <div class="content-note">
                <div class="content-note-label" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                <div class="content-note-dot"></div>
                <div class="content-note-dot"></div>
                <div class="content-note-dot"></div>
            </div>

            <div class="content-info-barcode">
                <svg id="barcode-expense"></svg>
            </div>

        </div>
        <div class="signature">
            <div *ngIf="printer.name =='Pay for liabilities / others'" class="signature-detail">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver" | translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
            </div>
            <div *ngIf="printer.name =='Receive from receivables / others'" class="signature-detail">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Payer" | translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
            </div>
            <div class="signature-detail"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                    name'|translate}})</em>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
    </div>
    <div id="expenses-electron-k80a" style="padding: 8px; display: none;">
        <div style="border-bottom: 0.1rem black dashed; text-align: center;" *ngIf="infoStore">
            <div style=" padding: 0.1rem 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.header}}</div>
            <div>
                <div><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                        >{{infoStore.company}}</b></div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Phone number"|
                    translate}} : {{infoStore['phone']}}</div>
            </div>
        </div>

        <div style="padding: 4px 0;">
            <div style="border-bottom: 1px black dashed;">
                <div style="text-align: center; "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{ printer.name | translate | uppercase}}</b>
                </div>
                <div style="text-align: start; ">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Pay for liabilities / others'"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Expenses code" | translate}}</b>:
                        {{billInfo.bill_code}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt code" |
                            translate}}</b>: {{billInfo.bill_code}}</div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div style="text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> :
                    {{billInfo.employee}}</div>
            </div>


            <div style="text-align: left">
                <div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  *ngIf="printer.name =='Pay for liabilities / others'">
                        {{"Receiver" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  *ngIf="printer.name =='Receive from receivables / others'">
                        {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Phone number" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.phone}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Address" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.address}}</b>
                    </div>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Total amount" | translate}} :
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b></div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
            </div>


            <div style="text-align: left">
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
            </div>

            <div class="content-info-barcode">
                <svg id="barcode-expense"></svg>
            </div>

        </div>
        <div style="display: flex; justify-content: space-between;padding: 16px;">
            <div *ngIf="printer.name =='Pay for liabilities / others'" style="text-align: center;">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Receiver" | translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  >({{'Sign, write full name'|translate}})</em>
            </div>
            <div *ngIf="printer.name =='Receive from receivables / others'" style="text-align: center;">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  >{{"Payer" | translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{'Sign,
                    write full name'|translate}})</em>
            </div>
            <div style="text-align: center;"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'cashier'|translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  >({{'Sign, write full
                    name'|translate}})</em>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div style=" text-align: center;padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>