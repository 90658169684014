<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="warranty-k57a" class="K57">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div *ngIf="!printer.name_store_hidden" class="header-store-name"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" |
                    translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>
        
        <div class="content">
            <div class="content-header">
                <div class="content-header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{('Warranty, repair invoice' | translate) | uppercase}}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>:
                        {{invoice.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>:
                        {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receptionist" | translate}}</b>:
                        {{invoiceInfo['receptionist'] ? invoiceInfo['receptionist'].name : ''}}
                    </div>
                </div>
            </div>

            <div class="table" *ngIf="invoice_detail.length">
                <table>
                    <thead>
                        <th style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 100% ; word-break: break-all;" colspan="3"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td colSpan="3"
                                    style="width: 100%; max-width: 100% ; white-space: normal;padding-top: 0;">
                                    <ng-container *ngIf="item.combos">
                                        <span  *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 30%; max-width: 30%; text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                                <td style="width: 35%; max-width: 35%; text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span style="text-align: right ;text-decoration: line-through;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 35%; max-width: 35%;text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="width: 30%; max-width: 30%; text-align: left; "
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{item.name}} x{{item.quantity}}
                                </td>
                                <td style="width: 35%; max-width: 35%; text-align: right; "
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                        style="text-align: right ;text-decoration: line-through; "
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                    style="width: 35%; max-width: 35%;text-align: right; ">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" colSpan="3"
                                    style="width: 100%; max-width: 100% ; white-space: normal;   padding-top: 0">
                                    <div>{{ item.subComboName}}</div>
                                </td>
                            </tr> -->
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="sub-table" *ngIf="invoice_detail.length">
                <table>
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Discount' | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.discount)}}</td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'Total'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.debt_hidden">
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div  *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>:
                    {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '')
                    | translate}}
                    {{invoice.retail_name ?'-':''}}
                    {{invoice.retail_name}}
                </div>
                <div class="content-note">
                    <div class="content-note-title">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" |
                            translate}}:</b>
                    </div>
                    <div class="content-note-content" *ngFor="let item of warrantyProd; let i = index"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        + {{item.content}}
                    </div>
                </div>
                <div class="content-note">
                    <div class="content-note-title">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                    </div>
                    <div class="content-note-content" *ngFor="let item of warrantyNote; let i = index"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{item.content}}
                    </div>
                </div>

                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div class="payment-info" *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}}
                    </div>
                </div>
            </div>
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.note}}
                </div>
            </div>
        <div class="footer">
            <div *ngIf="!printer.footer_hidden" class="footer-footer" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div class="footer-barcode" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-warranty-k57"></svg>
            </div>
        </div>
    </div>
    <div id="electron-warranty-k57a" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style="  border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div style="text-align: center;
            
            padding: 4px 0;" *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div  >
                <div *ngIf="!printer.name_store_hidden" style="text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div  *ngIf="!printer.name_branch_hidden"  style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>
        
        <div style=" padding: 4px 0;">
            <div style=" border-bottom: 1px black dashed;">
                <div style="  text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{('Warranty, repair invoice' | translate) | uppercase}}</b></div>
                <div style=" text-align: start;
                ">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>:
                        {{invoice.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>:
                        {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receptionist" | translate}}</b>:
                        {{invoiceInfo['receptionist'] ? invoiceInfo['receptionist'].name : ''}}
                    </div>
                </div>
            </div>

            <div style="  border-bottom: 1px black dashed;
            padding: 4px 0;" *ngIf="invoice_detail.length">
                <table style="width: 100%;">
                    <thead>
                        <th style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 100% ; word-break: break-all;" colspan="3"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td colSpan="3"
                                    style="width: 100%; max-width: 100% ; white-space: normal;padding-top: 0;">
                                    <ng-container *ngIf="item.combos">
                                        <span  *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 30%; max-width: 30%; text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                                <td style="width: 35%; max-width: 35%; text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span style="text-align: right ;text-decoration: line-through;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 35%; max-width: 35%;text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="width: 30%; max-width: 30%; text-align: left; "
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{item.name}} x{{item.quantity}}
                                </td>
                                <td style="width: 35%; max-width: 35%; text-align: right; "
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                        style="text-align: right ;text-decoration: line-through; "
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                    style="width: 35%; max-width: 35%;text-align: right; ">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" colSpan="3"
                                    style="width: 100%; max-width: 100% ; white-space: normal;   padding-top: 0">
                                    <div>{{ item.subComboName}}</div>
                                </td>
                            </tr> -->
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div style=" border-bottom: 1px black dashed;
            padding: 4px 0;" *ngIf="invoice_detail.length">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Discount' | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.discount)}}</td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{'Total'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.debt_hidden">
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="
            text-align: start;
            padding: 4px 0;
            border-bottom: 1px black dashed;">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div  *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>:
                    {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '')
                    | translate}}
                    {{invoice.retail_name ?'-':''}}
                    {{invoice.retail_name}}
                </div>
                <div  >
                    <div  >
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" |
                            translate}}:</b>
                    </div>
                    <div  *ngFor="let item of warrantyProd; let i = index"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        + {{item.content}}
                    </div>
                </div>
                <div >
                    <div  >
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                    </div>
                    <div  *ngFor="let item of warrantyNote; let i = index"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{item.content}}
                    </div>
                </div>

                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div  *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}}
                    </div>
                </div>
            </div>
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.note}}
                </div>
            </div>
        <div style=" padding: 4px 0 8px 0;">
            <div *ngIf="!printer.footer_hidden" style=" 
            text-align: center;
            padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" style="text-align: center;">
                <svg id="barcode-warranty-k57"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>