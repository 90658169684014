<nz-drawer [nzMaskClosable]="true" [nzWidth]="1000" nzTitle='{{"Danh sách lô" | translate}}' [nzVisible]="show_lot_list" [nzFooter]="footerAddCustomer" (nzOnClose)="nzclose()">
    <ng-container *nzDrawerContent>
        <form nz-form [formGroup]="formLots">
            <div *ngIf="lots.length == 0" style="text-align:center">
                <span>{{'No data'|translate}}</span>
                <button class="btn-add" style="margin: auto;" nz-button nzType="default" (click)="addLots()">
                    <i nz-icon nzType="plus" nzTheme="outline" style="color: var(--ion-color-vh-white);margin-right: 8px;"></i>
                    {{'Tạo lô' | translate}}
                </button>
            </div>
            <ng-container formArrayName="lots" *ngIf="lots.length > 0">
                <div nz-row [nzGutter]="24">
                    <div nz-col class="gutter-row" nzSpan="1"></div>
                    <div nz-col class="gutter-row" nzSpan="5">
                        <div class='label'><b>{{'Số lô' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="5">
                        <div class='label'><b>{{'Ngày sản xuất' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="5">
                        <div class='label'><b>{{'Ngày hết hạn' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="4">
                        <div class='label'><b>{{'Barcode' | translate}}</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="4">
                        <div class='label'><b>{{'Ngưng sử dụng' | translate}}</b></div>
                    </div>
                </div>
                <div *ngFor="let lotForm of lots.controls; let i = index">
                    <form nz-row [formGroup]="lotForm" style="padding-bottom: 8px" [nzGutter]="24">
                        <ng-container>
                            <div class="gutter-row" nz-col nzSpan="1" style="display: flex; justify-content: center; align-items: center; padding: 0px">
                                <i (click)="$event.stopPropagation()" nz-popconfirm nzPopconfirmTitle="{{'Xóa đơn vị này?'}}" (nzOnConfirm)="deleteLots(i)" nzPopconfirmPlacement="topLeft" style="color: var(--ion-color-vh-red); cursor: pointer;" nz-icon nzType="close" nzTheme="outline"></i>
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                                <input nz-input formControlName="lot_number" [id]="'lot'+i" placeholder="{{ 'Nhập số lô'| translate}}" #lot (keyup)="checkNameUnit(lot.value,i)" />
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                                <nz-date-picker formControlName="date_mfg"></nz-date-picker>
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                                <nz-date-picker formControlName="date_exp" (ngModelChange)="changeDate(lotForm)"></nz-date-picker>
                            </div>
                             
                            <div class="gutter-row" nz-col nzSpan="4">
                                <div style="padding-left: 0px; padding-right: 0px; display: flex;  justify-content: center; align-items: center">
                                    <input nz-input formControlName="barcode" (keyup)="checkBarcode(lotForm.value.barcode,i)" />
                                    <i style="padding-left: 8px; cursor: pointer;" nz-icon nzType="reload" nzTheme="outline" (click)="setBarcodeForUnit(i)" nz-tooltip nzTooltipTitle="{{'Generate barcodes automatically' | translate}}" nzTooltipPlacement="bottom"></i>
                                </div>
                            </div>
                            <div class="gutter-row" nz-col nzSpan="4">
                                <nz-switch formControlName="hidden" ></nz-switch>
                            </div>

                        </ng-container>
                        <ng-container>
                            <div class="gutter-row" nz-col nzSpan="1" style="display: flex; justify-content: center; align-items: center; padding: 0px">
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                                <div class="validation-errors" [id]="'lotError'+i">
                                    <ng-container>
                                        <div class="error-message">
                                            <ion-text color="vh-red">{{'Số lô đã tồn tại'|translate}}
                                            </ion-text>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                                 
                            </div>
                            <div class="gutter-row" nz-col nzSpan="5" style="padding-left: 0px; padding-right: 0px">
                            </div> 
                            <div class="gutter-row" nz-col nzSpan="4">
                                <div class="validation-errors" [id]="'barcodeError'+i">
                                    <ng-container>
                                        <div class="error-message">
                                            <ion-text color="vh-red">{{'Barcode này đã tồn tại!'|translate}}
                                            </ion-text>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                            </div>
                        </ng-container>
                    </form>
                </div>
                <div style="padding-top: 8px; float: right;">
                    <button class="btn-add" nz-button nzType="default" (click)="addLots()">
                      <i nz-icon nzType="plus" nzTheme="outline" style="color: var(--ion-color-vh-white);margin-right: 8px;"></i>
                      {{'Thêm lô' | translate}}
                  </button>
                </div>
            </ng-container>

        </form>
    </ng-container>
    <ng-template #footerAddCustomer>
        <div class="footerAddCustomer" style="float: right">
            <button nz-button style="margin-right: 8px;" (click)="nzclose()">{{"Cancel" | translate}}</button>
            <button nz-button (click)="okLots()">{{lots.length > 0 ? 'Lưu':'Ok'}}</button>
        </div>
    </ng-template>
</nz-drawer>