 
import { Component, Input, OnInit } from '@angular/core';
import { VhAlgorithm, VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
 
import { LanguageService } from 'src/app/services/language.service';
import jsbarcode from 'jsbarcode'
import printJS from 'print-js'
import { Platform } from '@ionic/angular';
import { FunctionService } from 'src/app/services/function.service'; 
import { VhSalePaymentCardInvoices } from 'src/app/interface/vh-sale-payment-card-invoice';
declare var electron: any;

@Component({
  selector: 'app-test-card-a4',
  templateUrl: './test-card-a4.component.html',
  styleUrls: ['./test-card-a4.component.scss']
})
export class TestCardA4Component implements OnInit {
  @Input() printer: any;
  tempBill: any = {
    title: "Invoice",
    bill_code: "A35DE4F",
    bill_type: 1,
    date: new Date().toISOString(),
    customer: { name: "Công Ty TNHH GPCN VIETHAS", phone: "02838661111", address: "161/3 Ni Sư Huỳnh Liên, Tân Bình" },
    employee: { name: "Quang Nghĩa" },
    bill_details: [
      {
        name: "thẻ 1",
        id_voucher_code:"DIWOUH2505",
        price: 4800000,
        quantity: 1,
        products: [
          {
            id_product: "pro001",
            name: "BIXOLON SAMSUNG SRP-275C PRINTER",
            unit: "pcs",
            price: 4800000,
            price_origin: 2000000,
            product_quantity: 24,
            quantity: 1,
            root_quantity: 24,
            warning_number: 10
          },
          {
            id_product: "pro002",
            name: "Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58 - XP-I100 2Gb RAM + 16Gb ROM",
            unit: "pcs",
            price: 6290000,
            price_origin: 3000000,
            product_quantity: 24,
            quantity: 1,
            root_quantity: 24,
            warning_number: 15
          }, 
        ]
      },
      {
        name: "thẻ 1",
        id_voucher_code:"DIWOUH2505",
        price: 4800000,
        quantity: 1,
        products: [
           
          {
            id_product: "pro003",
            name: "Oji K80 80mmx45mm_5 rolls",
            unit: "roll",
            price: 39000,
            price_origin: 39000,
            product_quantity: 28,
            quantity: 1,
            root_quantity: 28,
            warning_number: 20
          }
        ]
      },
    ],
    fee: 0,
    payment_type: 1,
    discount: 200000,
    note: "Đây là hoá đơn in thử...",
    qty_total: 3,
    root_total: 11129000,
    tax: 0,
    tax_value: 0,
    total_atax: 10929000,
    payment: 10929000,
  }
  infoStore: any = {};
  constructor(
    public platform: Platform,
    public vhAuth:VhAuth,
    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    
    
    
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(!this.printer.barcode_hidden) jsbarcode("#barcode-a4", this.tempBill['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-browserA").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      a4:true,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "browserA",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 10pt;}' +
        '.A4 {width: 100%; font-family: Arial;}' +
        '.A4 .header-store {display: grid; grid-template-columns: 7% 93%;}' +
        '.A4 .header-store-logo {text-align: center;}' +
        '.A4 .header-store-logo img {width: 100%}' +
        '.A4 .header-store-info-name {font-size: 2vw;padding: 0.05vw 16px;}' +
        '.A4 .header-store-info-contact {display: grid;padding: 0.05vw 16px;grid-template-columns: 65% 35%;}' +
        '.A4 .header-store-info-contact-address {font-size: 1.8vw;}' +
        '.A4 .header-store-info-contact-phone {font-size: 1.8vw;}' +
        '.A4 .header-store-info-invoice {font-size: 2.5vw;text-align: center;padding: 0.05vw 0;font-weight: bold;}' +
        '.A4 .header-invoice {font-size: 1.8vw;}' +
        '.A4 .header-invoice-line {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .header-invoice-line-left {text-align: start;}' +
        '.A4 .header-invoice-line-right {text-align: end;}' +
        '.A4 .content table,.A4 .content thead,.A4 .content tbody {border: none;border-collapse: collapse; }' +
        '.A4 .content table {width: 100%;}' +
        '.A4 .content table thead th {font-size: 2vw;border: 1px black solid;border-collapse: collapse;}' +
        '.A4 .content table tbody tr td {font-size: 1.8vw;border: 1px black solid;border-collapse: collapse;}' +
        '.A4 .content table tbody tr td > .price-origin {text-decoration: line-through;font-size: 1.5vw;}' +
        '.A4 .content table tbody tr.pay td {font-size: 2vw;}' +
        '.A4 .content-note {font-size: 1.8vw;}' +
        '.A4 .content-pay {font-size: 1.8vw;}' +
        '.A4 .signature {padding: 16px 5vw;display: flex;justify-content: space-between;}' +
        '.A4 .signature-detail {text-align: center;}' +
        '.A4 .signature-detail > b {font-size: 1.8vw;}' +
        '.A4 .signature-detail > em {font-size: 1.5vw;}'
    });
  }

}
