<nz-drawer [nzMaskClosable]="true" [nzWidth]="500" [nzTitle]="false" [nzVisible]="searchProductListWarranty"
    (nzOnClose)="goBack()">
    <ng-container *nzDrawerContent>
        <div nz-row>
            <div nz-col nzSpan="24"><input (blur)="acticeFocus()" #inputSearchSales class="nz-hover-border"
                    placeholder="{{ 'Search for product name, barcode' | translate}}" [(ngModel)]="search"
                    (ngModelChange)="searchProduct($event)" [ngModelOptions]="{standalone: true}" nz-input />
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <nz-tabset (nzSelectedIndexChange)="changeTypeGood($event)">
                    <nz-tab *ngFor="let tab of tabs" nzTitle="{{tab | translate}}">
                        <cdk-virtual-scroll-viewport itemSize="67.2" class="infinite-container">
                            <nz-list>
                                <nz-list-item (click)="chooseProduct(item)" *cdkVirtualFor="let item of searchList"
                                    style="cursor: pointer;">
                                    <nz-list-item-meta
                                        nzAvatar="{{item.img ? item.img : 'assets/icon/management/noimage.svg'}}"
                                        nzDescription="{{item.name}}">
                                        <nz-list-item-meta-title>
                                            {{vhAlgorithm.vhcurrencyunit_symbol(item.price)}}
                                        </nz-list-item-meta-title>
                                    </nz-list-item-meta>
                                </nz-list-item>
                                <nz-list-empty *ngIf="searchList.length === 0"></nz-list-empty>
                            </nz-list>
                        </cdk-virtual-scroll-viewport>
                    </nz-tab>
                </nz-tabset>
            </div>
        </div>
    </ng-container>
</nz-drawer>