<router-outlet></router-outlet>

<div class="splash" id="lottie-splash">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
    <div style="font-size: 1rem; text-align: center;">{{'Syncing data...' | translate}}</div>
</div>
<!-- ---------------------modal chọn giao diện bán hàng ------------------- -->
<nz-modal nzWidth="70vw" [(nzVisible)]="show_modal_choose_interface" (nzOnOk)="updateSalesInterface()" (nzOnCancel)="show_modal_choose_interface = false" [nzTitle]="'Giao diện bán hàng' | translate" nzCancelText="">
    <ng-container *nzModalContent>
        <div nz-row class="center-all-content">
            <div nz-col nzSpan="12" class="bottom-right col">
                <div class="thumbnail" (click)="user.default_selling_page = 'sales-pos'" [class.active]=" user.default_selling_page == 'sales-pos'">
                    <img src="assets/img/pos_interface.png" style="width: 100%;" />  

                </div>
                <div style="text-align: center;">
                   {{'Giao diện 1' | translate}} <i *ngIf="user.default_selling_page == 'sales-pos'" style="color: var(--ion-color-vh-green);" nz-icon nzType="check-circle" nzTheme="fill"></i>
                </div>
            </div>
            <div nz-col nzSpan="12" class="bottom-left col">
                <div class="thumbnail" (click)="user.default_selling_page = 'sales-desktop'" [class.active]=" user.default_selling_page == 'sales-desktop'">
                    <img src="assets/img/desktop_interface.png" style="width: 100%;" />
                </div>
                <div style="text-align: center;">
                    {{'Giao diện 2' | translate}}  <i *ngIf="user.default_selling_page == 'sales-desktop'" style="color: var(--ion-color-vh-green);" nz-icon nzType="check-circle" nzTheme="fill"></i>
                 </div>
            </div>
        </div>
    </ng-container>
</nz-modal>