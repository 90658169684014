<ion-content scrollX="true">
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div class="A4" id="exchange-payment-card-a5">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="!printer.logo_hidden" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: right">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div class="header-store-info-contact">
                        <div *ngIf="!printer.address_hidden" class="header-store-info-contact-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div class="header-store-info-invoice" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{'Biên nhận đổi thẻ lấy hàng hóa' | translate | uppercase}}</div>
                </div>
            </div>
            <div class="header-invoice">
                <div class="header-invoice-line">
                    <div *ngIf="!printer.bill_code_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div class="header-invoice-line" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                        invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                        {{invoice.retail_name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["employee"] ?
                        invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden">
                            {{ "Address" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                        invoice.retail_address
                        }}</b>
                        </ng-container>


                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                        }}{{invoice.retail_phone}}</b>
                        </ng-container>

                    </div>
                    <div class="header-invoice-line-right" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <table style="width: 100%;">
                <thead>
                    <th style="max-width: 5%; width: 5%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{ "No." | translate }}</th>
                    <th style="max-width: 45%; width: 45%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Product name" | translate }}</th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Đơn vị" | translate }}</th>
                    <th style="max-width: 10%; width: 10%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "SL cho phép" | translate }}</th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "SL sử dụng" | translate }}</th>
                    <th style="max-width: 10%; width: 10%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Còn lại" | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr>
                            <td style="max-width: 5%; width: 5%; text-align: center" [ngStyle]="{'font-family': printer.font_family}">
                                {{ i + 1 }}
                            </td>
                            <td colspan="5" style="max-width: 35%; width: 35%; text-align: left; margin-left: 4px" [ngStyle]="{'font-family': printer.font_family}">
                                <b> {{ item.name_payment_card }} - {{ item.code }}</b>
                            </td>

                        </tr>
                        <tr *ngFor="let prod of item.products;">
                            <td></td>
                            <td style="text-align: left; margin-left: 4px" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.name}}
                            </td>

                            <td style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family}">
                                {{prod.unit ? prod.unit :''}}
                            </td>
                            <td style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family}"> {{ prod.restquantity }} </td>
                            <td style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.quantity }}
                            </td>
                            <td style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.restquantity - prod.quantity }}
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
            <div class="content-note" *ngIf="!printer.note_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                {{ "Note" | translate }}: {{ invoice.getNote() }}
            </div>
            <div class="content-note">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>

        </div>
        <div class="signature">
            <ng-container>
                <div class="signature-detail" *ngIf="printer.signature_buyer_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Buyer" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                      }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_shipper_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Deliver" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                      }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_creator_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Prepared by" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                      }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_stocker_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Stocker" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                      }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_accountancy_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Chief accountant" | translate
                      }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                      translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="A4" id="electron-exchange-payment-card-a5" style="display: none; margin: 0 !important; padding: 0 !important;">
        <div style="padding:4px 0;" >
            <div style="display: grid; grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img *ngIf="!printer.logo_hidden" style="width: 100%;"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div>
                    <div style=" display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div *ngIf="!printer.name_store_hidden"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div  *ngIf="!printer.name_branch_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div style="display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div  *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div  *ngIf="!printer.phone_number_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div style="text-align: center;padding: 2px 0; font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{'Biên nhận đổi thẻ lấy hàng hóa' | translate | uppercase}}</div>
                </div>
            </div>
            <div >
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div *ngIf="!printer.bill_code_hidden" style=" text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div  *ngIf="!printer.date_hidden" style=" text-align: end; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" style=" text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" style="text-align: end; "  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["employee"] ?
                            invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div style=" text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden" >
                            {{ "Address" | translate }} :
                        <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                            invoice.retail_address
                            }}</b>
                        </ng-container>
                        
                        
                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b style="font-size: 0.8rem" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                                }}{{invoice.retail_phone}}</b>
                        </ng-container>
                         
                    </div>
                    <div style=" text-align: end;"  *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <table style="width: 100%;">
                <thead>
                    <th style="max-width: 5%; width: 5%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{ "No." | translate }}</th>
                    <th style="max-width: 45%; width: 45%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Product name" | translate }}</th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Đơn vị" | translate }}</th>
                    <th style="max-width: 10%; width: 10%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "SL cho phép" | translate }}</th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "SL sử dụng" | translate }}</th>
                    <th style="max-width: 10%; width: 10%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Còn lại" | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr>
                            <td style="max-width: 5%; width: 5%; text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ i + 1 }}
                            </td>
                            <td colspan="5" style="max-width: 35%; width: 35%; text-align: left; margin-left: 4px;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                <b> {{ item.name_payment_card }} - {{ item.code }}</b>
                            </td>

                        </tr>
                        <tr *ngFor="let prod of item.products;">
                            <td></td>
                            <td style="text-align: left; margin-left: 4px;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.name}}
                            </td>

                            <td style=" text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                {{prod.unit ? prod.unit :''}}
                            </td>
                            <td style=" text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}"> {{ prod.restquantity }} </td>
                            <td style=" text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.quantity }}
                            </td>
                            <td style=" text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family}">
                                {{ prod.restquantity - prod.quantity }}
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
            
            <div class="content-note">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="signature">
            <ng-container>
                <div style=" text-align: center;" *ngIf="!printer.signature_buyer_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{ "Buyer" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  > ({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_shipper_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Deliver" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_creator_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Prepared by" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="vhAuth.localStorageGET('show_signature_stocker')">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Stocker" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_accountancy_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Chief accountant" | translate
                        }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" |
                        translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button color="vh-green" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">
            {{ "Print" | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
