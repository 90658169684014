<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="sales-k80a" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}"
                alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div class="header-header" *ngIf="!printer.header_hidden"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.header}}</div>
            <div class="header-store">
                <div class="header-store-name" *ngIf="!printer.name_store_hidden"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b>
                </div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoBranch['name']}}
                </div>
                <div *ngIf="!printer.address_hidden" class="header-store-address"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                    number" |
                    translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; "
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; "
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email"
                    |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header" style="padding: 4px 0;">
                <div class="content-header-title"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{(invoice.bill_type == 1 ? ('Sales invoice'| translate) :('Temporary sales invoice'| translate))  | uppercase}}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice
                            code" | translate}}</b>:
                        {{invoice.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date"
                            | translate}}</b>:
                        {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman"
                            | translate}}</b>:
                        {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div class="table">
                <table>
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: start;">{{'Product name'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: center;">
                            {{'Qty'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: right;">{{'Unit price'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: right;">
                            {{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail;index as i">
                            <ng-container *ngIf="!item.id_promotion">
                                <tr [ngStyle]="{'border-top': i == 0 ?'none'  : '1px dotted'}">
                                    <td style="width: 45%; max-width: 45% ; white-space: normal"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{item.name}} ({{getUnit(item.units, item.ratio)}})
                                        <ng-container *ngIf="item.id_lotproduct">
                                            <div
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                                Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}}
                                            </div>
                                            <div
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                                {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                            </div>
                                        </ng-container>
                                    </td>
                                    <td style="width: 5%; max-width: 5%; text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{item.quantity}}</td>
                                    <td style="width: 25%; max-width: 25%; text-align: end;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                        <br>
                                        <span
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            style="text-align: right ;text-decoration: line-through;"
                                            *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                            {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                        </span>
                                    </td>
                                    <td style="width: 25%; max-width: 25%;text-align: end;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                    </td>
                                </tr>
                                <tr *ngIf="item.combos">
                                    <td colSpan="4"
                                        style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                        <span *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}),
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="item.id_promotion">
                                <tr [ngStyle]="{'border-top': i == 0 ?'none'  : '1px dotted'}">
                                    <td colSpan="4"
                                        style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0;font-weight: bold;">
                                        <div
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.7}">
                                            <b>{{item.name}}</b></div>
                                    </td>
                                </tr>
                                <ng-container *ngIf="item.pmtype != 6 &&  item.pmtype != 7">
                                    <ng-container *ngFor="let data of item.products">
                                        <tr style="border-top: 1px dotted gray;">
                                            <td style="width: 45%; max-width: 45% ; white-space: normal"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{data.name}} ({{getUnit(data.units, data.ratio)}})</td>
                                            <td style="width: 5%; max-width: 5%; text-align: center;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{data.quantity}}</td>
                                            <td style="width: 25%; max-width: 25%; text-align: end;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                                <br>
                                                <span
                                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                    style="text-align: right ;text-decoration: line-through;"
                                                    *ngIf="data.price_origin > data.price && data.price_origin && display_promotion_selling_price">
                                                    {{vhAlgorithm.vhcurrencyunit(data.price_origin)}}
                                                </span>
                                            </td>
                                            <td style="width: 25%; max-width: 25%;text-align: end;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{vhAlgorithm.vhcurrencyunit(data.price * data.quantity)}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="data.combos">
                                            <td colSpan="4"
                                                style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                                <span
                                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                    *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                    ({{getUnit(combo.units,combo.ratio)}}), </span>
                                            </td>
                                        </tr>
                                    </ng-container>

                                </ng-container>
                                <ng-container *ngFor="let data of item.products_gift">
                                    <tr style="border-top: 1px dotted gray;">
                                        <td style="width: 45%; max-width: 45% ; white-space: normal"
                                            [ngStyle]="{'font-family': printer.font_family,'font-siz e.pt' : printer.font_size*0.6}">
                                            {{data.name}} ({{getUnit(data.units, data.ratio)}})</td>
                                        <td style="width: 5%; max-width: 5%; text-align: center;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{data.quantity}}</td>
                                        <td style="width: 25%; max-width: 25%; text-align: end;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                            <br>
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                style="text-align: right ;text-decoration: line-through;"
                                                *ngIf="data.price_origin > data.price && data.price_origin && display_promotion_selling_price">
                                                {{vhAlgorithm.vhcurrencyunit(data.price_origin)}}
                                            </span>
                                        </td>
                                        <td style="width: 25%; max-width: 25%;text-align: end;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{vhAlgorithm.vhcurrencyunit(data.price * data.quantity)}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="data.combos">
                                        <td colSpan="4"
                                            style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                ({{getUnit(combo.units,combo.ratio)}}), </span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="sub-table">
                <table>
                    <tbody>
                        <!-- <tr *ngIf="vhAuth.localStorageGET('show_discount_invoice') && invoice.getDiscountInvoice() >= 0">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Chiết khấu' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getDiscountInvoice())}}</td>
                        </tr> -->
                        <tr>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'SubTotal' | translate}}</td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden && invoice.discount">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.discount_bill_hidden && invoice.discount_bill">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Discount" | translate }}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.discount_bill) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.payment_points_hidden && invoice.payment_points">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Thanh toán điểm" | translate }}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.payment_points) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.payment_coupon_hidden && invoice.payment_coupons">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Thanh toán phiếu" | translate }}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.payment_coupons) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Fee'|translate}}</td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'Total'|translate}}
                            </td>
                            <td class="pay"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.total +
                                invoice.total*( invoice.tax/100)) : vhAlgorithm.vhcurrencyunit(invoice.total +
                                invoice.total*( invoice.tax/100))
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td class="pay"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.payment) :
                                vhAlgorithm.vhcurrencyunit(invoice.payment)
                                }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.debt_hidden">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) -
                                invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'cash'|translate}}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Change'|translate}}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash -
                                invoice.payment : 0)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer
                        name'|translate}}</b>:
                    {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}}
                    {{invoice.retail_name
                    ?'-':''}}
                    {{invoice.retail_name}}

                    <ng-container
                        *ngIf="!printer.customer_address_hidden && renderAddress(invoiceInfo['partner']) || invoice.retail_address">
                        <br> {{ "Address" | translate }} :
                        <span
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            renderAddress(invoiceInfo["partner"]) ||
                            invoice.retail_address
                            }}</span>
                    </ng-container>


                    <ng-container
                        *ngIf="!printer.customer_phone_hidden &&  invoiceInfo['partner'].phone || invoice.retail_phone">
                        <br /> {{ "Phone number" | translate }} :
                        <span
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoiceInfo["partner"].phone
                            }}{{invoice.retail_phone}}</span>
                    </ng-container>
                    <ng-container *ngIf="!printer.total_points_customer_hidden && invoice.id_customer != 'id_retail'">
                        <br /> {{ "Điểm tích lũy" | translate }} :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhQuerySales.getlocalCustomerPoints(invoice.id_customer)}}</span>
                    </ng-container>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment
                        method' | translate}}</b>:
                    {{invoiceInfo['paymentName'] | translate}} </div>
                <div class="payment-info" *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account
                            name" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account
                            number" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}} - {{invoiceInfo['wallet'] ?
                        invoiceInfo['wallet'].bank_name: ''}}
                    </div>
                </div>
            </div>
            <div class="payment" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    <pre>{{ invoice.note }}</pre> 
                </div>
            </div>
            <div style="font-size: 12pt;padding: 4px 0;text-align: start;">
                <pre
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="footer">
            <div *ngIf="!printer.footer_hidden" class="footer-footer"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" class="footer-barcode">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
        <div style="border-top: 1px dash ;text-align: center;"  *ngIf="!printer.qr_ibanking && infoBranch.qr_ibanking">
             <img style="width: 45mm;" [src]="infoBranch.qr_ibanking" alt="">
        </div>
    </div>
    <div id="electron-sales-k80a" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}"
                alt="Logo">
        </div>
        <div style=" border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" style="text-align: center;
            
            padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.header}}</div>
            <div>
                <div *ngIf="!printer.name_store_hidden" style="  text-align: center;
                "><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b>
                </div>
                <div *ngIf="!printer.name_branch_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                    number" |
                    translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; "
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; "
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email"
                    |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div>
            <div style="border-bottom: 1px black dashed; padding: 4px 0;">
                <div style="text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{(invoice.bill_type == 1 ? ('Sales invoice'| translate) :('Temporary sales invoice'| translate))  | uppercase}}</b></div>
                <div style="text-align: start;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <div *ngIf="!printer.bill_code_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice
                            code" | translate}}</b>:
                        {{invoice.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{"Date" | translate}}</b>: {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman"
                            | translate}}</b>:
                        {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div style=" border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: start;">{{'Product name'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: center;">
                            {{'Qty'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: right;">{{'Unit price'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                            style="text-align: right;">
                            {{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail;index as i">
                            <ng-container *ngIf="!item.id_promotion">
                                <tr [ngStyle]="{'border-top': i == 0 ?'none'  : '1px dotted'}">
                                    <td style="width: 45%; max-width: 45% ; white-space: normal"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{item.name}} ({{getUnit(item.units,item.ratio)}})
                                        <ng-container *ngIf="item.id_lotproduct">
                                            <div
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                                Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}}
                                            </div>
                                            <div
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                                {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                            </div>
                                        </ng-container>
                                    </td>
                                    <td style="width: 5%; max-width: 5%; text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{item.quantity}}</td>
                                    <td style="width: 25%; max-width: 25%; text-align: end;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                        <br>
                                        <span
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            style="text-align: right ;text-decoration: line-through;"
                                            *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                            {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                        </span>
                                    </td>
                                    <td style="width: 25%; max-width: 25%;text-align: end;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                        {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                    </td>
                                </tr>
                                <tr *ngIf="item.combos">
                                    <td colSpan="4"
                                        style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                        <div *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}),
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="item.id_promotion">
                                <tr [ngStyle]="{'border-top': i == 0 ?'none'  : '1px dotted'}">
                                    <td colSpan="4"
                                        style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0;font-weight: bold;">
                                        <span
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.7}"><b>{{
                                                item.name}}</b></span>
                                    </td>
                                </tr>
                                <ng-container *ngIf="item.pmtype != 6 &&  item.pmtype != 7">
                                    <ng-container *ngFor="let data of item.products">
                                        <tr style="border-top: 1px dotted gray;">
                                            <td style="width: 45%; max-width: 45% ; white-space: normal"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{data.name}} ({{getUnit(data.units,data.ratio)}})</td>
                                            <td style="width: 5%; max-width: 5%; text-align: center;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{data.quantity}}</td>
                                            <td style="width: 25%; max-width: 25%; text-align: end;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                                <br>
                                                <span
                                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                    style="text-align: right ;text-decoration: line-through;"
                                                    *ngIf="data.price_origin > data.price && data.price_origin && display_promotion_selling_price">
                                                    {{vhAlgorithm.vhcurrencyunit(data.price_origin)}}
                                                </span>
                                            </td>
                                            <td style="width: 25%; max-width: 25%;text-align: end;"
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                                {{vhAlgorithm.vhcurrencyunit(data.price * data.quantity)}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="data.combos">
                                            <td colSpan="4"
                                                style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                                <span
                                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                    *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                    ({{getUnit(combo.units,combo.ratio)}}), </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngFor="let data of item.products_gift">
                                    <tr style="border-top: 1px dotted gray;">
                                        <td style="width: 45%; max-width: 45% ; white-space: normal"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{data.name}} ({{getUnit(data.units,data.ratio)}}) </td>
                                        <td style="width: 5%; max-width: 5%; text-align: center;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{data.quantity}}</td>
                                        <td style="width: 25%; max-width: 25%; text-align: end;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                            <br>
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                style="text-align: right ;text-decoration: line-through;"
                                                *ngIf="data.price_origin > data.price && data.price_origin && display_promotion_selling_price">
                                                {{vhAlgorithm.vhcurrencyunit(data.price_origin)}}
                                            </span>
                                        </td>
                                        <td style="width: 25%; max-width: 25%;text-align: end;"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                            {{vhAlgorithm.vhcurrencyunit(data.price * data.quantity)}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="data.combos">
                                        <td colSpan="4"
                                            style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                ({{getUnit(combo.units,combo.ratio)}}), </span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div style="border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <tbody>
                        <!-- <tr *ngIf="vhAuth.localStorageGET('show_discount_invoice') && invoice.getDiscountInvoice() >= 0">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Chiết khấu' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getDiscountInvoice())}}</td>
                        </tr> -->
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden && invoice.discount">
                            <td style="text-align: end;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)
                            </td>
                            <td style="text-align: end;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.discount_bill_hidden && invoice.discount_bill">
                            <td style="text-align: end;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Discount" | translate }}
                            </td>
                            <td style="text-align: end;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.discount_bill) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.payment_points_hidden && invoice.payment_points">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Thanh toán điểm" | translate }}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.payment_points) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.payment_coupon_hidden && invoice.payment_coupons">
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ "Thanh toán phiếu" | translate }}
                            </td>
                            <td
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(invoice.payment_coupons) }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}"
                                style="text-align: end;font-weight: bold;">
                                {{'Total'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.total +
                                invoice.total*( invoice.tax/100)) : vhAlgorithm.vhcurrencyunit(invoice.total +
                                invoice.total*( invoice.tax/100))
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: end;font-weight: bold;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.payment) :
                                vhAlgorithm.vhcurrencyunit(invoice.payment)
                                }}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.debt_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{'REST'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) -
                                invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{'cash'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{'Change'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                                style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash -
                                invoice.payment : 0)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="
            text-align: start;
            padding: 4px 0;
            border-bottom: 1px black dashed;">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer
                        name'|translate}}</b>:
                    {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}}
                    {{invoice.retail_name ?'-':''}}
                    {{invoice.retail_name}}

                    <ng-container
                        *ngIf="!printer.customer_address_hidden && renderAddress(invoiceInfo['partner']) || invoice.retail_address">
                        <br> {{ "Address" | translate }} :
                        <span
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            renderAddress(invoiceInfo["partner"]) ||
                            invoice.retail_address
                            }}</span>
                    </ng-container>


                    <ng-container
                        *ngIf="!printer.customer_phone_hidden &&  invoiceInfo['partner'].phone || invoice.retail_phone">
                        <br /> {{ "Phone number" | translate }} :
                        <span
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoiceInfo["partner"].phone
                            }}{{invoice.retail_phone}}</span>
                    </ng-container>
                    <ng-container *ngIf="!printer.total_points_customer_hidden && invoice.id_customer != 'id_retail'">
                        <br /> {{ "Điểm tích lũy" | translate }} :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhQuerySales.getlocalCustomerPoints(invoice.id_customer)}}</span>
                    </ng-container>
                </div>

                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment
                        method' | translate}}</b>:
                    {{invoiceInfo['paymentName'] | translate}} </div>
                <div *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account
                            name" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account
                            number" | translate}}</b>:
                        {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}} - {{invoiceInfo['wallet'] ?
                        invoiceInfo['wallet'].bank_name: ''}}
                    </div>
                </div>
            </div>
            <div style="
            text-align: start;
            padding: 4px 0;
            border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    <pre>{{ invoice.note }}</pre> 
                </div>
            </div>
            <div style="
            text-align: start;
            padding: 4px 0;" *ngIf="printer.notice">
                <pre
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div style=" padding: 4px 0 8px 0;">
            <div *ngIf="!printer.footer_hidden" style=" 
            text-align: center;
            padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" style="text-align: center;">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
        <div style="border-top: 1px dash ;text-align: center;"  *ngIf="!printer.qr_ibanking && infoBranch.qr_ibanking">
             <img style="width: 45mm;" [src]="infoBranch.qr_ibanking" alt="">
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>