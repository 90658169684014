<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="ios" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="receipt-k57a" class="K57">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div  *ngIf="!printer.header_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div  *ngIf="!printer.name_store_hidden" class="header-store-name"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div *ngIf="useBranch && !printer.name_branch_hidden" class="header-store-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoBranch['name']}}</div>
                <div  *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div  *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" |
                    translate}} : {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}</div>
                    <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                        translate}} : {{infoStore['email']}}
                    </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header" style="padding: 0 0 4px 0">
                <div class="content-header-title"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{(([7,25].includes(billInfo.bill_type) ?
                        'Other receipts':'Receive from customer receivables' )| translate) | uppercase}}</b></div>
                <div class="content-header-info">
                    <div  *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt code" | translate}}</b>:
                        {{billInfo.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date
                        | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-header-info"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> :
                    {{billInfo.employee.name}}</div>
            </div>


            <div style="padding: 4px 0;text-align: left;">
                <div *ngIf="[7].includes(billInfo.bill_type)" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                </div>

                <div *ngIf="[11].includes(billInfo.bill_type)">
                    <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payer" | translate}} : <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.name}}</b>
                    </div>
                    <div *ngIf="!printer.customer_phone_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                        number" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.phone}}</b>
                    </div>
                    <div *ngIf="!printer.customer_address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                        | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.address}}</b>
                    </div>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" | translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}: <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] | translate}}</b>
                </div>
                <div *ngIf="billInfo.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account name" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].name:
                            ''}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account number" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].number:
                            ''}}</b>
                    </div>
                </div>
            </div>


            <div style="text-align: left" *ngIf="!printer.note_hidden">
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                <!-- <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div> -->
            </div>

            <div class="content-info-barcode"   *ngIf="!printer.barcode_hidden">
                <svg id="barcode-receipt"></svg>
            </div>

        </div>
        <div class="signature">
            <div style="text-align: center;" *ngIf="!printer.signature_payer_hidden">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Payer" | translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                >({{'Sign,
                    write full name'|translate}})</em>
            </div>
            <div style="text-align: center;" *ngIf="!printer.signature_treasurer_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'cashier'|translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                    >({{'Sign, write full
                    name'|translate}})</em>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div  *ngIf="!printer.footer_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
    </div>
    <div id="receipt-electron-k57a" style="padding: 8px;display:none">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style="border-bottom: 0.1rem black dashed; text-align: center;" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" style=" padding: 0.1rem 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                {{printer.header}}</div>
            <div>
                <div *ngIf="!printer.name_store_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                        >{{infoStore.company}}</b></div>
                <div *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Phone number"|
                    translate}} : {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}</div>
                    <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                        translate}} : {{infoStore['email']}}
                    </div>
            </div>
        </div>

        <div style="padding: 4px 0;">
            <div style="border-bottom: 1px black dashed; padding: 0 0 4px 0;">
                <div style="text-align: center; "><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{(([7,25].includes(billInfo.bill_type) ?
                        'Other receipts':'Receive from customer receivables' )| translate) | uppercase}}</b></div>
                <div style="text-align: start; ">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt code" | translate}}</b>:
                        {{billInfo.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date
                        | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div *ngIf="!printer.employee_name_hidden" style="text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> :
                    {{billInfo.employee.name}}</div>
            </div>


            <div style="text-align: left;padding: 4px 0;">
                <div  *ngIf="[7].includes(billInfo.bill_type)"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                </div>

                <div  *ngIf="[11].includes(billInfo.bill_type)"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payer" | translate}} : <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.name}}</b>
                    </div>
                    <div *ngIf="!printer.customer_phone_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                        number" | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.phone}}</b>
                    </div>
                    <div *ngIf="!printer.customer_address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                        | translate}}:
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.address}}</b>
                    </div>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" |
                    translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" |
                    translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'Payment method' | translate}}: <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] | translate}}</b>
                </div>
                <div *ngIf="billInfo.payment_type == 3">
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account name" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].name:
                            ''}}</b>
                    </div>
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account number" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].number:
                            ''}}</b>
                    </div>
                </div>
            </div>


            <div style="text-align: left" *ngIf="!printer.note_hidden">
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}
                    :</div>
                <!-- <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div> -->
            </div>

            <div *ngIf="!printer.barcode_hidden" style="text-align: center">
                <svg id="barcode-receipt"></svg>
            </div>

        </div>
        <div style="display: flex; justify-content: space-between;padding: 16px;">
            <div style="text-align: center;" *ngIf="!printer.signature_payer_hidden">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Payer" | translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                >({{'Sign,
                    write full name'|translate}})</em>
            </div>
            <div style="text-align: center;" *ngIf="!printer.signature_treasurer_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'cashier'|translate}}</b><br><em
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                    >({{'Sign, write full
                    name'|translate}})</em>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div *ngIf="!printer.footer_hidden" style=" text-align: center;padding: 4px 0;"
            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>