<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>

    <div id="browserSmall" class="K57">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div *ngIf="!printer.name_store_hidden" class="header-store-name" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b>{{infoStore.company}}</b></div>
                <div *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.address}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore.phone}}</div>
            </div>
        </div>

        <div class="content">
            <div class="content-header">
                <div class="content-header-title" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{(tempBill.title | translate) | uppercase}} {{printer.name | translate | uppercase }}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b> : {{tempBill.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b> : {{ tempBill.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> : {{tempBill.employee.name}}</div>
                </div>
            </div>

            <div class="table">
                <table>
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Tên thẻ'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Mã thẻ'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of tempBill.bill_details">
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 30%; max-width: 30%; text-align: center;">{{item.name}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 35%; max-width: 35%; text-align: right;">
                                    {{item.id_voucher_code}}
                                </td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 35%; max-width: 35%;text-align: right;">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 100% ; white-space: normal" colspan="3">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                        {{ product.name}} ({{product.unit}}) x {{product.quantity}} <br>
                                      </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="sub-table">
                <table>
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit( tempBill.root_total)}}</td>
                        </tr>
                        <tr [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_discount') : !printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Giảm giá' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(tempBill.discount)}}</td>
                        </tr>
                        <tr [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_fee') : printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit( tempBill.fee)}}</td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_tax') : printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent((tempBill.tax)/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.tax_value)}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" class="pay">
                                {{'Total'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" class="pay">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax)}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" class="pay">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" class="pay">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_rest') : printer.debt_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax - tempBill.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'cash'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(11000000)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Change'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(71000)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{tempBill.customer.name}}
                </div>
                <div *ngIf="printer.name == 'Warranty, repair'">
                    <div class="content-note">
                        <div class="content-note-title">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                        </div>
                        <div class="content-note-content" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            + Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58
                        </div>
                    </div>
                    <div class="content-note">
                        <div class="content-note-title">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                        </div>
                        <div class="content-note-content" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            Trầy xướt nhẹ...
                        </div>
                    </div>
                </div>
            </div>
            <div class="payment">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="footer">
            <div class="footer-footer" *ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div class="footer-barcode" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-invoice-k57"></svg>
            </div>
        </div>
    </div>
    <div id="electron-browserSmall" class="K57" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style="border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" style="text-align: center;

            padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div>
                <div *ngIf="!printer.name_store_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b>{{infoStore.company}}</b></div>
                <div *ngIf="!printer.address_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.address}}</div>
                <div *ngIf="!printer.phone_number_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore.phone}}</div>
            </div>
        </div>

        <div style=" padding: 4px 0;">
            <div style="border-bottom: 1px black dashed;">
                <div style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{(tempBill.title | translate) | uppercase}} {{printer.name | translate | uppercase }}</b></div>
                <div style=" text-align: start;
                ">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b> : {{tempBill.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b> : {{ tempBill.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> : {{tempBill.employee.name}}</div>
                </div>
            </div>

            <div style=" border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Tên thẻ'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Mã thẻ'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of tempBill.bill_details">

                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 30%; max-width: 30%; text-align: center;">{{item.name}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 35%; max-width: 35%; text-align: right;">
                                    {{item.id_voucher_code}}
                                </td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 35%; max-width: 35%;text-align: right;">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 100% ; white-space: normal" colspan="3">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                        {{ product.name}} ({{product.unit}}) x {{product.quantity}} <br>
                                      </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div style="border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;"> {{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{vhAlgorithm.vhcurrencyunit( tempBill.root_total)}}</td>
                        </tr>
                        <tr [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_discount') : !printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Giảm giá' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{vhAlgorithm.vhcurrencyunit(tempBill.discount)}}</td>
                        </tr>
                        <tr [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_fee') : printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{vhAlgorithm.vhcurrencyunit( tempBill.fee)}}</td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_tax') : printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent((tempBill.tax)/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.tax_value)}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{'Total'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax)}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_rest') : printer.debt_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'REST'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(tempBill.total_atax - tempBill.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'cash'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(11000000)}}
                            </td>
                        </tr>
                        <tr *ngIf="printer.name === 'Sales' ?  vhAuth.localStorageGET('show_cash') : false">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'Change'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(71000)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{tempBill.customer.name}}
                </div>
                <div *ngIf="printer.name == 'Warranty, repair'">
                    <div>
                        <div>
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            + Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58
                        </div>
                    </div>
                    <div>
                        <div>
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            Trầy xướt nhẹ...
                        </div>
                    </div>
                </div>
            </div>
            <div style="
            text-align: start;
            padding: 4px 0;
            border-bottom: 1px black dashed;">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div style=" padding: 4px 0 8px 0;">
            <div style="
            text-align: center;
            padding: 4px 0;" *ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div style=" text-align: center;" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-invoice-k57"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
