import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode'
import { VhAlgorithm, VhQuerySales, VhAuth } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';

import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;

@Component({
  selector: 'app-transfer-money-a4',
  templateUrl: './transfer-money-a4.component.html',
  styleUrls: ['./transfer-money-a4.component.scss']
})
export class TransferMoneyA4Component implements OnInit {

  @Input() printer: any;
  @Input() billInfo: any;
  infoStore: any = {}
  invoiceInfo: any = {}
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    public platform: Platform,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales,
    private vhAuth: VhAuth,
    private lang: LanguageService,

    private fncService: FunctionService
  ) { }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    if (this.useBranch) this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
   
    if (this.useBranch) this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.billInfo.employee),
      paymentName: this.setPaymentName(this.billInfo.payment_type),
      wallet: this.vhQuerySales.getlocalWallet(this.billInfo.id_wallet),
      name_branch_reception: this.vhQuerySales.getlocalBranch(this.billInfo.id_branch_receive),
      branch_default: this.vhQuerySales.getlocalBranch(this.billInfo.id_branch)
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(!this.printer.barcode_hidden) jsbarcode("#barcode-transfer-money", this.billInfo['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.lang.translate("Cash")
      case 2: return this.lang.translate("Debit")
      case 3: return this.lang.translate("Account")
    }
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-transfer-money-a4").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      a4:true,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "transfer-money-a4",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 10pt;}' +
        '.A4 .header-store {display: grid;grid-template-columns: 7% 93%;}' +
        '.A4 .header-store-logo {text-align: center;}' +
        '.A4 .header-store-logo img {width: 100%;}' +
        '.A4 .header-store-info-contact,.A4 .header-store-info-name {display: grid;padding: 0.05vw 16px;grid-template-columns: 65% 35%;}' +
        '.A4 .header-store-info-contact-address,.A4 .header-store-info-contact-phone {font-size: 1.8vw;}' +
        '.A4 .header-store-info-name-company,.A4 .header-store-info-name-branch {font-size: 1.8vw;}' +
        '.A4 .header-title {text-align: center;font-size: 2.5vw;}' +
        '.A4 .content {font-size: 1.8vw;}' +
        '.A4 .content-invoice {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-invoice-date {text-align: end;}' +
        '.A4 .content-info {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-info-barcode {text-align: end;}' +
        '.A4 .content-payment {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-payment .content-payment-number {text-align: end;}' +
        '.A4 .content-note {display: grid;grid-template-columns: max-content auto;}' +
        '.A4 .content-note-label {align-self: flex-end;}' +
        '.A4 .content-note-dot {border-bottom: 1px dotted black;height: 1.8vw;}' +
        '.A4 .signature {display: flex;justify-content: space-between;padding: 16px 5vw;}' +
        '.A4 .signature-detail {text-align: center;}' +
        '.A4 .signature-detail > b {font-size: 1.8vw;}' +
        '.A4 .signature-detail > em {font-size: 1.5vw;}'
    });
  }
}
