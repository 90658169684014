<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="purchase-k80a" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" class="header-header"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div *ngIf="!printer.name_store_hidden" class="header-store-name"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}
                </div>
                <div *ngIf="!printer.address_hidden" class="header-store-address"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header">
                <div class="content-header-title"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Phiếu xuất hủy hàng hóa' | translate) | uppercase}}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}</div>
                    <div  *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Stocker" | translate}}</b>: {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div class="table">
                <table>
                    <thead>
                        <th style="text-align: start;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: center;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Qty'|translate}}</th>
                        <th style="text-align: right;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 45%; max-width: 45% ; white-space: normal"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item.name}} ({{getUnit(item.units,item.ratio)}})<br>
                                    
                            <ng-container *ngIf="item.id_lotproduct">
                                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}} 
                                  </div>
                                  <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                     {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                  </div>
                            </ng-container>
                                </td>
                                <td style="width: 5%; max-width: 5%; text-align: center;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">{{item.quantity}}</td>
                                <td style="width: 25%; max-width: 25%; text-align: end;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" style="text-align: right ;text-decoration: line-through;" *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 25%; max-width: 25%;text-align: end;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
 

             
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.note}}
                </div>
            </div>
        <div class="footer">
            <div *ngIf="!printer.footer_hidden" class="footer-footer"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" class="footer-barcode">
                <svg id="barcode-purchase"></svg>
            </div>
        </div>
    </div>
    <div id="electron-purchase-k80a" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style=" border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div  *ngIf="!printer.header_hidden" style="text-align: center;
            
            padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div >
                <div  *ngIf="!printer.name_store_hidden" style="  text-align: center;
                "><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div  *ngIf="!printer.name_branch_hidden"  style="text-align: center; 
                "
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div  *ngIf="!printer.address_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div  *ngIf="!printer.phone_number_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" |
                    translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div style="padding: 4px 0;">
            <div style=" border-bottom: 1px black dashed;">
                <div style=" text-align: center;
                "><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Phiếu xuất hủy hàng hóa' | translate) | uppercase}}</b></div>
                <div style="text-align: start;
                " >
                    <div *ngIf="!printer.bill_code_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Stocker" | translate}}</b>: {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div style=" border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <thead>
                        <th style="text-align: start;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: center;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Qty'|translate}}</th>
                        <th style="text-align: right;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 45%; max-width: 45% ; white-space: normal"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item.name}} ({{getUnit(item.units,item.ratio)}})<br>
                                    
                            <ng-container *ngIf="item.id_lotproduct">
                                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}} 
                                  </div>
                                  <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                     {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                  </div>
                            </ng-container>
                                </td>
                                <td style="width: 5%; max-width: 5%; text-align: center;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">{{item.quantity}}</td>
                                <td style="width: 25%; max-width: 25%; text-align: end;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" style="text-align: right ;text-decoration: line-through;" *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 25%; max-width: 25%;text-align: end;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
 
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.note}}
                </div>
            </div>
        <div style="padding: 4px 0 8px 0;">
            <div *ngIf="!printer.footer_hidden"  style=" 
            text-align: center;
            padding: 4px 0;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden"  style="text-align: center;">
                <svg id="barcode-purchase"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>