<nz-drawer [nzMaskClosable]="true" [nzWidth]="500" [nzTitle]="" [nzVisible]="searchProductList" (nzOnClose)="goBack()">
    <ng-container *nzDrawerContent id="search-combos-popup">
        <div nz-row>
            <input   #inputSearchSales class="nz-hover-border" style="width: 70%;"
                    placeholder="{{ 'Search for product name, barcode' | translate}}" [(ngModel)]="search"
                    (ngModelChange)="searchProduct($event)" [ngModelOptions]="{standalone: true}" nz-input />
                <button class="btn-add" style="margin-left: 8px;" nz-button nzType="default" (click)="gotoAddProducts()">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    {{'Add'|translate}}
                </button>
        </div>
        <div nz-row style="height: calc(100% - 35px); padding: 8px 0">
            <div nz-col nzSpan="24">
                <nz-tabset (nzSelectedIndexChange)="changeTypeGood($event);" [nzSelectedIndex]="indexTab">
                    <nz-tab *ngFor="let tab of tabs" nzTitle="{{tab | translate}}"></nz-tab>
                </nz-tabset>
                <nz-tabset (nzSelectedIndexChange)="changeCategory($event);" [nzSelectedIndex]="indexTabCateogry">
                    <nz-tab *ngFor="let tab of listCategories; trackBy : trackByFn"
                        nzTitle="{{tab.name | translate}}"></nz-tab>
                </nz-tabset>
                <cdk-virtual-scroll-viewport [itemSize]="56" class="infinite-container ant-table-body">
                    <nz-list>
                        <nz-list-item *cdkVirtualFor="let item of searchList" class="item" style="cursor: pointer;">
                            <nz-list-item-meta
                                nzAvatar="{{item.img ? item.img : 'assets/icon/management/noimage.svg'}}">
                                <nz-list-item-meta-title (click)="chooseProduct(item)">
                                    <span style="font-size: 0.9rem; color: var(--ion-color-vh-black)">
                                        {{ item.name}}
                                    </span> <br>
                                    <div>{{'Selling price' | translate}}:
                                        <span style=" color: var(--ion-color-vh-green)"
                                            *ngIf="default_price_type == 1 || !item.price2">{{vhAlgorithm.vhcurrencyunit(item.price)}}</span>
                                        <span style=" color: var(--ion-color-vh-green)"
                                            *ngIf="default_price_type == 2 && item.price2">{{vhAlgorithm.vhcurrencyunit(item.price2)}}</span>
                                        <span *ngIf="item.type !=1"> ({{ item.unit}})</span>
                                    </div>
                                    <div *ngIf="indexTab ==0" [ngClass]="{'color-red': item.quantity_branch[id_branch]/item.ratio < 0, 
                                    'color-warning': item.quantity_branch[id_branch]/item.ratio < item.warning_number,
                                    'color-black': item.quantity_branch[id_branch]/item.ratio > item.warning_number}">
                                        {{'Stock' | translate}}:
                                        <span>{{item.quantity_branch ? item.quantity_branch[id_branch]/item.ratio : 0
                                            | number:'1.0-2'}} ({{item.unit}})</span>
                                    </div>
                                    <div *ngIf="item.position" style="color: var(--ion-color-vh-gray)">Vị trí:
                                        {{item.position}}</div>
                                </nz-list-item-meta-title>
                                <nz-list-item-meta-description *ngIf="item.combos">
                                    <span
                                        style="font-size: 0.75rem; color: var(--ion-color-vh-black); font-weight: normal;">
                                        {{item.subComboName}}
                                    </span>
                                </nz-list-item-meta-description>
                            </nz-list-item-meta>
                        </nz-list-item>
                        <nz-list-empty *ngIf="searchList.length === 0"></nz-list-empty>
                    </nz-list>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </ng-container>
</nz-drawer>