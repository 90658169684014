import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from '../components/vh-component/vh-component';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  branchList: Array<any> = [] // danh sách chi nhánh show ra HTML
  id_branch_report: any;
  constructor(
    private vhQuerySales: VhQuerySales,
    private vhAuth: VhAuth,
    private vhComponent: VhComponent,
    private datePipe: DatePipe,
  ) { }

  checkExitsLotNumber(lot_number) {
    let product = this.vhQuerySales.getlocalDetailProducts(1).filter(item => item.manylot).filter(e => e.lots.find(lot => lot.lot_number == lot_number))
    
    
    if (product.length) return true;
    else return false;

  }

  checkUsingBranch(): boolean {
    return this.vhQuerySales.getlocalBranchs().length > 1 ? true : false
  }

  checkDateReport() {
    return this.vhQuerySales.getLocalRestrictionMainPackage().show_report_duration
  }

  formatDate(date) {
    return this.datePipe.transform(date, "dd/MM/yyyy")
  }

  getBranches() {
    return new Promise((resolve, rejects) => {
      let dataBranchReport = {}
      let branches = [...this.vhQuerySales.getlocalBranchs()] // danh sách toàn bộ chi nhánh
      let employee = this.vhAuth.getUser() // thông tn nhân viên
      let defaultBranch = this.vhQuerySales.getDefaultBranch()._id // chi nhánh mặc định khi đăng nhập (lấy id chi nhánh)

      if (this.checkUsingBranch()) { // kiểm tra xem có 2 chi nhánh trở lên

        if (employee['owner'] == 'boss') { // kiểm tra tài khoản đăng nhập là boss
          this.branchList = branches // gán danh sách chi nhánh show ra HTML
          if (this.branchList.length > 1) this.branchList.unshift({ _id: "all", name: "All" })
          this.id_branch_report = defaultBranch // gán mặc định chi nhánh mặc định xem báo cáo là chi nhánh đăng nhập vô
          dataBranchReport = {
            id_branch_report: this.id_branch_report,
            branchList: this.branchList
          }

        }

        else { // trường hợp tài khoản là nhân viên
          this.vhAuth.getPermission(employee['_id']).then(result => { // get quyền nhân viên được xem báo cáo chi nhánh nào
            branches.forEach(item => {
              if (result[`${item._id}_view_report_branch`]) this.branchList.push({ ...item }) // kiểm tra chi nhánh đc xem báo cáo, gán cho branchList show ra HMLT 
            })
            if (this.branchList.length > 1) { // trường hợp được xem báo cáo 2 chi nhánh trở lên
              this.branchList.unshift({ _id: "all", name: "All" }) // đẩy xem tất cả vô, nếu có 2 chi nhánh trở lên
              for (let i in this.branchList) {
                if (this.branchList[i]._id == defaultBranch) return this.id_branch_report = defaultBranch // nếu có gán chi nhánh xem báo mặc định là chi nhánh mặc định (đăng nhập)
                else this.id_branch_report = this.branchList[1]._id // ngược lại gán chi nhánh mặc định là chi nhánh thứ 1
              }
              dataBranchReport = {
                id_branch_report: this.id_branch_report,
                branchList: this.branchList
              }

            }
            else if (this.branchList.length == 1) { // nếu chỉ đc xem báo cáo 1 chi nhánh , lấy mặc định là chi nhánh đó
              this.id_branch_report = this.branchList[0]._id
              dataBranchReport = {
                id_branch_report: this.id_branch_report,
                branchList: this.branchList
              }

            }
            else this.vhComponent.alertMessageDesktop("warning", "Bạn không được chọn chi nhánh xem báo cáo") // kg có chi nhánh xem báo cáo
          })
        }

      } else {
        this.id_branch_report = defaultBranch // chỉ xài 1 chi nhánh 
        dataBranchReport = {
          id_branch_report: this.id_branch_report,
          branchList: this.branchList
        }
      }
    })
  }
}
