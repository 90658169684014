<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div id="expenses-a4" class="A4">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="platform.is('desktop')"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company | uppercase}}</b></div>
                    <div class="header-store-info-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    <div class="header-store-info-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                    <div class="header-store-info-contact" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{"Phone number" | translate}} : {{infoStore['phone']}}
                    </div>
                </div>
            </div>
            <div class="header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{printer.name| translate | uppercase}}</b></div>

            <div class="content">
                <div class="content-invoice">
                    <div *ngIf="printer.name =='Pay for liabilities / others'" class="content-invoice-bill-code" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Expenses code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'" class="content-invoice-bill-code">
                        {{"Receipt code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-invoice-date">{{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div class="content-info">
                    <div class="content-info-detail">
                        <div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Pay for liabilities / others'">
                                {{"Receiver" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'">
                                {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.phone}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.address}}</b>
                            </div>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Total amount" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                    </div>
                    <div class="content-info-barcode">
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.employee}}</b></div>
                        <svg id="barcode-receipt"></svg>
                    </div>
                </div>
                <div class="content-note">
                    <div class="content-note-label" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                    <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div>
                </div>
            </div>

            <div class="signature">
                <div class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver" | translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div class="signature-detail"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by' |translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em></div>
                <div class="signature-detail"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em>
                </div>
            </div>
        </div>
    </div>
    <div id="expenses-electron-a4" style="display: none;">
        <div>
            <div style="display: grid;
            grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img style="width: 100%;" *ngIf="platform.is('desktop')"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div>
                    <div style="padding: 2px 16px;"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company | uppercase}}</b></div>
                    <div style="padding: 2px 16px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    <div style="padding: 2px 16px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                    <div style="padding: 2px 16px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{"Phone number" | translate}} :
                        {{infoStore['phone']}}
                    </div>
                </div>
            </div>
            <div style="font-size:1rem;text-align: center;"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.name| translate | uppercase}}</b></div>

            <div>
                <div style="display: grid; grid-template-columns: 50% 50%;">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Pay for liabilities / others'" >
                        {{"Expenses code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngIf="printer.name =='Receive from receivables / others'" >
                        {{"Receipt code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end; ">{{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 50% 50%;">
                    <div>
                        <div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  *ngIf="printer.name =='Pay for liabilities / others'">
                                {{"Receiver" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                            </div>
                            <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  *ngIf="printer.name =='Receive from receivables / others'">
                                {{"Payer" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Phone number" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.phone}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Address" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.address}}</b>
                            </div>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Total amount" | translate}} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                    </div>
                    <div class="content-info-barcode">
                        <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.employee}}</b></div>
                        <svg id="barcode-receipt"></svg>
                    </div>
                </div>
                <div style="text-align: left">
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" |
                        translate}} :</div>
                    <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                    <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                    <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                </div>
            </div>

            <div style=" display: flex;
            justify-content: space-between;
            padding: 16px;">
                <div style="text-align: center;">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver" |
                        translate}}</b><br><em 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div style="text-align: center;"><b 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by'
                        |translate}}</b><br><em 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em></div>
                <div style="text-align: center;"><b 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br><em
                         [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em>
                </div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}</ion-button>
    </ion-toolbar>
</ion-footer>