import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.scss']
})
/** component này để thêm xóa sửa danh sách lô lưu local khi thêm sp */
export class LotListComponent implements OnInit {
  @Input() show_lot_list: boolean = false;
  @Input("data") data: any[] = [];
  @Input("barcodes") barcodes: any = []
  @Output("close") close = new EventEmitter<any>();
  public formLots = this.formBuilderfb.group({                       // Form thêm đơn vị quy đổi
    lots: this.formBuilderfb.array([], Validators.required)
  })
  public lotForm: FormGroup;

  disabledButton = true;
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private readonly formBuilderfb: FormBuilder,
    private vhQuerySales: VhQuerySales,
    private vhComponent: VhComponent,
    private lang: LanguageService
  ) {
  }
  ngOnInit(): void {
    this.initAddLotsForm(this.data)
  }

  initAddLotsForm(data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) { //  đơn vị quy đổi
        this.addLots(data[i])
      }
    }
  }

  // get mãng đơn vị quy đổi
  get lots() {
    return this.formLots.controls["lots"] as FormArray;
  }
  // thêm đơn vị quy đổi
  addLots(lot?) {
    this.lotForm = this.formBuilderfb.group({
      date_mfg: new FormControl(lot ? lot.date_mfg : new Date(new Date().setHours(0, 0, 0,)), Validators.compose([Validators.required])),
      date_exp: new FormControl(lot ? lot.date_exp : new Date(new Date().setHours(23, 59, 59,)), Validators.compose([Validators.required])),
      lot_number: new FormControl(lot ? lot.lot_number : '', Validators.compose([Validators.required])),
      hidden: new FormControl(lot ? lot.hidden : false),
      barcode: new FormControl(lot ? lot.barcode : ''),
    })
    this.lots.push(this.lotForm);
  }
  // xóa đơn vị quy đổi
  deleteLots(index: number) {
    this.lots.removeAt(index)
  }

  /**
   * kiểm tra ngày sx và ngày hh hợp lý hay ko
   * @param lotForm 
   */
  changeDate(lotForm) {
    if (new Date(lotForm.value.date_mfg).getTime() >= new Date(lotForm.value.date_exp).getTime()) {
      lotForm.control['date_exp'].setValue(new Date(new Date(lotForm.value.date_mfg).setHours(23, 59, 59)))
      this.vhComponent.alertMessageDesktop("error", this.lang.translate("Staring date must be less than or the same as ending date"))
    }
  }

  /**
   * bắt sk nhấn nút lưu
   */
  okLots() {
    
    if (this.barcode_exist) {
      this.vhComponent.alertMessageDesktop("error", this.lang.translate("Barcode đã tồn tại"))
    }
    else {
      let invalid_lot = this.lots.value.filter(item => new Date(item.date_exp).getTime() <= new Date(new Date(item.date_mfg)).getTime())
      if (invalid_lot.length) {
        this.vhComponent.alertMessageDesktop("error", invalid_lot[0].lot_number + ' ' + this.lang.translate("Staring date must be less than or the same as ending date"))
      }
      else {
        let data = this.lots.value.filter(item => item.lot_number != '' && new Date(item.date_exp).getTime() >= new Date(new Date(item.date_mfg)).getTime())
        this.close.emit(data);
      }
    }
  }

  /**
   * đóng component
   */
  nzclose() {
    this.close.emit(false);
  }

  /**
   * tạo barcode tự động tại vị trí position trong mảng
   * @param position 
   */
  setBarcodeForUnit(position) {
    let newbarcode = '';
    for (let index = 0; index < 12; index++) {
      newbarcode += Math.floor(Math.random() * 10)
    }
    let detailproduct = this.vhQuerySales.getlocalDetailProduct_byBarcode(newbarcode)
    if (detailproduct == null) {
      (this.lots.at(position) as FormGroup).get('barcode').setValue(newbarcode);
      document.getElementById('barcodeError' + position).style.display = 'none';
    } else {
      document.getElementById('barcodeError' + position).style.display = 'block';
    }
  }


  barcode_exist = false;
  /**
   * kiểm tra barcode đã tồn tại hay chưa (cả trong local và sp đã tạo)
   * @param barcode 
   * @param position 
   * @returns 
   */
  checkBarcode(barcode, position) {
    if (barcode.length) {
      
      
      if (this.barcodes.find(item => item == barcode)) {
        this.barcode_exist = false;
        document.getElementById('barcodeError' + position).style.display = 'block';
        return
      }
      else {
        if (this.vhQuerySales.getlocalDetailProduct_byBarcode(barcode)) {
          this.barcode_exist = true;
          document.getElementById('barcodeError' + position).style.display = 'block';
          return;
        }
        else {
          const lotsNotPosition: any[] = this.lots.value.filter((item, index) => index != position)
          if (lotsNotPosition.length) {
            lotsNotPosition.forEach(element => {
              if (element.barcode != barcode) {
                this.barcode_exist = false;
                document.getElementById('barcodeError' + position).style.display = 'none';
                return
              } else {
                this.barcode_exist = true;
                document.getElementById('barcodeError' + position).style.display = 'block';
                return;
              }
            })

          } else {
            this.barcode_exist = false;
            document.getElementById('barcodeError' + position).style.display = 'none';
            return
          }

        }
      }
    } else {
      this.barcode_exist = false;
      document.getElementById('barcodeError' + position).style.display = 'none';
      return
    }
  }



  //  kiểm tra mả lô khi thêm có trùng hay ko
  checkNameUnit(lot: string, position: number) {
    if (lot.length) {
      const lotsNotPosition: any[] = this.lots.value.filter((item, index) => index != position)
      if (lotsNotPosition.length) {
        lotsNotPosition.forEach(element => {
          if (element.lot_number != lot) {
            document.getElementById('lotError' + position).style.display = 'none';
            return
          } else {
            document.getElementById('lotError' + position).style.display = 'block';
            return;
          }
        })
      } else {
        document.getElementById('lotError' + position).style.display = 'none';
        return
      }
    }
  }

}
