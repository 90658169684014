import { LanguageService } from 'src/app/services/language.service';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';

@Component({
  selector: 'app-search-product-import',
  templateUrl: './search-product-import.component.html',
  styleUrls: ['./search-product-import.component.scss']
})
export class SearchProductImportComponent implements OnInit {
  @Input("searchProductList") searchProductList: boolean = true;
  @Output("closeSearchProductList") closeSearchProductList = new EventEmitter<any>();
  id_branch: string = this.vhQuerySales.getDefaultBranch()._id
  listAllProducts: any[] = [];
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private vhQuerySales: VhQuerySales,
    private vhComponent: VhComponent,
    private languageService: LanguageService
  ) { }
  public goodList: any[] = [];
  public searchList: any[] = [];
  search: string = '';
  ngOnInit(): void {
    this.getData();

  }

  goBack() {
    this.closeSearchProductList.emit(false);
  }
  /**
   * lọc sp theo tên hoặc barcode
   * @example this.searchProduct('')
   */
  searchProduct(value: string) {
    let tempVal: string = this.vhAlgorithm.changeAlias(value.toLowerCase());
    if (value.length)
      this.searchList = this.vhAlgorithm.searchList(tempVal, this.goodList, ['name', 'barcode'])
    else this.searchList = this.goodList
  }
  /**
   * bắt sk click vào sp và ouput về component cha
   * @param item 
   * @example this.chooseProduct(product_selected)
   */
  chooseProduct(item, sub?) {
    if (!sub) this.closeSearchProductList.emit({ ...item })
    else this.closeSearchProductList.emit({ ...item, ...sub, name: item.name + ' (' + sub.name + ') ', id_subproduct: sub._id, _id: item._id })
  }
  /**
   * get dữ liệu sp để show ra giao diện
   */
  getData() {
    this.listAllProducts = this.vhQuerySales.getlocalDetailProducts(1).map(item => {
      let unit = this.vhQuerySales.getUnit_byRatio(item.units, item.units.find(e => e.default)?.ratio || 1)
      return { ...item, ...unit }
    });
    this.goodList = this.listAllProducts
    this.searchList = this.goodList;
    this.searchProduct('');
  }
}
