import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';

@Component({
  selector: 'app-search-product-warranty-invoice',
  templateUrl: './search-product-warranty-invoice.component.html',
  styleUrls: ['./search-product-warranty-invoice.component.scss']
})
export class SearchProductWarrantyInvoiceComponent implements OnInit {

  @ViewChild("inputSearchSales") myInputField: ElementRef;
  @Input("searchProductListWarranty") searchProductListWarranty: boolean = true;
  @Output("closeSearchProductListWarranty") closeSearchProductListWarranty = new EventEmitter<any>();
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private vhQuerySales: VhQuerySales
  ) { }
  public goodList: any[] = [];
  public searchList: any[] = [];
  public typeGoods = 0;
  search: string = '';
  ngOnInit(): void {
    this.changeTypeGood(this.typeGoods);
  }

  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }
  acticeFocus() {
    this.myInputField.nativeElement.focus();
  }
  goBack() {
    this.closeSearchProductListWarranty.emit(false);
  }
  tempVal: string = ''
  /**
   * lọc sản phẩm theo tên hoặc barcode
   * @param value 
   * @example this.searchProduct('')
   */
  searchProduct(value: string) {
    this.tempVal = this.vhAlgorithm.changeAlias(value.toLowerCase());
    if (value.length)
      this.searchList = this.vhAlgorithm.searchList(this.tempVal, this.goodList, ['name', 'barcode'])
    else this.searchList = this.goodList
  }
  chooseProduct(item) {
    this.closeSearchProductListWarranty.emit(item)
  }
  tabs: any = ['Product', 'Service']
   /**
   * bắt sk thay đổi tab loại sp
   * @example this.changeTypeGood('1')
   */
  changeTypeGood(event) {
    this.goodList = this.vhQuerySales.getlocalDetailProducts().filter(good => good.type == event + 1).map(e => {return {...e,  ...this.vhQuerySales.getUnit_byRatio(e.units, 1)}});
    this.searchList = this.goodList;
    this.searchProduct(this.tempVal)
  }

}
