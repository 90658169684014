import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { VhAlgorithm, VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { FunctionService } from 'src/app/services/function.service';
import { LanguageService } from 'src/app/services/language.service';
import JsBarcode from 'jsbarcode'
import PrintJs from 'print-js'
import { VhBillType51Add, VhBillType51DetailAdd } from 'src/app/components/bill-type/bill-type-51/bill-type51-interface';
declare var electron: any;
@Component({
  selector: 'app-exchange-payment-card-k80-col4',
  templateUrl: './exchange-payment-card-k80-col4.component.html',
  styleUrls: ['./exchange-payment-card-k80-col4.component.scss']
})
export class ExchangePaymentCardK80Col4Component implements OnInit {
  @Input() printer;
  @Input() invoice: VhBillType51Add;
  @Input() invoice_detail : VhBillType51DetailAdd[] = [];
  public infoStore: any = {}                                                    // Thông tin cửa hàng
  public invoiceInfo: any                                                       // Thông tin hóa đơn
  public useBranch: boolean = this.fncService.checkUsingBranch()                // Chi nhánh đang dùng
  public infoBranch: any = {}                                                   // Thông tin chi nhánh đang dùng
  public display_promotion_selling_price: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').display_promotion_selling_price
  constructor(
    private vhComponent: VhComponent,
    public vhAuth: VhAuth,
    public vhQuerySales: VhQuerySales,
    private languageService: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    private fncService: FunctionService,
    private platform: Platform
  ) { }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.invoice.id_employee),
      partner: this.vhQuerySales.getlocalCustomer(this.invoice.id_customer), 
    }
  }
  ngAfterViewInit(): void {
    if(!this.printer.barcode_hidden)  this.renderBarcode(this.invoice.bill_code)
  }

  // Gán tên kiểu thanh toán
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.languageService.translate("Cash")
      case 2: return this.languageService.translate("Debit")
      case 3: return this.languageService.translate("Account")
    }
  }
  //  Gán địa chỉ khách hành
  public renderAddress(partner) {
    return (partner.address ? partner.address : '') +
      (partner.district ? ', ' + partner.district : '') +
      (partner.province ? ', ' + partner.province : '') +
      (partner.country ? ', ' + partner.country : '')
  }

  // Thực hiện in
  Print() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printDesktop()
  }

  printElectron() {
    let html = document.getElementById("electron-exchange-payment-card-k80-col4").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.languageService.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printDesktop() {
    PrintJs({
      printable: "exchange-payment-card-k80-col4",
      type: "html",
      scanStyles: false,
      style:
      'html { font-size: 8.5pt;}' +
      '@page { margin: 0 !important;}' +
      '.K80 { padding-bottom: 64px;}' +
      '.K80 .header {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
      '.K80 .header-header {text-align: center;font-size: 0.9rem;padding: 0.5vw 0;}' +
      '.K80 .header-store-name {text-align: center;font-size: 1.1rem;}' +
      '.K80 .header-store-address, .K80 .header-store-phone,.K80 .header-store-branch {text-align: center;font-size: 1rem;}' +
      '.K80 .content {padding: 0.5vw 0;}' +
      '.K80 .content-header {border-bottom: 1px black dashed;}' +
      '.K80 .content-header-title {text-align: center;font-size: 1.2rem;}' +
      '.K80 .content-header-info {text-align: start;font-size: 1rem;}' +
      '.K80 .content .table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
      '.K80 .content .table table {width: 100%;}' +
      '.K80 .content .table table thead th {font-size: 1rem;}' +
      '.K80 .content .table table tbody tr td {font-size: 1rem;}' +
      '.K80 .content .sub-table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
      '.K80 .content .sub-table table {width: 100%;}' +
      '.K80 .content .sub-table table tbody tr {padding: 0.5vw 0;}' +
      '.K80 .content .sub-table table tbody tr td {width: 50%;max-width: 50%;font-size: 1rem;text-align: end;}' +
      '.K80 .content .sub-table table tbody tr > .pay {font-size: 1rem;font-weight: bold;}' +
      '.K80 .content .payment {font-size: 1rem;padding: 0.5vw 0;border-bottom: 1px black dashed;}' +
      '.K80 .footer {padding: 0.5vw 0 1vw 0;}' +
      '.K80 .footer-footer {font-size: 0.9rem;text-align: center;padding: 0.5vw 0;}' +
      '.K80 .footer-barcode {text-align: center;}'
    })
  }

  close() {
    this.vhComponent.hideModal()
  }

  renderBarcode(value) {
    if(!this.printer.barcode_hidden) JsBarcode("#barcode-sales", value, {
      format: "CODE128",
      lineColor: "#000",
      height: 40,
      displayValue: false
    });
  }

}
