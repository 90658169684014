import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from './components/vh-component/vh-component';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { AnimationItem } from 'lottie-web';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private animationItem: AnimationItem;
  public options: AnimationOptions = {
    path: '/assets/splash/sync.json'
  };
  constructor(private router: Router,
    private vhAuth: VhAuth,
    private vhQuerySales: VhQuerySales,
    private lang: LanguageService,
    private vhComponent: VhComponent,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private languageService: LanguageService,
    public platform: Platform,) {
    this.router.navigateByUrl("/").then(() => {
      this.setDefaultSetting()
      this.initializeApp()
      if (platform.width() < 765) {
        this.stop()
        this.vhComponent
          .alertConfirm("URL này chỉ chạy trên máy tính, trên điện thoại vui lòng tải APP về sử dụng!",
            "", "",
            "Ok", "Cancel",
          )
          .then(ok => {
            
            if (platform.is("android")) window.open("https://play.google.com/store/apps/details?id=com.viethas.sell&hl=vi&gl=US", '_blank')
            if (platform.is("ios")) window.open("https://apps.apple.com/us/app/sales-viethas/id1524758552", '_blank')
          }, cancel => {
            
          })
      }
      else this.tryCheckSignedIn()


      // ------xóa sự kiện back/forward-------
      document.addEventListener('mousedown', (event: any) => {
        this.noBackNoForward()
      })
    })
  }

  /**Khởi tạo app */
  private initializeApp() {
    this.vhAuth.initializeProject('bvhsales', 'firebase', 'firebase', 'firebase', 'research', 'research', 240415, 'all');
  }
  /**
   * gọi các hàm cấu hình trong local khi load app
   * @example this.setDefaultSetting()
   */
  private setDefaultSetting() {
    this.vhAuth.syncExistedTheme()
    this.setDefaultFontsize()
    this.setDefaultLanguage()
    this.setDefaultCurrency() 
  }



  /**Set hiển thị các trường thông tin đơn hàng mặc định là true nêu chưa đc khởi tạo */
  private setDefaultConfigSales() {
    let firstTimeLogin = this.vhAuth.localStorageGET("first_time_login");
    if (!firstTimeLogin) {
      this.vhAuth.localStorageSET("show_best_selling", true)
      this.vhAuth.localStorageSET("show_tax", true)
      this.vhAuth.localStorageSET("show_fee", true)
      this.vhAuth.localStorageSET("show_discount", true)
      this.vhAuth.localStorageSET("show_note", true);
      this.vhAuth.localStorageSET("show_cash", true);
      this.vhAuth.localStorageSET("show_rest", true);
      // dành cho phần in A4
      this.vhAuth.localStorageSET("show_signature_buyer", true)
      this.vhAuth.localStorageSET("show_signature_shipper", true);
      this.vhAuth.localStorageSET("show_signature_creator", true);
      this.vhAuth.localStorageSET("show_signature_accountancy", true);
      this.vhAuth.localStorageSET("first_time_login", true);

    }
  }
  /**Set default currency */
  private setDefaultCurrency() {
    if (!localStorage.getItem("vhsales_currencyFormat")) {
      localStorage.setItem("vhsales_currencyFormat",
        JSON.stringify({ symbol: { text: "VND", value: 295 }, position: { text: '1 ' + 'VND', value: 1 }, digitDecimal: { text: '0', value: 0 }, digitGroup: { text: '123456', value: 0 } }));
    }
  }

  /** Chọn font size cho app */
  setDefaultFontsize() {
    let fontSize = localStorage.getItem("vh-sales-system-fontsize")
    if (!fontSize) {
      localStorage.setItem("vh-sales-system-fontsize", "16")
      document.documentElement.style.fontSize = '16px'
    } else document.documentElement.style.fontSize = fontSize + 'px'
  }

  /**Set app language */
  private async setDefaultLanguage() {
    const appLang: any = localStorage.getItem("vh-sales-language")
    if (!appLang) {
      this.translateService.setDefaultLang('vi')
      this.languageService.switchLanguage('vi')
      localStorage.setItem("vh-sales-language", 'vi') 
    } else {
      this.translateService.use(appLang)
      this.languageService.switchLanguage(appLang)
    }
  }
 

  show_modal_choose_interface = false
  user: any = {};
 
  /**
   * Xử lý mở app, kiểm tra đã đăng nhập hay chưa, nếu có kiểm tra phân quyền và router vào trang hiển thị mặc định
   * @example this.tryCheckSignedIn()
   */
  tryCheckSignedIn() {
    this.vhAuth.onAuthStateChanged_Sales()
      .then((user: any) => {       
        this.vhQuerySales.refreshLocalBranchs()
          .then(() => {
            
            this.vhQuerySales.syncCollections_Desktop()
              .then(() => {
                this.user = user
                this.setDefaultConfigSales();
                // kiểm tra phải boss hay ko
                if (user['owner'] == 'boss') {
                  if (!this.vhQuerySales.getDefaultBranch()) {
                    this.router.navigate(["/login"]).then(() => {
                      this.stop()
                    })
                  }
                  else {
                    let mypermissionname = this.vhAuth.getLocalMyPermissionName('default_display_page')
                    if (mypermissionname) { // kiểm tra có trường đăng nhập mặc định chưa

                      switch (mypermissionname) {
                        case 'selling_display_page': {
                          if (!this.vhAuth.getLocalMyPermissionName('default_selling_page')) this.show_modal_choose_interface = true
                          else
                            this.navigateToPage(this.vhAuth.getLocalMyPermissionName('default_selling_page'))
                          break;
                        }
                        case 'admin_display_page': {
                          this.navigateToPage('/dashboard')
                          // this.router.navigate(['/dashboard']);
                          break;
                        }
                        default: {
                          this.vhAuth.updateEmployee(user._id, { default_display_page: 'admin_display_page' })
                            .then((bool) => {
                              if (bool) this.navigateToPage('/dashboard')

                            })
                        }
                      }
                    }
                    else { // nếu chưa có thì check xem có quyền đăng nhập vô url nào. ưu tiên dashboard
                      this.vhAuth.updateMyPermission({ default_display_page: 'admin_display_page' })
                        .then((bool) => {
                          if (bool) this.navigateToPage('/dashboard')

                        })
                    }
                  }
                }
                else // nếu là nv thì get quyền
                  this.vhAuth.getPermission(user._id)
                    .then((permission) => {

                      if (!this.vhQuerySales.getDefaultBranch()) {
                        this.router.navigate(["/login"]).then(() => {
                          this.stop()
                        })
                      }
                      else {
                        if (permission['default_price_type'] == undefined) this.vhAuth.updateMyPermission({ default_price_type: true }) // dành cho các tk cũ để update quyền này = true
                        let default_branch = this.vhQuerySales.getDefaultBranch()
                        //chỗ này kiểm tra nhân viên có phân quyền branch nào hay k, nếu không thì đá ra
                        let branches: Array<any> = this.vhQuerySales.getlocalBranchs()
                        for (let index in branches) {
                          if (permission[`${branches[index]._id}_login_branch`]) break;
                          if (+index == branches.length - 1) {
                            this.vhComponent.showToast(1500, this.languageService.translate("You don't have any branch to log in, please contact the owner account"), "alert-toast")
                            this.signOut(false)
                          }
                        }
                        if (default_branch) {
                          let index = this.vhQuerySales.getlocalBranchs().findIndex(item => default_branch._id == item._id);

                          if (index >= 0) {
                            if (permission.default_display_page) { // kiểm tra có trường đăng nhập mặc định chưa
                              switch (permission.default_display_page) {
                                case 'selling_display_page': {
                                  if (permission['selling_display_page']) { // kiểm tra trường đăng nhập vs quyền có khớp ko
                                    if (!permission.default_selling_page) this.show_modal_choose_interface = true
                                    else this.navigateToPage(permission.default_selling_page)


                                  }
                                  else { // nếu quyền vô trang đó = false thì xet tiep 2 quyền khác ưu tiên pos
                                    
                                    if (permission.admin_display_page) {
                                      this.vhAuth.updateMyPermission({ default_display_page: 'admin_display_page' })
                                        .then((bool) => {
                                          if (bool) this.navigateToPage('/dashboard')

                                        })
                                    }
                                    else
                                      Promise.all([this.vhAuth.updateMyPermission({ selling_display_page: true, admin_display_page: true }),
                                      this.vhAuth.updateMyPermission({ default_display_page: 'admin_display_page' })])
                                        .then((bool) => {
                                          if (bool) this.navigateToPage('/dashboard')

                                        })
                                  }
                                  break;
                                }
                                case 'admin_display_page': {
                                  if (permission['admin_display_page']) { // kiểm tra trường đăng nhập vs quyền có khớp ko
                                    this.navigateToPage('/dashboard');

                                  }
                                  else { // nếu quyền vô trang đó = false thì xet tiep 2 quyền khác ưu tiên pos
                                    if (permission.selling_display_page) {

                                      this.vhAuth.updateMyPermission({ default_display_page: 'selling_display_page' })
                                        .then((bool) => {
                                          if (bool) {
                                            if (!permission.default_selling_page) this.show_modal_choose_interface = true
                                            else
                                              this.router.navigate([permission.default_selling_page]);
                                          }

                                        })
                                    }

                                    else
                                      Promise.all([this.vhAuth.updateMyPermission({ selling_display_page: true, admin_display_page: true, default_display_page: 'admin_display_page' }),
                                      ])
                                        .then((bool) => {
                                          if (bool) this.navigateToPage('/dashboard')

                                        })
                                  }
                                  break;
                                }
                                default: {
                                  Promise.all([this.vhAuth.updateMyPermission({ selling_display_page: true, admin_display_page: true, default_display_page: 'admin_display_page' }),
                                  ])
                                    .then((bool) => {
                                      if (bool) this.navigateToPage('/dashboard')

                                    })
                                }
                              }
                            }
                            else { // nếu chưa có trường default_display_page của user thì check xem có quyền đăng nhập vô url nào. ưu tiên POS
                              if (permission.selling_display_page) {
                                this.vhAuth.updateMyPermission({ default_display_page: 'selling_display_page', default_selling_page: 'sales-desktop' })
                                  .then((bool) => {
                                    if (bool) {
                                      if (!permission.default_selling_page) this.show_modal_choose_interface = true
                                      else
                                        this.router.navigate([permission.default_selling_page]);
                                    }

                                  })
                              }
                              else {
                                if (permission.admin_display_page) {
                                  this.vhAuth.updateMyPermission({ default_display_page: 'admin_display_page' })
                                    .then((bool) => {
                                      if (bool) this.navigateToPage('/dashboard')

                                    })
                                }
                                else
                                  Promise.all([this.vhAuth.updateMyPermission({ selling_display_page: true, admin_display_page: true, default_display_page: 'admin_display_page' }),
                                  ])
                                    .then((bool) => {
                                      if (bool) this.navigateToPage('/dashboard')

                                    })
                              }
                            }
                            if (!permission)
                              Promise.all([this.vhAuth.updateMyPermission({ selling_display_page: true, admin_display_page: true, default_display_page: 'admin_display_page' }),
                              ])
                                .then((bool) => {
                                  if (bool) this.navigateToPage('/dashboard')

                                })
                          } else this.signOut(true)
                        } else this.signOut(false)
                      }

                    }, error => {
                      
                    });
              }).catch(err => {
                
                this.router.navigate(["/login"]).then(() => {
                  this.stop()
                })
              })
          })
      }, error => {
        
        this.setDefaultConfigSales()
        this.stop()
        this.router.navigate(["/login"])
      })
      .catch(err => {
        
        this.router.navigate(["/login"]).then(() => {
          this.stop()
        })
      })
  }

  /**
   * hàm này để router tới trang mặc định của uer đã chọn (cafe-pos ,cafe-desktop, admin)
   * @param url : trang mặc định khi mở app
   * @example this.navigateToPage('cafe-pos')
   */
  navigateToPage(url) {
    // kiểm tra tk cũ chưa có trường này trong chi nhánh thì update true lại
    if (this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').display_promotion_selling_price == undefined)
      this.vhQuerySales.updateAppSettingNameBranch('permission_branch', { display_promotion_selling_price: true })
    this.router.navigate([url]).then(() => {
      this.stop()
    })
  }


  /**
    * thiết lập giao diện trang bán hàng  sale-pos | sale-desktop
    * @example this.updateSalesInterface()
    */
  updateSalesInterface() {
    this.vhAuth.updateEmployee(this.user._id, { default_selling_page: this.user.default_selling_page })
      .then((bool) => {
        this.stop()
        this.router.navigate([this.user.default_selling_page])
        this.show_modal_choose_interface = false
      })
  }

   /**
   * đăng xuất khỏi app, quay về trang login
   * @param isExist : biến cờ để hiển thị thông báo ko có chi nhánh được cấp để truy cập
   * @example this.signOut(false)
   */
  signOut(isExist) {
    this.vhQuerySales.clearDefaultBranch()
    this.vhAuth.signOut().then(() => {
      if (isExist)
        this.vhComponent.showToast(2000, this.lang.translate("This branch is invalid or not working"), "alert-toast")
      this.router.navigate(["/login"]).then(() => {
        this.stop()
      })
    })
  }
 /**
  * mở hoạt ảnh động khi mở app
  * @example this.animationCreated($event)
  */
  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }
 /**
  * tắt hoạt ảnh động khi mở app
  * @example this.animationCreated($event)
  */
  stop(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.stop();
    });

    if (document.querySelector('#lottie-splash')) document.getElementById("lottie-splash").remove()
  }

  noBackNoForward() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }
}
