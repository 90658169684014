import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode'
import { VhAlgorithm, VhQuerySales, VhAuth } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';

import { LanguageService } from 'src/app/services/language.service';
declare var electron: any;

@Component({
  selector: 'app-test-card-k57',
  templateUrl: './test-card-k57.component.html',
  styleUrls: ['./test-card-k57.component.scss']
})
export class TestCardK57Component implements OnInit {
  @Input() printer: any;
  tempBill: any = {
    title: "Invoice",
    bill_code: "A35DE4F",
    bill_type: 1,
    date: new Date().toISOString(),
    customer: { name: "Công Ty TNHH GPCN VIETHAS", phone: "02838661111", address: "161/3 Ni Sư Huỳnh Liên, Tân Bình" },
    employee: { name: "Quang Nghĩa" },
    bill_details: [
      {
        name: "thẻ 1",
        id_voucher_code:"DIWOUH2505",
        price: 4800000,
        quantity: 1,
        products: [
          {
            id_product: "pro001",
            name: "BIXOLON SAMSUNG SRP-275C PRINTER",
            unit: "pcs",
            price: 4800000,
            price_origin: 2000000,
            product_quantity: 24,
            quantity: 1,
            root_quantity: 24,
            warning_number: 10
          },
          {
            id_product: "pro002",
            name: "Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58 - XP-I100 2Gb RAM + 16Gb ROM",
            unit: "pcs",
            price: 6290000,
            price_origin: 3000000,
            product_quantity: 24,
            quantity: 1,
            root_quantity: 24,
            warning_number: 15
          }, 
        ]
      },
      {
        name: "thẻ 1",
        id_voucher_code:"DIWOUH2505",
        price: 4800000,
        quantity: 1,
        products: [
           
          {
            id_product: "pro003",
            name: "Oji K80 80mmx45mm_5 rolls",
            unit: "roll",
            price: 39000,
            price_origin: 39000,
            product_quantity: 28,
            quantity: 1,
            root_quantity: 28,
            warning_number: 20
          }
        ]
      },
    ],
    fee: 0,
    payment_type: 1,
    discount: 200000,
    note: "Đây là hoá đơn in thử...",
    qty_total: 3,
    root_total: 11129000,
    tax: 0,
    tax_value: 0,
    total_atax: 10929000,
    payment: 10929000,
  }
  infoStore: any;
  constructor(
    public platform: Platform,
    public vhAuth:VhAuth,

    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(!this.printer.barcode_hidden) jsbarcode("#barcode-invoice-k57", this.tempBill['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-browserSmall").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "browserSmall",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K57 {padding-right: 8px; padding-bottom: 64px;}' +
        '.K57 .header {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .header-header {text-align: center;font-size: 0.9rem;padding: 0.5vw 0;}' +
        '.K57 .header-store-name {text-align: center;font-size: 1.1rem;}' +
        '.K57 .header-store-address, .K57 .header-store-phone {text-align: center;font-size: 1rem;}' +
        '.K57 .content {padding: 0.5vw 0;}' +
        '.K57 .content-header {border-bottom: 1px black dashed;}' +
        '.K57 .content-header-title {text-align: center;font-size: 1.2rem;}' +
        '.K57 .content-header-info {text-align: start;font-size: 1rem;}' +
        '.K57 .content .table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .content .table table {width: 100%;}' +
        '.K57 .content .table table thead th {font-size: 1.05rem;}' +
        '.K57 .content .table table tbody tr td {font-size: 1rem;}' +
        '.K57 .content .sub-table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .content .sub-table table {width: 100%;}' +
        '.K57 .content .sub-table table tbody tr {padding: 0.5vw 0;}' +
        '.K57 .content .sub-table table tbody tr td {width: 50%;max-width: 50%;font-size: 1rem;text-align: end;}' +
        '.K57 .content .sub-table table tbody tr > .pay {font-size: 1rem;font-weight: bold;}' +
        '.K57 .content .payment {font-size: 1rem;padding: 0.5vw 0;border-bottom: 1px black dashed;}' +
        '.K57 .footer {padding: 0.5vw 0 1vw 0;}' +
        '.K57 .footer-footer {font-size: 0.9rem;text-align: center;padding: 0.5vw 0;}' +
        '.K57 .footer-barcode {text-align: center;}'
    });
  }

}
