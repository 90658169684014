<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div id="inventory-a4">
        <div class="A4">
            <div style="text-align: center;" *ngIf="infoStore">
                <i *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</i>
                <h3 *ngIf="!printer.name_store_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</h3>
                <p *ngIf="!printer.address_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{'Address'|translate}}</b>: {{infoStore.address}}</p>
                <p *ngIf="!printer.phone_number_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{'Phone number'|translate}}</b>:{{infoStore.phone}}</p>
                <div *ngIf="!printer.tax_code_hidden"  
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}
                </div>
                <div *ngIf="!printer.email_hidden"  
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Email" | translate}} : {{infoStore['email']}}
                </div>
            </div>
            <h3 class="title" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{'INVENTORY' | translate}}
            </h3>
            <p class="title"><i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Inventory employee'|translate}}</b>:{{billInfo.employee.name}}</i></p>
            <div class="customer">
                <table style="border-bottom: 1px black solid;width: 100%;">
                    <thead>
                        <tr>
                            <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center; width: 15%">{{'No.'|translate}}</th>
                            <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center; width: 65%">{{'Product name'|translate}}</th>
                            <th  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center;width: 20%">{{'Checked'|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="width: 100%" *ngFor="let item of billDetails; let i = index">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 15% ; white-space: normal; text-align: center;">{{i+1}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 65% ; white-space: normal;text-align: left;">{{item.name}}</td>
                            <td  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 20% ; white-space: normal;text-align: right;">
                                {{item.quantity}}
                            </td>
                        </tr>
                        <tr>
                    </tbody>
                </table>
                <div class="bill-body-sub">
                    <div class="bill-body-sub-key" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Total checked'|translate}}: </b> {{billInfo.total_checked}}
                    </div>
                </div>
                <div *ngIf="!printer.barcode_hidden" class="footer ion-text-center">
                    <svg id="barcode-inventory-a4"></svg>
                    <p ><i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</i></p>
                </div>
            </div>
            <div *ngIf="!billInfo" style="text-align: start; font-size:12px; ">
                <i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}} : {{'This is a draff'|translate}}</i>
            </div>
        </div>
    </div>
    <div id="electron-inventory-a4" style="display: none;">
        <div style="text-align: justify;">
            <div style="text-align: center;" *ngIf="infoStore">
                <i *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</i>
                <h3 *ngIf="!printer.name_store_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</h3>
                <p *ngIf="!printer.address_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{'Address'|translate}}</b>: {{infoStore.address}}</p>
                <p *ngIf="!printer.phone_number_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{'Phone number'|translate}}</b>:{{infoStore.phone}}</p>
            </div>
            <h3 style=" text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{'INVENTORY' | translate}}
            </h3>
            <p style=" text-align: center;"><i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Inventory employee'|translate}}</b>:{{billInfo.employee.name}}</i></p>
            <div style=" margin:10px;">
                <table style="border-bottom: 1px black solid;width: 100%;">
                    <thead>
                        <tr>
                            <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center; border: 1px black solid;width: 15%">{{'No.'|translate}}</th>
                            <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center; border: 1px black solid;width: 65%">{{'Product name'|translate}}</th>
                            <th  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: center;border: 1px black solid;width: 20%">{{'Checked'|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="width: 100%" *ngFor="let item of billDetails; let i = index">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 15% ; white-space: normal;border: 1px black solid; text-align: center;">{{i+1}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 65% ; white-space: normal;border: 1px black solid;text-align: left;">{{item.name}}</td>
                            <td  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 20% ; white-space: normal;border: 1px black solid;text-align: right;">
                                {{item.quantity}}
                            </td>
                        </tr>
                        <tr>
                    </tbody>
                </table>
                <div style="padding:5px 0;">
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Total checked'|translate}}: </b> {{billInfo.total_checked}}
                    </div>
                </div>
                <div  *ngIf="!printer.barcode_hidden" style="text-align: center;">
                    <svg id="barcode-inventory-a4"></svg>
                    <p><i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</i></p>
                </div>
            </div>
            <div *ngIf="!billInfo" style="text-align: start; font-size:12px; ">
                <i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}} : {{'This is a draff'|translate}}</i>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="footer" mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>