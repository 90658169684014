<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div id="receipts-a4" class="A4">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="  !printer.logo_hidden"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div *ngIf="!printer.name_store_hidden" class="header-store-info-name"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company | uppercase}}</b></div>
                    <div *ngIf="useBranch && !printer.name_branch_hidden" class="header-store-info-branch"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    <div *ngIf="!printer.address_hidden" class="header-store-info-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{infoStore['address']}}</div>
                    <div *ngIf="!printer.phone_number_hidden" class="header-store-info-contact" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{"Phone
                        number" | translate}} : {{infoStore['phone']}}</div>
                        <div *ngIf="!printer.tax_code_hidden"   style="padding: 2px 16px; "
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"   style="padding: 2px 16px; "
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                </div>
            </div>
            <div class="header-title"><b
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{(([7,25].includes(billInfo.bill_type) ? 'Other
                    receipts':'Receive from customer receivables' )| translate) | uppercase}}</b></div>

            <div class="content">
                <div class="content-invoice">
                    <div *ngIf="!printer.bill_code_hidden" class="content-invoice-bill-code" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt
                        code" | translate}} : <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b></div>
                    <div *ngIf="!printer.date_hidden" class="content-invoice-date" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" |
                        translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy
                            HH:mm'}}</b>
                    </div>
                </div>
                <div class="content-info">
                    <div class="content-info-detail">
                        <div *ngIf="[7].includes(billInfo.bill_type)" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Payer" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                        </div>

                        <div *ngIf="[11].includes(billInfo.bill_type)">
                            <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Payer" | translate}} : <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.name}}</b>
                            </div>
                            <div *ngIf="!printer.customer_phone_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                                number" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.phone}}</b>
                            </div>
                            <div *ngIf="!printer.customer_address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                                | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.address}}</b>
                            </div>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}: <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] |
                                translate}}</b>
                        </div>
                        <div *ngIf="billInfo.payment_type == 3">
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Account name" | translate}}: <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                                    invoiceInfo['wallet'].name:
                                    ''}}</b>
                            </div>
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Account number" | translate}}: <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                                    invoiceInfo['wallet'].number:
                                    ''}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="content-info-barcode">
                        <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.employee.name}}</b></div>
                        <svg *ngIf="!printer.barcode_hidden" id="barcode-receipt"></svg>

                    </div>
                </div>
                <div class="content-note" *ngIf="!printer.note_hidden">
                    <div class="content-note-label" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" |
                        translate}} :</div>
                    <!-- <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div> -->
                </div>
            </div>

            <div class="signature">
                <div style="text-align: center;"  *ngIf="!printer.signature_payer_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Payer" |
                        translate}}</b><br><em 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div style="text-align: center;" *ngIf="!printer.signature_creator_hidden"><b 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by'
                        |translate}}</b><br><em 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em></div>
                <div style="text-align: center;" *ngIf="!printer.signature_treasurer_hidden"><b 
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br><em
                         [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                        name'|translate}})</em>
                </div>
            </div>
        </div>
    </div>
    <div style="display: none;" id="receipts-electron-a4" class="A4">
        <div>
            <div style="display: grid;
            grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img style="width: 100%;" *ngIf="  !printer.logo_hidden" style="width: 100%;"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div>
                    <div *ngIf="!printer.name_store_hidden" style="padding: 2px 16px; "><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company | uppercase}}</b></div>
                    <div *ngIf="useBranch && !printer.name_branch_hidden" style="padding: 2px 16px; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    <div *ngIf="!printer.address_hidden" style="padding: 2px 16px; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{infoStore['address']}}</div>
                    <div *ngIf="!printer.phone_number_hidden" style="padding: 2px 16px; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Phone
                        number" | translate}} : {{infoStore['phone']}}</div>
                        <div *ngIf="!printer.tax_code_hidden"   style="padding: 2px 16px; "
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"   style="padding: 2px 16px; "
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                </div>
            </div>

            <div style="text-align: center;"><b
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{(([7,25].includes(billInfo.bill_type) ? 'Other
                    receipts':'Receive from customer receivables' )| translate) | uppercase}}</b></div>

            <div>
                <div style="display: grid;
                grid-template-columns: 50% 50%;">
                    <div  *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receipt
                        code" | translate}} : <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b></div>
                    <div  *ngIf="!printer.date_hidden" style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Date" |
                        translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy
                            HH:mm'}}</b>
                    </div>
                </div>
                <div style="display: grid;
                grid-template-columns: 50% 50%;">
                    <div>
                        <div  *ngIf="[7].includes(billInfo.bill_type)"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Payer" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.name}}</b>
                        </div>

                        <div *ngIf="[11].includes(billInfo.bill_type)">
                            <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Payer" | translate}} : <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.name}}</b>
                            </div>
                            <div *ngIf="!printer.customer_phone_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                                number" | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.phone}}</b>
                            </div>
                            <div *ngIf="!printer.customer_address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                                | translate}}:
                                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.customer.address}}</b>
                            </div>
                        </div>
                        <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total
                            amount" | translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" |
                            translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b>
                        </div>
                        <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment
                            method' | translate}}: <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] |
                                translate}}</b>
                        </div>
                        <div *ngIf="billInfo.payment_type == 3">
                            <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Account name" | translate}}: <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                                    invoiceInfo['wallet'].name:
                                    ''}}</b>
                            </div>
                            <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Account number" | translate}}: <b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                                    invoiceInfo['wallet'].number:
                                    ''}}</b>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: end;">
                        <div  *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" |
                            translate}} : <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.employee.name}}</b></div>
                        <svg  *ngIf="!printer.barcode_hidden" id="barcode-receipt"></svg>

                    </div>
                </div>
                <div style="text-align: left" *ngIf="!printer.note_hidden">
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" |
                        translate}} :</div>
                    <!-- <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                    <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div>
                    <div style="border-bottom: 1px dotted black;height: 1.8vw;"></div> -->
                </div>
            </div>

            <div style=" display: flex;
            justify-content: space-between;
            padding: 16px;">
            <div style="text-align: center;"  *ngIf="!printer.signature_payer_hidden">
                <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Payer" |
                    translate}}</b><br><em 
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
            </div>
            <div style="text-align: center;" *ngIf="!printer.signature_creator_hidden"><b 
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by'
                    |translate}}</b><br><em 
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                    name'|translate}})</em></div>
            <div style="text-align: center;" *ngIf="!printer.signature_treasurer_hidden"><b 
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br><em
                     [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                    name'|translate}})</em>
            </div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}</ion-button>
    </ion-toolbar>
</ion-footer>