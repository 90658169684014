import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';

import { LanguageService } from 'src/app/services/language.service';
import printJS from 'print-js';
import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;
@Component({
  selector: 'app-delivery-a45',
  templateUrl: './delivery-a45.component.html',
  styleUrls: ['./delivery-a45.component.scss']
})
export class DeliveryA45Component implements OnInit {

  @Input() billInfo: any;
  @Input() billDetails: any;
  @Input() printer: any;
  @Input() data_delivery: any;
  infoStore: any = {}
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    public vhAlgorithm: VhAlgorithm,
    public platform: Platform,

    private lang: LanguageService,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales,
    private fncService: FunctionService
  ) {
  }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    if (this.billInfo) this.setTitle(this.data_delivery.type);
    // jsbarcode("#barcode-delivery-a4", this.billInfo['bill_code'], {
    //   displayValue: false,
    //   height: 30
    // });
    
  }
  close() {
    this.vhComponent.hideModal()
  }
  setTitle(type) {
    this.billInfo.name_transport = this.filterNameDelivery(type);
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-delivery-a4").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      a4:true,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "delivery-a4",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 10pt;}' +
        '.A4 {width: 100%; font-family: Arial;}' +
        '.A4 .header-store {font-size: 1.8vw}' +
        '.A4 .header-store > div {font-size: 1.8vw}' +
        '.A4 .header-store .name {font-weight: bold;}' +
        '.A4 .header-name {font-size: 2vw;text-align: center;margin: 0.05vw 0;font-weight: bold;text-transform: uppercase;}' +
        '.A4 .header-invoice {display: flex;justify-content: space-around;}' +
        '.A4 .header-invoice .billInfo {font-size: 1.8vw}' +
        '.A4 .header-invoice .billInfo span {font-size: 1.8vw;font-weight: bold;}' +
        '.A4 .header-invoice .billInfo-svg {text-align: center;}' +
        // '.A4 .header-invoice .billInfo-svg svg {width: 100%;}' +
        '.A4 .content {margin-top: 0.05vw;width: 100%;display: flex;justify-content: space-between;margin:1vw 0;}' +
        '.A4 .content .content-left {width: 65%; max-width: 65%; margin-right: 0.05;}' +
        '.A4 .content .content-left table , .A4 .content .content-left thead , .A4 .content .content-left tbody {border: none;border-collapse: collapse;}' +
        '.A4 .content .content-left table {width : 100%}' +
        '.A4 .content .content-left .content-pay-method {font-size: 1.8vw;margin:1vw 0;}' +
        '.A4 .content .content-left table thead th {font-size: 1.8vw;border: 1px black solid;border-collapse: collapse;padding: 0.05vw 0.1vw;}' +
        '.A4 .content .content-left table tbody tr td {font-size: 1.8vw;border: 1px black solid;border-collapse: collapse;padding: 0.05vw 0.1vw;}' +
        '.A4 .content .content-right {width: 30%;}' +
        '.A4 .content .content-right div {padding: 0.05vw;text-align: center;border: 1px solid gray;}' +
        '.A4 .content .content-right h5 {font-size: 1.8vw;font-weight: initial;margin: 0;padding: 0;}' +
        '.A4 .signature {margin: 0.05vw;display: flex;justify-content: space-around;}' +
        '.A4 .signature-detail > b {font-size: 1.8vw;}' +
        '.A4 .signature-detail > em {font-size: 1.5vw;}'
    })
  }
  filterNameDelivery(type) {
    let name;
    switch (type) {
      case 1: name = 'Giao hang nhanh'; break;
      case 2: name = 'Giao hang tiet kiem'; break;
      case 3: name = 'AhaMove'; break;
      case 4: name = 'VietNam Post'; break;
      case 5: name = 'Viettel Post'; break;
    }
    return name
  }

}
