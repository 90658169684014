<ion-content scrollX="true">
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div class="A4" id="sales-a45a">
        <div class="header">
            <div class="header-store"
                [ngStyle]="{'grid-template-columns': !printer.qr_ibanking && infoBranch.qr_ibanking ? '7% 80% 13%' : '7% 93%'}">
                <div class="header-store-logo">
                    <img *ngIf="!printer.logo_hidden"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b>
                        </div>
                        <div *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div class="header-store-info-contact">
                        <div *ngIf="!printer.address_hidden" class="header-store-info-contact-address"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.phone_number_hidden" class="header-store-info-contact-phone"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden" class="header-store-info-contact-address"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden" class="header-store-info-contact-phone"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div class="header-store-info-invoice"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{(invoice.bill_type == 1 ? ('Sales invoice'| translate) :('Temporary sales invoice'| translate))  | uppercase}}</div>
                </div>
                <div style="text-align: center;"
                    *ngIf="!printer.qr_ibanking && infoBranch.qr_ibanking">
                    <img style="width: 100%;" [src]="infoBranch.qr_ibanking" alt="">
                </div>

            </div>
            <div class="header-invoice">
                <div class="header-invoice-line">
                    <div *ngIf="!printer.bill_code_hidden" class="header-invoice-line-left"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" class="header-invoice-line-right"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{
                            invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div class="header-invoice-line" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" class="header-invoice-line-left"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>

                    </div>
                    <div *ngIf="!printer.employee_name_hidden" class="header-invoice-line-right"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoiceInfo["employee"] ?
                            invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div class="header-invoice-line-left"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden">
                            {{ "Address" | translate }} :
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                renderAddress(invoiceInfo["partner"]) ||
                                invoice.retail_address
                                }}</b>
                        </ng-container>


                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                invoiceInfo["partner"].phone
                                }}{{invoice.retail_phone}}</b>
                        </ng-container>

                    </div>
                    <div class="header-invoice-line-right" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>
                <div class="header-invoice-line"
                    *ngIf="!printer.total_points_customer_hidden && invoice.id_customer != 'id_retail'">
                    <div class="header-invoice-line-left"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container>
                            {{ "Điểm tích lũy" | translate }} :
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhQuerySales.getlocalCustomerPoints(invoice.id_customer)}}</b>
                        </ng-container>




                    </div>

                </div>

            </div>
        </div>
        <div class="content">
            <table>
                <thead>
                    <th style="max-width: 5%; width: 5%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "No." |
                        translate }}</th>
                    <th style="max-width: 48%; width: 48%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Product
                        name" | translate }}</th>
                    <th style="max-width: 10%; width: 10%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Unit" |
                        translate }}</th>
                    <th style="max-width: 7%; width: 7%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Quantity" |
                        translate }}</th>
                    <th style="max-width: 15%; width: 15%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Unit price" | translate }}
                    </th>
                    <th *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Giảm giá" |
                        translate }}</th>
                    <th style="max-width: 15%; width: 15%"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Amount"
                        | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr *ngIf="!item.id_promotion">
                            <td style="max-width: 5%; width: 5%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ i + 1 }}
                            </td>

                            <td style="max-width: 48%; width: 48%; padding: 2px;
                            border: 1px black solid;padding: 2px;">
                                <div
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{ item.name }}
                                    <ng-container *ngIf="item.id_lotproduct">
                                        <div
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}}
                                        </div>
                                        <div
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="item.combos">
                                    <span *ngFor="let data of item.combos"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                        {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                </ng-container>
                            </td>
                            <td style="max-width: 10%; width: 10%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{getUnit(item.units,item.ratio)}}
                            </td>
                            <td style="max-width: 7%; width: 7%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ item.quantity }}
                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: end; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price) }}
                                <br />
                                <span style="text-decoration: line-through;
                                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                    *ngIf="item.price < item.price_origin && display_promotion_selling_price">
                                    {{ vhAlgorithm.vhcurrencyunit(item.price_origin) }}
                                </span>
                            </td>
                            <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{(item.price_origin > item.price ? ((1 - item.price/item.price_origin)*100) : 0) |
                                number:'1.0-2'}}%
                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: end; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price * item.quantity) }}
                            </td>
                        </tr>
                        <ng-container *ngIf="item.id_promotion">
                            <tr style="cursor: pointer;">
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{i+1}}</td>
                                <td colspan="5"><b
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</b>
                                </td>
                            </tr>
                            <ng-container *ngIf="item.pmtype != 6 &&  item.pmtype != 7">
                                <tr style="cursor: pointer;" *ngFor="let data of item.products">
                                    <td></td>
                                    <td
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{data.name}} <br>
                                        <ng-container *ngIf="data.combos">
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                ({{combo.unit}}), </span>
                                        </ng-container>
                                    </td>
                                    <td style="text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        ({{getUnit(data.units,data.ratio)}})
                                    </td>
                                    <td style="text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{data.quantity}}</td>
                                    <td style="text-align: right;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                        <del [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            *ngIf="display_promotion_selling_price">
                                            {{data.price_origin > data.price ?
                                            vhAlgorithm.vhcurrencyunit(data.price_origin)
                                            : ''}}
                                        </del>
                                    </td>
                                    <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                                    border: 1px black solid;padding: 2px;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{(data.price_origin > data.price ? ((1 - data.price/data.price_origin)*100) :
                                        0) | number:'1.0-2'}}%
                                    </td>
                                    <td style="text-align: right;"><b
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(data.quantity
                                            * data.price)}}</b>
                                    </td>
                                </tr>
                            </ng-container>


                            <tr style="cursor: pointer;" *ngFor="let data of item.products_gift">
                                <td></td>
                                <td
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{data.name}} <br>
                                    <ng-container *ngIf="data.combos">
                                        <span
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                            ({{combo.unit}}), </span>
                                    </ng-container>
                                </td>
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    ({{getUnit(data.units,data.ratio)}})
                                </td>
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{data.quantity}}</td>
                                <td style="text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                    <del [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="display_promotion_selling_price">
                                        {{data.price_origin > data.price ? vhAlgorithm.vhcurrencyunit(data.price_origin)
                                        : ''}}
                                    </del>
                                </td>
                                <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                                    border: 1px black solid;padding: 2px;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{(data.price_origin > data.price ? ((1 - data.price/data.price_origin)*100) : 0) |
                                    number:'1.0-2'}}%
                                </td>
                                <td style="text-align: right;"><b
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(data.quantity
                                        * data.price)}}</b>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                "SubTotal" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                vhAlgorithm.vhcurrencyunit(subTotal) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_hidden && invoice.discount">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 |
                            number:'1.0-2'}}%)
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_bill_hidden && invoice.discount_bill">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Discount" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount_bill) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.payment_points_hidden && invoice.payment_points">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Thanh toán điểm" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.payment_points) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.payment_coupon_hidden && invoice.payment_coupons">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Thanh toán phiếu" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.payment_coupons) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Fee" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.fee) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Tax" | translate }} ({{ vhAlgorithm.vhpercent(invoice.tax / 100) }})
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                "Total" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.total +
                                invoice.total*( invoice.tax/100)) : vhAlgorithm.vhcurrencyunit(invoice.total +
                                invoice.total*( invoice.tax/100))
                                }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                "PAYMENT" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.payment) :
                                vhAlgorithm.vhcurrencyunit(invoice.payment)
                                }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.debt_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "REST" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) -
                            invoice.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.cash) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash -
                            invoice.payment : 0)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="content-pay">
                <div class="content-pay-method"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{ "Payment method" | translate }}: {{ invoiceInfo.paymentName }}
                </div>
                <div class="content-pay-bank" *ngIf="invoice.payment_type == 3">
                    <div class="content-pay-bank-name"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account name" | translate }}: {{ invoiceInfo["wallet"].name }}
                    </div>
                    <div class="content-pay-bank-number"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account number" | translate }}: {{ invoiceInfo["wallet"].number }} - {{
                        invoiceInfo["wallet"].bank_name}}
                    </div>
                </div>
            </div>
            <div class="content-note" *ngIf="!printer.note_hidden"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                {{ "Note" | translate }}:
                <pre>{{ invoice.note }}</pre>
            </div>
            <div class="content-note">
                <pre
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="signature">
            <ng-container>
                <div class="signature-detail" *ngIf="!printer.signature_buyer_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Buyer" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> ({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_shipper_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Deliver" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_creator_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Prepared by" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div class="signature-detail" *ngIf="vhAuth.localStorageGET('show_signature_stocker')">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Stocker" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div class="signature-detail" *ngIf="!printer.signature_accountancy_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Chief accountant" | translate
                        }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" |
                        translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
    <div id="electron-sales-a45a" style="display: none;">
        <div style="padding:4px 0;">
            <div style="display: grid;"   [ngStyle]="{'grid-template-columns': !printer.qr_ibanking && infoBranch.qr_ibanking ? '7% 80% 13%' : '7% 93%'}">
                <div style="text-align: center;">
                    <img *ngIf="!printer.logo_hidden" style="width: 100%;"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div>
                    <div style=" display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 65% 35%;">
                        <div *ngIf="!printer.name_store_hidden"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b>
                        </div>
                        <div *ngIf="!printer.name_branch_hidden"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div style="display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 65% 35%;">
                        <div *ngIf="!printer.address_hidden"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.phone_number_hidden"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>

                        <div *ngIf="!printer.tax_code_hidden"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div style="text-align: center;padding: 2px 0; font-weight: bold;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{(invoice.bill_type == 1 ? ('Sales invoice'| translate) :('Temporary sales invoice'| translate))  | uppercase}}</div>
                </div>
                
                <div style="text-align: center;"
                    *ngIf="!printer.qr_ibanking && infoBranch.qr_ibanking">
                    <img style="width: 100%;" [src]="infoBranch.qr_ibanking" alt="">
                </div>
            </div>
            <div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div *ngIf="!printer.bill_code_hidden" style=" text-align: start; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b style="font-size: 0.8rem"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" style=" text-align: end; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b style="font-size: 0.8rem"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" style=" text-align: start; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b style="font-size: 0.8rem"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" style="text-align: end; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b style="font-size: 0.8rem"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoiceInfo["employee"] ?
                            invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div style=" text-align: start; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden">
                            {{ "Address" | translate }} :
                            <b style="font-size: 0.8rem"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                renderAddress(invoiceInfo["partner"]) ||
                                invoice.retail_address
                                }}</b>
                        </ng-container>


                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b style="font-size: 0.8rem"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                invoiceInfo["partner"].phone
                                }}{{invoice.retail_phone}}</b>
                        </ng-container>

                    </div>
                    <div style=" text-align: end;" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>

                <div *ngIf="!printer.total_points_customer_hidden && invoice.id_customer != 'id_retail'">
                    <div style=" text-align: start; "
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container>
                            {{ "Điểm tích lũy" | translate }} :
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhQuerySales.getlocalCustomerPoints(invoice.id_customer)}}</b>
                        </ng-container>




                    </div>

                </div>
            </div>
        </div>
        <div>
            <table style="width: 100%;border-collapse: collapse;">
                <thead>
                    <th style="max-width: 5%; width: 5%; border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "No." |
                        translate }}</th>
                    <th style="max-width: 48%; width: 48%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Product
                        name" | translate }}</th>
                    <th style="max-width: 10%; width: 10%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Unit" |
                        translate }}</th>
                    <th style="max-width: 7%; width: 7%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Quantity" |
                        translate }}</th>
                    <th style="max-width: 15%; width: 15%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Unit price" | translate }}
                    </th>
                    <th *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Giảm giá" |
                        translate }}</th>
                    <th style="max-width: 15%; width: 15%;
                    border: 1px black solid;padding: 2px;"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                        "Amount"
                        | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr *ngIf="!item.id_promotion">
                            <td style="max-width: 5%; width: 5%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ i + 1 }}
                            </td>
                            <td style="max-width: 48%; width: 48%; padding: 2px;
                            border: 1px black solid;padding: 2px;">
                                <div
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{ item.name }}
                                    <ng-container *ngIf="item.id_lotproduct">
                                        <div
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            Mã lô: {{getLotNumber(item.lots, item.id_lotproduct)}}
                                        </div>
                                        <div
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{getDateLotNumber(item.lots, item.id_lotproduct)}}
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="item.combos">
                                    <span *ngFor="let data of item.combos"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                        {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                </ng-container>
                            </td>
                            <td style="max-width: 10%; width: 10%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{getUnit(item.units,item.ratio)}}
                            </td>
                            <td style="max-width: 7%; width: 7%; text-align: center; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ item.quantity }}
                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: end; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price) }}
                                <br />
                                <span style="text-decoration: line-through;
                                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                    *ngIf="item.price < item.price_origin && display_promotion_selling_price">
                                    {{ vhAlgorithm.vhcurrencyunit(item.price_origin) }}
                                </span>
                            </td>
                            <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{(item.price_origin > item.price ? ((1 - item.price/item.price_origin)*100) : 0) |
                                number:'1.0-2'}}%
                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: end; padding: 2px;
                            border: 1px black solid;padding: 2px;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price * item.quantity) }}
                            </td>
                        </tr>
                        <ng-container *ngIf="item.id_promotion">
                            <tr style="cursor: pointer;">
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{i+1}}</td>
                                <td colspan="5"><b
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</b>
                                </td>
                            </tr>
                            <ng-container *ngIf="item.pmtype != 6 &&  item.pmtype != 7">
                                <tr style="cursor: pointer;" *ngFor="let data of item.products">
                                    <td></td>
                                    <td
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{data.name}} <br>
                                        <ng-container *ngIf="data.combos">
                                            <span
                                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                                *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                                ({{combo.unit}}), </span>
                                        </ng-container>
                                    </td>
                                    <td style="text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        ({{getUnit(data.units,data.ratio)}})
                                    </td>
                                    <td style="text-align: center;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{data.quantity}}</td>
                                    <td style="text-align: right;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                        <del [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            *ngIf="display_promotion_selling_price">
                                            {{data.price_origin > data.price ?
                                            vhAlgorithm.vhcurrencyunit(data.price_origin)
                                            : ''}}
                                        </del>
                                    </td>
                                    <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                                    border: 1px black solid;padding: 2px;"
                                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                        {{(data.price_origin > data.price ? ((1 - data.price/data.price_origin)*100) :
                                        0) | number:'1.0-2'}}%
                                    </td>
                                    <td style="text-align: right;"><b>{{vhAlgorithm.vhcurrencyunit(data.quantity *
                                            data.price)}}</b>
                                    </td>
                                </tr>
                            </ng-container>

                            <tr style="cursor: pointer;" *ngFor="let data of item.products_gift">
                                <td
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{data.name}} <br>
                                    <ng-container *ngIf="data.combos">
                                        <span
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                            *ngFor="let combo of data.combos">{{combo.quantity}}x {{combo.name}}
                                            ({{combo.unit}}), </span>
                                    </ng-container>
                                </td>
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    ({{getUnit(data.units,data.ratio)}})
                                </td>
                                <td style="text-align: center;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{data.quantity}}</td>
                                <td style="text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(data.price)}}
                                    <del [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="display_promotion_selling_price">
                                        {{data.price_origin > data.price ? vhAlgorithm.vhcurrencyunit(data.price_origin)
                                        : ''}}
                                    </del>
                                </td>
                                <td *ngIf="!printer.discount_bill_detail_hidden" style="max-width: 7%; width: 7%; text-align: center; padding: 2px; 
                                    border: 1px black solid;padding: 2px;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{(data.price_origin > data.price ? ((1 - data.price/data.price_origin)*100) : 0) |
                                    number:'1.0-2'}}%
                                </td>
                                <td style="text-align: right;"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    <b>{{vhAlgorithm.vhcurrencyunit(data.quantity * data.price)}}</b>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>



                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                "SubTotal" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                                vhAlgorithm.vhcurrencyunit(subTotal) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_hidden && invoice.discount">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 |
                            number:'1.0-2'}}%)
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_bill_hidden && invoice.discount_bill">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Discount" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount_bill) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.payment_points_hidden && invoice.payment_points">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Thanh toán điểm" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.payment_points) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.payment_coupon_hidden && invoice.payment_coupons">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Thanh toán phiếu" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.payment_coupons) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Fee" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.fee) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Tax" | translate }} ({{ vhAlgorithm.vhpercent(invoice.tax / 100) }})
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                "Total" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.total +
                                invoice.total*( invoice.tax/100)) : vhAlgorithm.vhcurrencyunit(invoice.total +
                                invoice.total*( invoice.tax/100))
                                }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                "PAYMENT" | translate }}</b>
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{
                                printer.currency_hidden ? vhAlgorithm.vhcurrencyunit_symbol(invoice.payment) :
                                vhAlgorithm.vhcurrencyunit(invoice.payment)
                                }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.debt_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "REST" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) -
                            invoice.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.cash) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td [attr.colspan]="printer.discount_bill_detail_hidden ? '3' : '4'" style="text-align: center;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="3" style="text-align: end;
                        border: 1px black solid;padding: 2px;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash -
                            invoice.payment : 0)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="padding:4px 0;">
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{ "Payment method" | translate }}: {{ invoiceInfo.paymentName }}
                </div>
                <div *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account name" | translate }}: {{ invoiceInfo["wallet"].name }}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account number" | translate }}: {{ invoiceInfo["wallet"].number }} - {{
                        invoiceInfo["wallet"].bank_name}}
                    </div>
                </div>
            </div>
            <div *ngIf="!printer.note_hidden"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                {{ "Note" | translate }}:
                <pre>{{ invoice.note }}</pre>
            </div>
            <div>
                <pre
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div style=" padding: 16px;
        display: flex;
        justify-content: space-between;">
            <ng-container>
                <div style=" text-align: center;" *ngIf="!printer.signature_buyer_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Buyer" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> ({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_shipper_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Deliver" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_creator_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Prepared by" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="vhAuth.localStorageGET('show_signature_stocker')">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Stocker" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_accountancy_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        "Chief accountant" | translate
                        }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{
                        "Sign, write full name" |
                        translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button color="vh-green" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">
            {{ "Print" | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>