import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode'
import { VhAlgorithm, VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';

import { LanguageService } from 'src/app/services/language.service';
import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;

@Component({
  selector: 'app-expense-k80-col3',
  templateUrl: './expense-k80-col3.component.html',
  styleUrls: ['./expense-k80-col3.component.scss']
})
export class ExpenseK80Col3Component implements OnInit {

  @Input() printer: any;
  @Input() billInfo: any;
  infoStore: any = {}
  invoiceInfo: any = {}
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    public platform: Platform,
    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales,
    private vhAuth: VhAuth,
    private fncService: FunctionService
  ) { }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.billInfo.id_employee),
      partner: this.vhQuerySales.getlocalSupplier(this.billInfo.id_supplier)? this.vhQuerySales.getlocalSupplier(this.billInfo.id_supplier) : {name : 'Công ty TNHH GPCN Viethas'},
      paymentName: this.setPaymentName(this.billInfo.payment_type),
    }
    if (this.billInfo.id_wallet) this.invoiceInfo['wallet'] = this.vhQuerySales.getlocalWallet(this.billInfo.id_wallet)
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(!this.printer.barcode_hidden)  jsbarcode("#barcode-expense", this.billInfo['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.lang.translate("Cash")
      case 2: return this.lang.translate("Debit")
      case 3: return this.lang.translate("Account")
    }
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("expenses-electron-k57a").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "expenses-k57a",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K57 {  padding-bottom: 32px;}' +
        '.K57 .header { border-bottom: 1px black dashed; padding: 4px 0;}' +
        '.K57 .header-header { text-align: center; font-size: 2vw; padding: 4px 0;}' +
        '.K57 .header-store-name { text-align: center; font-size: 5vw;}' +
        '.K57 .header-store-address, .K57 .header-store-phone { text-align: center; font-size: 4vw;}' +
        '.K57 .content { padding: 4px 0;}' +
        '.K57 .content-header { border-bottom: 1px black dashed;}' +
        '.K57 .content-header-title { text-align: center;font-size: 6vw;}' +
        '.K57 .content-header-info { text-align: start;font-size: 4vw;}' +
        '.K57 .content-info-detail { text-align: left;}' +
        '.K57 .content-note { text-align: left;}' +
        '.K57 .signature { display: flex;justify-content: space-between;padding: 16px;}' +
        '.K57 .signature-detail { text-align: center;}' +
        '.K57 .signature-detail > b { font-size: 2.5vw;}' +
        '.K57 .signature-detail > em { font-size: 2vw;}'
    });
  }


}
