<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div class="A4" id="delivery-a4">
        <div class="header">
            <div class="header-store" *ngIf="infoStore">
                <div *ngIf="!printer.name_store_hidden" class="name"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore.company}}</div>
                <div *ngIf="useBranch && !printer.name_branch_hidden" class="branch"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoBranch.name}}</div>
                <div *ngIf="!printer.address_hidden" class="address"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="phone"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                    number" |translate}}:
                    {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden"  
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}
                    </div>
                    <div *ngIf="!printer.email_hidden"  
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Email" | translate}} : {{infoStore['email']}}
                    </div>
            </div>
            <div class="header-name"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{"Delivery
                Receipt" | translate}}
            </div>
            <div class="header-invoice">
                <div style="width: 50%; text-align: left;" class="billInfo"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Transport
                        Companies" | translate}}:
                    </span>{{billInfo.name_transport | translate}} <br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice
                        code" | translate}}: </span>
                    {{billInfo.bill_code}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Order
                        code" | translate}}:
                    </span>{{billInfo.order_code}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date"
                        | translate}}:
                    </span>{{billInfo.data.created | date: 'dd/MM/yyyy'}}<br />
                </div>
                <!-- <div style="width: 20%;" class="billInfo billInfo-svg">
                    <svg id="barcode-delivery-a4"></svg>
                </div> -->
                <div style="width: 50%;text-align: right;" class="billInfo"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver
                        Information" |
                        translate}}</span><br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Customer"
                        | translate}}:
                    </span>{{billInfo.data.customer_fullname | translate}} <br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"_phone"
                        | translate}}: </span>
                    {{billInfo.data.customer_tel}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                        | translate}}:
                    </span>{{billInfo.data.address}}<br />
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-left">
                <table>
                    <thead>
                        <th style="max-width: 10%; width: 10%;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"No."|translate}}</th>
                        <th style="max-width: 70%; width: 70%; text-align: left;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"Name" | translate}}</th>
                        <th style="max-width: 20%; width: 20%;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"Quantity" | translate}}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of billDetails; let i = index">
                            <td style="max-width: 10%; width: 10%; text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{i + 1}}</td>
                            <td style="max-width: 70%; width: 70%;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{item.name_product || item.name}}</td>
                            <td style="max-width: 20%; width: 20%; text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{item.quantity}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Shipping fee" | translate}}</td>
                            <td colspan="1" style="text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.ship_money)}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Insurance fee'|translate}}</td>
                            <td colspan="1" style="text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.insurance)}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;"><b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Total'|translate}}</b>
                            </td>
                            <td colspan="1" style="text-align: center;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.ship_money + billInfo.data.insurance)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="content-pay">
                    <div class="content-pay-method" *ngIf="billInfo.data.is_freeship == 1"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payment type" | translate}}: {{"Paid by seller" | translate}}
                    </div>
                    <div class="content-pay-method" *ngIf="billInfo.data.is_freeship == 0"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payment type" | translate}}: {{"Paid by receiver" | translate}}
                    </div>
                </div>
            </div>
            <div class="content-right">
                <div>
                    <h5 [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Cash on delivery"| translate}}</h5>
                    <h5 *ngIf="billInfo.data.is_freeship == 1"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{vhAlgorithm.vhcurrencyunit(billInfo.data.CodAmountEvaluation>= 0
                            ?billInfo.data.CodAmountEvaluation: billInfo.data.pick_money)}}</b>
                    </h5>
                    <h5 *ngIf="billInfo.data.is_freeship == 0"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{vhAlgorithm.vhcurrencyunit(billInfo.data.CodAmountEvaluation >=0?
                            billInfo.data.CodAmountEvaluation:(data_delivery.type ==
                            2?(billInfo.data.pick_money+billInfo.data.ship_money):(billInfo.data.pick_money+billInfo.data.ship_money
                            +
                            billInfo.data.insurance)))}}</b>
                    </h5>
                </div>
            </div>
        </div>
        <div class="signature">
            <ng-container>
                <div class="signature-detail"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">Chữ ký
                        người
                        gửi</b><br><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">(Ký và
                        ghi rõ họ tên)</em></div>
                <div class="signature-detail"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">Chữ ký
                        người
                        nhận</b><br><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">(Ký và
                        ghi rõ họ tên)</em>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="A4" id="electron-delivery-a4" style="display:none">
        <div class="header">
            <div *ngIf="infoStore">
                <div style=" font-weight: bold;"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore.company}}</div>
                <div *ngIf="useBranch"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoBranch.name}}</div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone
                    number" |translate}}:
                    {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden"  
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}
                    </div>
                    <div *ngIf="!printer.email_hidden"  
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Email" | translate}} : {{infoStore['email']}}
                    </div>
            </div>
            <div style="
            text-align: center;
            margin: 2px 0;
            font-weight: bold;
            text-transform: uppercase;"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{"Delivery
                Receipt" | translate}}
            </div>
            <div style=" display: flex;
            justify-content: space-around;">
                <div style="width: 50%; text-align: left;"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Transport
                        Companies" | translate}}:
                    </span>{{billInfo.name_transport | translate}} <br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice
                        code" | translate}}: </span>
                    {{billInfo.bill_code}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Order
                        code" | translate}}:
                    </span>{{billInfo.order_code}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date"
                        | translate}}:
                    </span>{{billInfo.data.created | date: 'dd/MM/yyyy'}}<br />
                </div>
                <!-- <div style="width: 20%;" class="billInfo billInfo-svg">
                    <svg id="barcode-delivery-a4"></svg>
                </div> -->
                <div style="width: 50%;text-align: right;"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver
                        Information" |
                        translate}}</span><br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Customer"
                        | translate}}:
                    </span>{{billInfo.data.customer_fullname | translate}} <br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"_phone"
                        | translate}}: </span>
                    {{billInfo.data.customer_tel}}<br />
                    <span
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Address"
                        | translate}}:
                    </span>{{billInfo.data.address}}<br />
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;">
            <div style=" width: 65%;
            max-width: 65%;
            margin-right: 10px;">
                <table style="width: 100%;border-collapse: collapse;">
                    <thead>
                        <th style="max-width: 10%; width: 10%;
                        border: 1px black solid;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"No."|translate}}</th>
                        <th style="max-width: 70%; width: 70%; text-align: left;
                        border: 1px black solid;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"Name" | translate}}</th>
                        <th style="max-width: 20%; width: 20%;
                        border: 1px black solid;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{"Quantity" | translate}}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of billDetails; let i = index">
                            <td style="max-width: 10%; width: 10%; text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{i + 1}}</td>
                            <td style="max-width: 70%; width: 70%;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{item.name_product || item.name}}</td>
                            <td style="max-width: 20%; width: 20%; text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{item.quantity}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{"Shipping fee" | translate}}</td>
                            <td colspan="1" style="text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.ship_money)}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Insurance fee'|translate}}</td>
                            <td colspan="1" style="text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.insurance)}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center;
                            border: 1px black solid;"><b
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Total'|translate}}</b>
                            </td>
                            <td colspan="1" style="text-align: center;
                            border: 1px black solid;"
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(billInfo.data.ship_money + billInfo.data.insurance)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="  margin-top:10px;">
                    <div style="  margin-top:10px;" *ngIf="billInfo.data.is_freeship == 1"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payment type" | translate}}: {{"Paid by seller" | translate}}
                    </div>
                    <div style="  margin-top:10px;" *ngIf="billInfo.data.is_freeship == 0"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Payment type" | translate}}: {{"Paid by receiver" | translate}}
                    </div>
                </div>
            </div>
            <div style="width: 30%;">
                <div>
                    <h5 [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Cash on delivery"| translate}}</h5>
                    <h5 *ngIf="billInfo.data.is_freeship == 1"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{vhAlgorithm.vhcurrencyunit(billInfo.data.CodAmountEvaluation>=0
                            ?billInfo.data.CodAmountEvaluation: billInfo.data.pick_money)}}</b>
                    </h5>
                    <h5 *ngIf="billInfo.data.is_freeship == 0"
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{vhAlgorithm.vhcurrencyunit(billInfo.data.CodAmountEvaluation >=0?
                            billInfo.data.CodAmountEvaluation:(data_delivery.type ==
                            2?(billInfo.data.pick_money+billInfo.data.ship_money):(billInfo.data.pick_money+
                            billInfo.data.ship_money +
                            billInfo.data.insurance)))}}</b>
                    </h5>
                </div>
            </div>
        </div>
        <div style="  margin: 16px;
        display: flex;
        justify-content: space-around;">
            <ng-container>
                <div style="  text-align: center;"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">Chữ ký
                        người
                        gửi</b><br><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">(Ký và
                        ghi rõ họ tên)</em></div>
                <div style="  text-align: center;"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">Chữ ký
                        người
                        nhận</b><br><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">(Ký và
                        ghi rõ họ tên)</em>
                </div>
            </ng-container>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>