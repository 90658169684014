import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode';
import { VhAlgorithm, VhQuerySales, VhAuth } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';
declare var electron: any;
@Component({
  selector: 'app-print-test-k80',
  templateUrl: './print-test-k80.component.html',
  styleUrls: ['./print-test-k80.component.scss'],
})
export class PrintTestK80Component implements OnInit {
  @Input() printer: any;
  tempBill: any = {
    title: 'Invoice',
    bill_code: 'A35DE4F',
    bill_type: 1,
    date: new Date().toISOString(),
    customer: {
      name: 'Công Ty TNHH GPCN VIETHAS',
      phone: '02838661111',
      address: '161/3 Ni Sư Huỳnh Liên, Tân Bình',
    },
    employee: { name: 'Quang Nghĩa' },
    bill_details: [
      {
        id_product: 'pro001',
        name: 'BIXOLON SAMSUNG SRP-275C PRINTER',
        unit: 'pcs',
        price: 4800000,
        price_origin: 2000000,
        product_quantity: 24,
        quantity: 1,
        root_quantity: 24,
        warning_number: 10,
      },
      {
        id_product: 'pro002',
        name: 'Máy POS Xprinter XP-I100 2Gb RAM + 16Gb ROM Android K58 - XP-I100 2Gb RAM + 16Gb ROM',
        unit: 'pcs',
        price: 6290000,
        price_origin: 3000000,
        product_quantity: 24,
        quantity: 1,
        root_quantity: 24,
        warning_number: 15,
      },
      {
        id_product: 'pro003',
        name: 'Oji K80 80mmx45mm_5 rolls',
        unit: 'roll',
        price: 39000,
        price_origin: 39000,
        product_quantity: 28,
        quantity: 1,
        root_quantity: 28,
        warning_number: 20,
      },
    ],
    fee: 0,
    payment_type: 1,
    discount: 200000,
    note: 'Đây là hoá đơn in thử...',
    qty_total: 3,
    root_total: 11129000,
    tax: 0,
    tax_value: 0,
    total_atax: 10929000,
    payment: 10929000,
  };
  infoStore: any;
  constructor(
    public platform: Platform,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales,
    public vhAuth: VhAuth,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.infoStore =
      this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
   
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    jsbarcode('#barcode-invoice-k80', this.tempBill['bill_code'], {
      displayValue: false,
      height: 30,
    });
  }
  close() {
    this.vhComponent.hideModal();
  }
  checkPrint() {
    if (this.platform.is('electron')) this.printElectron();
    else this.printBrowser();
  }
  printElectron() {
    let html = document.getElementById('electron-browserMedium').innerHTML;
    electron.ipcRenderer.send('print-start', {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on('print-done', (event, data) => {
      if (!data)
        this.vhComponent.alertMessageDesktop(
          'warning',
          this.lang.translate('Printing failed'),
          3000
        );
      else this.close();
    });
  }

  printBrowser() {
    printJS({
      printable: 'browserMedium',
      type: 'html',
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K80 { padding-bottom: 64px;}' +
        '.K80 .header {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K80 .header-header {text-align: center;font-size: 0.9rem;padding: 0.5vw 0;}' +
        '.K80 .header-store-name {text-align: center;font-size: 1.1rem;}' +
        '.K80 .header-store-address, .K80 .header-store-phone {text-align: center;font-size: 1rem;}' +
        '.K80 .content {padding: 0.5vw 0;}' +
        '.K80 .content-header {border-bottom: 1px black dashed;}' +
        '.K80 .content-header-title {text-align: center;font-size: 1.2rem;}' +
        '.K80 .content-header-info {text-align: start;font-size: 1rem;}' +
        '.K80 .content .table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K80 .content .table table {width: 100%;}' +
        '.K80 .content .table table thead th {font-size: 1rem;}' +
        '.K80 .content .table table tbody tr td {font-size: 1rem;}' +
        '.K80 .content .sub-table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K80 .content .sub-table table {width: 100%;}' +
        '.K80 .content .sub-table table tbody tr {padding: 0.5vw 0;}' +
        '.K80 .content .sub-table table tbody tr td {width: 50%;max-width: 50%;font-size: 1rem;text-align: end;}' +
        '.K80 .content .sub-table table tbody tr > .pay {font-size: 1rem;font-weight: bold;}' +
        '.K80 .content .payment {font-size: 1rem;padding: 0.5vw 0;border-bottom: 1px black dashed;}' +
        '.K80 .footer {padding: 0.5vw 0 1vw 0;}' +
        '.K80 .footer-footer {font-size: 0.9rem;text-align: center;padding: 0.5vw 0;}' +
        '.K80 .footer-barcode {text-align: center;}',
    });
  }
}
