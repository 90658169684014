import { FormsModule } from '@angular/forms';
import { NgZorroAntModule } from './../../ng-zorro-ant.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReceiptA4Component } from './receipt/receipt-a4/receipt-a4.component';
import { IonicModule } from '@ionic/angular';
import { InventoryA45Component } from './inventory/A45/inventory-a45/inventory-a45.component';
import { DeliveryA45Component } from './delivery/A45/delivery-a45/delivery-a45.component';
import { PrintTestA4Component } from './test/A4/print-test-a4/print-test-a4.component';
import { PrintTestK57Component } from './test/K57/print-test-k57/print-test-k57.component';
import { PrintTestK80Component } from './test/K80/print-test-k80/print-test-k80.component';
import { TestExpendA4Component } from './test/test-expend-a4/test-expend-a4.component';
import { TestExpendK57Component } from './test/test-expend-k57/test-expend-k57.component';
import { TestExpendK80Component } from './test/test-expend-k80/test-expend-k80.component';
import { SalesVoucherA4Component } from './sales-voucher/sales-voucher-a4/sales-voucher-a4.component';
import { SalesPaymentCardA4Component } from './sales-payment-card/sales-payment-card-a4/sales-payment-card-a4.component';
import { TestTranferA4Component } from './test/test-tranfer-a4/test-tranfer-a4.component';
import { TestTranferK80Component } from './test/test-tranfer-k80/test-tranfer-k80.component';
import { TestVoucherA4Component } from './test/test-voucher-a4/test-voucher-a4.component';
import { TestCardA4Component } from './test/test-card-a4/test-card-a4.component';
import { TestVoucherK57Component } from './test/test-voucher-k57/test-voucher-k57.component';
import { TestVoucherK80Component } from './test/test-voucher-k80/test-voucher-k80.component';
import { TestCardK57Component } from './test/test-card-k57/test-card-k57.component';
import { TestCardK80Component } from './test/test-card-k80/test-card-k80.component';
import { CustomerReturnedA4Component } from './customer-returned/customer-returned-a4/customer-returned-a4.component';
import { CustomerReturnedA5Component } from './customer-returned/customer-returned-a5/customer-returned-a5.component';
import { CustomerReturnedK80Col4Component } from './customer-returned/customer-returned-k80-col4/customer-returned-k80-col4.component';
import { CustomerReturnedK80Col3Component } from './customer-returned/customer-returned-k80-col3/customer-returned-k80-col3.component';
import { CustomerReturnedK57Col3Component } from './customer-returned/customer-returned-k57-col3/customer-returned-k57-col3.component';
import { SalesA4Component } from './sales/sales-a4/sales-a4.component';
import { SalesA5Component } from './sales/sales-a5/sales-a5.component';
import { SalesK80Col4Component } from './sales/sales-k80-col4/sales-k80-col4.component';
import { SalesK80Col3Component } from './sales/sales-k80-col3/sales-k80-col3.component';
import { SalesK57Col3Component } from './sales/sales-k57-col3/sales-k57-col3.component';
import { SalesPaymentCardA5Component } from './sales-payment-card/sales-payment-card-a5/sales-payment-card-a5.component';
import { SalesPaymentCardK57Col3Component } from './sales-payment-card/sales-payment-card-k57-col3/sales-payment-card-k57-col3.component';
import { SalesPaymentCardK80Col3Component } from './sales-payment-card/sales-payment-card-k80-col3/sales-payment-card-k80-col3.component';
import { SalesPaymentCardK80Col4Component } from './sales-payment-card/sales-payment-card-k80-col4/sales-payment-card-k80-col4.component';
import { WarrantyA4Component } from './warranty/warranty-a4/warranty-a4.component';
import { WarrantyA5Component } from './warranty/warranty-a5/warranty-a5.component';
import { WarrantyK80Col4Component } from './warranty/warranty-k80-col4/warranty-k80-col4.component';
import { WarrantyK80Col3Component } from './warranty/warranty-k80-col3/warranty-k80-col3.component';
import { WarrantyK57Col3Component } from './warranty/warranty-k57-col3/warranty-k57-col3.component';
import { PurchaseA4Component } from './purchase/purchase-a4/purchase-a4.component';
import { PurchaseA5Component } from './purchase/purchase-a5/purchase-a5.component';
import { PurchaseK80Col4Component } from './purchase/purchase-k80-col4/purchase-k80-col4.component';
import { PurchaseK80Col3Component } from './purchase/purchase-k80-col3/purchase-k80-col3.component';
import { PurchaseK57Col3Component } from './purchase/purchase-k57-col3/purchase-k57-col3.component';
import { ReturnSupplierA4Component } from './return-supplier/return-supplier-a4/return-supplier-a4.component';
import { ReturnSupplierA5Component } from './return-supplier/return-supplier-a5/return-supplier-a5.component';
import { ReturnSupplierK80Col4Component } from './return-supplier/return-supplier-k80-col4/return-supplier-k80-col4.component';
import { ReturnSupplierK80Col3Component } from './return-supplier/return-supplier-k80-col3/return-supplier-k80-col3.component';
import { ReturnSupplierK57Col3Component } from './return-supplier/return-supplier-k57-col3/return-supplier-k57-col3.component';
import { ExchangePaymentCardA4Component } from './prepaid-card/exchange-payment-card-a4/exchange-payment-card-a4.component';
import { ExchangePaymentCardA5Component } from './prepaid-card/exchange-payment-card-a5/exchange-payment-card-a5.component';
import { ExchangePaymentCardK57Col3Component } from './prepaid-card/exchange-payment-card-k57-col3/exchange-payment-card-k57-col3.component';
import { ExchangePaymentCardK80Col3Component } from './prepaid-card/exchange-payment-card-k80-col3/exchange-payment-card-k80-col3.component';
import { ExchangePaymentCardK80Col4Component } from './prepaid-card/exchange-payment-card-k80-col4/exchange-payment-card-k80-col4.component';
import { ExpenseA4Component } from './expenses/expense-a4/expense-a4.component';
import { ExpenseK80Col4Component } from './expenses/expense-k80-col4/expense-k80-col4.component';
import { ExpenseK80Col3Component } from './expenses/expense-k80-col3/expense-k80-col3.component';
import { ExpenseK57Col3Component } from './expenses/expense-k57-col3/expense-k57-col3.component';
import { ExpensesA5Component } from './expenses/expenses-a5/expenses-a5.component';
import { SalesVoucherA5Component } from './sales-voucher/sales-voucher-a5/sales-voucher-a5.component';
import { SalesVoucherK57Col3Component } from './sales-voucher/sales-voucher-k57-col3/sales-voucher-k57-col3.component';
import { SalesVoucherK80Col3Component } from './sales-voucher/sales-voucher-k80-col3/sales-voucher-k80-col3.component';
import { SalesVoucherK80Col4Component } from './sales-voucher/sales-voucher-k80-col4/sales-voucher-k80-col4.component';
import { ExchangeVoucherA4Component } from './exchange-voucher/exchange-voucher-a4/exchange-voucher-a4.component';
import { ExchangeVoucherA5Component } from './exchange-voucher/exchange-voucher-a5/exchange-voucher-a5.component';
import { ExchangeVoucherK57Col3Component } from './exchange-voucher/exchange-voucher-k57-col3/exchange-voucher-k57-col3.component';
import { ExchangeVoucherK80Col3Component } from './exchange-voucher/exchange-voucher-k80-col3/exchange-voucher-k80-col3.component';
import { ExchangeVoucherK80Col4Component } from './exchange-voucher/exchange-voucher-k80-col4/exchange-voucher-k80-col4.component';
import { ReceiptA5Component } from './receipt/receipt-a5/receipt-a5.component';
import { ReceiptK80Col4Component } from './receipt/receipt-k80-col4/receipt-k80-col4.component';
import { ReceiptK80Col3Component } from './receipt/receipt-k80-col3/receipt-k80-col3.component';
import { ReceiptK57Col3Component } from './receipt/receipt-k57-col3/receipt-k57-col3.component';
import { DeliveryGoodsA4Component } from './delivery-goods/delivery-goods-a4/delivery-goods-a4.component';
import { DeliveryGoodsA5Component } from './delivery-goods/delivery-goods-a5/delivery-goods-a5.component';
import { DeliveryGoodsK80Col4Component } from './delivery-goods/delivery-goods-k80-col4/delivery-goods-k80-col4.component';
import { DeliveryGoodsK80Col3Component } from './delivery-goods/delivery-goods-k80-col3/delivery-goods-k80-col3.component';
import { DeliveryGoodsK57Col3Component } from './delivery-goods/delivery-goods-k57-col3/delivery-goods-k57-col3.component';
import { TransferMoneyA4Component } from './transfer-money/transfer-money-a4/transfer-money-a4.component';
import { TransferMoneyA5Component } from './transfer-money/transfer-money-a5/transfer-money-a5.component';
import { TransferMoneyK80Col4Component } from './transfer-money/transfer-money-k80-col4/transfer-money-k80-col4.component';
import { TransferMoneyK80Col3Component } from './transfer-money/transfer-money-k80-col3/transfer-money-k80-col3.component';
import { TransferMoneyK57Col3Component } from './transfer-money/transfer-money-k57-col3/transfer-money-k57-col3.component';
import { ReceiptsQuoteA4Component } from './receipts-quote/receipts-quote-a4/receipts-quote-a4.component';
import { ReceiptsQuoteA5Component } from './receipts-quote/receipts-quote-a5/receipts-quote-a5.component';
import { ReceiptsQuoteK80Col4Component } from './receipts-quote/receipts-quote-k80-col4/receipts-quote-k80-col4.component';
import { ReceiptsQuoteK80Col3Component } from './receipts-quote/receipts-quote-k80-col3/receipts-quote-k80-col3.component';
import { ReceiptsQuoteK57Col3Component } from './receipts-quote/receipts-quote-k57-col3/receipts-quote-k57-col3.component';
import { ReceiveGoodsA4Component } from './receive-goods/receive-goods-a4/receive-goods-a4.component';
import { ReceiveGoodsA5Component } from './receive-goods/receive-goods-a5/receive-goods-a5.component';
import { ReceiveGoodsK80Col4Component } from './receive-goods/receive-goods-k80-col4/receive-goods-k80-col4.component';
import { ReceiveGoodsK80Col3Component } from './receive-goods/receive-goods-k80-col3/receive-goods-k80-col3.component';
import { ReceiveGoodsK57Col3Component } from './receive-goods/receive-goods-k57-col3/receive-goods-k57-col3.component';
import { ReceiveMoneyA4Component } from './receive-money/receive-money-a4/receive-money-a4.component';
import { ReceiveMoneyA5Component } from './receive-money/receive-money-a5/receive-money-a5.component';
import { ReceiveMoneyK80Col4Component } from './receive-money/receive-money-k80-col4/receive-money-k80-col4.component';
import { ReceiveMoneyK80Col3Component } from './receive-money/receive-money-k80-col3/receive-money-k80-col3.component';
import { ReceiveMoneyK57Col3Component } from './receive-money/receive-money-k57-col3/receive-money-k57-col3.component';
import { DestroyProductA4Component } from './destroy-product/destroy-product-a4/destroy-product-a4.component';
import { DestroyProductA5Component } from './destroy-product/destroy-product-a5/destroy-product-a5.component';
import { DestroyProductK57Col3Component } from './destroy-product/destroy-product-k57-col3/destroy-product-k57-col3.component';
import { DestroyProductK80Col3Component } from './destroy-product/destroy-product-k80-col3/destroy-product-k80-col3.component';
import { DestroyProductK80Col4Component } from './destroy-product/destroy-product-k80-col4/destroy-product-k80-col4.component';
 


@NgModule({
  declarations: [
    InventoryA45Component,
    DeliveryA45Component,
    PrintTestA4Component,
    PrintTestK57Component,
    PrintTestK80Component,
    TestExpendA4Component,
    TestExpendK57Component,
    TestExpendK80Component,
    SalesVoucherA4Component,
    TestTranferA4Component,
    TestTranferK80Component,
    TestVoucherA4Component,
    TestVoucherK57Component,
    TestVoucherK80Component,
    TestCardA4Component,
    TestCardK57Component,
    TestCardK80Component,
    CustomerReturnedA4Component,
    CustomerReturnedA5Component,
    CustomerReturnedK80Col4Component,
    CustomerReturnedK80Col3Component,
    CustomerReturnedK57Col3Component,
    SalesA4Component,
    SalesA5Component,
    SalesK80Col4Component,
    SalesK80Col3Component,
    SalesK57Col3Component,
    SalesPaymentCardA4Component,
    SalesPaymentCardA5Component,
    SalesPaymentCardK57Col3Component,
    SalesPaymentCardK80Col3Component,
    SalesPaymentCardK80Col4Component,

    WarrantyA4Component,
    WarrantyA5Component,
    WarrantyK80Col4Component,
    WarrantyK80Col3Component,
    WarrantyK57Col3Component,
    PurchaseA4Component,
    PurchaseA5Component,
    PurchaseK80Col4Component,
    PurchaseK80Col3Component,
    PurchaseK57Col3Component,
    ReturnSupplierA4Component,
    ReturnSupplierA5Component,
    ReturnSupplierK80Col4Component,
    ReturnSupplierK80Col3Component,
    ReturnSupplierK57Col3Component,
    ExchangePaymentCardA4Component,
    ExchangePaymentCardA5Component,
    ExchangePaymentCardK57Col3Component,
    ExchangePaymentCardK80Col3Component,
    ExchangePaymentCardK80Col4Component,

    ExpenseA4Component,
    ExpenseK80Col4Component,
    ExpenseK80Col3Component,
    ExpenseK57Col3Component,
    ExpensesA5Component,
    SalesVoucherA5Component,
    SalesVoucherK57Col3Component,
    SalesVoucherK80Col3Component,
    SalesVoucherK80Col4Component,
    ExchangeVoucherA4Component,
    ExchangeVoucherA5Component,
    ExchangeVoucherK57Col3Component,
    ExchangeVoucherK80Col3Component,
    ExchangeVoucherK80Col4Component,

    ReceiptA4Component,
    ReceiptA5Component,
    ReceiptK80Col4Component,
    ReceiptK80Col3Component,
    ReceiptK57Col3Component,
    DeliveryGoodsA4Component,
    DeliveryGoodsA5Component,
    DeliveryGoodsK80Col4Component,
    DeliveryGoodsK80Col3Component,
    DeliveryGoodsK57Col3Component,
    TransferMoneyA4Component,
    TransferMoneyA5Component,
    TransferMoneyK80Col4Component,
    TransferMoneyK80Col3Component,
    TransferMoneyK57Col3Component,
    ReceiptsQuoteA4Component,
    ReceiptsQuoteA5Component,
    ReceiptsQuoteK80Col4Component,
    ReceiptsQuoteK80Col3Component,
    ReceiptsQuoteK57Col3Component,
    ReceiveGoodsA4Component,
    ReceiveGoodsA5Component,
    ReceiveGoodsK80Col4Component,
    ReceiveGoodsK80Col3Component,
    ReceiveGoodsK57Col3Component,
    ReceiveMoneyA4Component,
    ReceiveMoneyA5Component,
    ReceiveMoneyK80Col4Component,
    ReceiveMoneyK80Col3Component,
    ReceiveMoneyK57Col3Component,
    DestroyProductA4Component,
    DestroyProductA5Component,
    DestroyProductK57Col3Component,
    DestroyProductK80Col3Component,
    DestroyProductK80Col4Component,
  ],
  entryComponents: [
    InventoryA45Component,
    DeliveryA45Component,
    PrintTestA4Component,
    PrintTestK57Component,
    PrintTestK80Component,
    TestExpendA4Component,
    TestExpendK57Component,
    TestExpendK80Component,
    SalesVoucherA4Component,
    TestTranferA4Component,
    TestTranferK80Component,
    TestVoucherA4Component,
    TestVoucherK57Component,
    TestVoucherK80Component,
    TestCardA4Component,
    TestCardK57Component,
    TestCardK80Component,
    CustomerReturnedA4Component,
    CustomerReturnedA5Component,
    CustomerReturnedK80Col4Component,
    CustomerReturnedK80Col3Component,
    CustomerReturnedK57Col3Component,
    SalesA4Component,
    SalesA5Component,
    SalesK80Col4Component,
    SalesK80Col3Component,
    SalesK57Col3Component,
    SalesPaymentCardA4Component,
    SalesPaymentCardA5Component,
    SalesPaymentCardK57Col3Component,
    SalesPaymentCardK80Col3Component,
    SalesPaymentCardK80Col4Component,
    WarrantyA4Component,
    WarrantyA5Component,
    WarrantyK80Col4Component,
    WarrantyK80Col3Component,
    WarrantyK57Col3Component,
    PurchaseA4Component,
    PurchaseA5Component,
    PurchaseK80Col4Component,
    PurchaseK80Col3Component,
    PurchaseK57Col3Component,
    ReturnSupplierA4Component,
    ReturnSupplierA5Component,
    ReturnSupplierK80Col4Component,
    ReturnSupplierK80Col3Component,
    ReturnSupplierK57Col3Component,
    ExchangePaymentCardA4Component,
    ExchangePaymentCardA5Component,
    ExchangePaymentCardK57Col3Component,
    ExchangePaymentCardK80Col3Component,
    ExchangePaymentCardK80Col4Component,
    ExpenseA4Component,
    ExpenseK80Col4Component,
    ExpenseK80Col3Component,
    ExpenseK57Col3Component,
    ExpensesA5Component,
    ReceiptA4Component,
    ReceiptA5Component,
    ReceiptK80Col4Component,
    ReceiptK80Col3Component,
    ReceiptK57Col3Component,
    DeliveryGoodsA4Component,
    DeliveryGoodsA5Component,
    DeliveryGoodsK80Col4Component,
    DeliveryGoodsK80Col3Component,
    DeliveryGoodsK57Col3Component,
    SalesVoucherA5Component,
    SalesVoucherK57Col3Component,
    SalesVoucherK80Col3Component,
    SalesVoucherK80Col4Component,
    ExchangeVoucherA4Component,
    ExchangeVoucherA5Component,
    ExchangeVoucherK57Col3Component,
    ExchangeVoucherK80Col3Component,
    ExchangeVoucherK80Col4Component,
    TransferMoneyA4Component,
    TransferMoneyA5Component,
    TransferMoneyK80Col4Component,
    TransferMoneyK80Col3Component,
    TransferMoneyK57Col3Component,
    ReceiptsQuoteA4Component,
    ReceiptsQuoteA5Component,
    ReceiptsQuoteK80Col4Component,
    ReceiptsQuoteK80Col3Component,
    ReceiptsQuoteK57Col3Component,
    ReceiveGoodsA4Component,
    ReceiveGoodsA5Component,
    ReceiveGoodsK80Col4Component,
    ReceiveGoodsK80Col3Component,
    ReceiveGoodsK57Col3Component,
    ReceiveMoneyA4Component,
    ReceiveMoneyA5Component,
    ReceiveMoneyK80Col4Component,
    ReceiveMoneyK80Col3Component,
    ReceiveMoneyK57Col3Component,
    DestroyProductA4Component,
    DestroyProductA5Component,
    DestroyProductK57Col3Component,
    DestroyProductK80Col3Component,
    DestroyProductK80Col4Component,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    NgZorroAntModule,
    FormsModule
  ]
})
export class PrintModule { }
