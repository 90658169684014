<ion-content scrollX="true">
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div class="A4" id="sales-voucher-a4">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="!printer.logo_hidden" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div class="header-store-info-contact">
                        <div *ngIf="!printer.address_hidden" class="header-store-info-contact-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.phone_number_hidden" class="header-store-info-contact-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div class="header-store-info-invoice" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{'Hóa đơn bán voucher' | translate | uppercase}}</div>
                </div>
            </div>
            <div class="header-invoice">
                <div class="header-invoice-line">
                    <div *ngIf="!printer.bill_code_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div class="header-invoice-line" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["employee"] ?
                            invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden">
                            {{ "Address" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                        invoice.retail_address
                        }}</b>
                        </ng-container>


                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                        }}{{invoice.retail_phone}}</b>
                        </ng-container>

                    </div>
                    <div class="header-invoice-line-right" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <table>
                <thead>
                    <th style="max-width: 5%; width: 5%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "No." | translate }}</th>
                    <th style="max-width: 35%; width: 35%; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Tên voucher" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Mã voucher" | translate }}
                    </th>
                    <th style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Unit price" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Amount"| translate }}
                    </th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr>
                            <td style="max-width: 5%; width: 5%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ i + 1 }}
                            </td>
                            <td style="max-width: 35%; width: 35%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                <b>{{ item.name }}</b>

                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ item.code }}
                            </td>
                            <td style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price) }}
                            </td>
                            <td style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price ) }}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4">
                                <div *ngFor="let product of item.products" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                                </div>
                            </td>
                        </tr>
                    </ng-container>


                    <tr>
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "SubTotal" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                                vhAlgorithm.vhcurrencyunit(subTotal) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Fee" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.fee) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Tax" | translate }} ({{ vhAlgorithm.vhpercent(invoice.tax / 100) }})
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "Total" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                                vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100)) }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "PAYMENT" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                                vhAlgorithm.vhcurrencyunit(invoice.payment) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.rest_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "REST" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.cash) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash - invoice.payment : 0)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="content-pay">
                <div class="content-pay-method" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{ "Payment method" | translate }}: {{ invoiceInfo.paymentName }}
                </div>
                <div class="content-pay-bank" *ngIf="invoice.payment_type == 3">
                    <div class="content-pay-bank-name" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account name" | translate }}: {{ invoiceInfo["wallet"].name }}
                    </div>
                    <div class="content-pay-bank-number" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account number" | translate }}: {{ invoiceInfo["wallet"].number }}
                    </div>
                </div>
            </div>
            <div class="content-note" *ngIf="!printer.note_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                {{ "Note" | translate }}: {{ invoice.note }}
            </div>
            <div class="content-note" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="signature">
            <ng-container>
                <div *ngIf="!printer.signature_buyer_hidden" class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Buyer" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> ({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div *ngIf="!printer.signature_shipper_hidden" class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Deliver" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div *ngIf="!printer.signature_creator_hidden" class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Prepared by" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div *ngIf="!printer.signature_stocker_hidden" class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Stocker" | translate }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div *ngIf="!printer.signature_stocker_hidden" class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Chief accountant" | translate
                        }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                        translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
    <div id="electron-sales-voucher-a4" style="display: none;">
        <div  style="padding:4px 0;" >
            <div  style="display: grid; grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img *ngIf="!printer.logo_hidden"  style="width: 100%;" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div style=" display: grid;
                padding: 2px 16px;
                grid-template-columns: 70% 30%;">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div  style="display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div *ngIf="!printer.address_hidden" class="header-store-info-contact-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.phone_number_hidden" class="header-store-info-contact-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div style="text-align: center;padding: 2px 0; font-weight: bold;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{'Hóa đơn bán voucher' | translate | uppercase}}</div>
                </div>
            </div>
            <div  >
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div *ngIf="!printer.bill_code_hidden" style=" text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                        invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" style=" text-align: end; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;" *ngIf="invoiceInfo">
                    <div *ngIf="!printer.customer_name_hidden" style=" text-align: start; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                        invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                        {{invoice.retail_name}}</b>
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" style=" text-align: end; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Salesman" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["employee"] ?
                        invoiceInfo["employee"].name : '' }}</b>
                    </div>
                </div>
                <div  style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div  style=" text-align: start; "  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.customer_address_hidden">
                            {{ "Address" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                    invoice.retail_address
                    }}</b>
                        </ng-container>


                        <ng-container *ngIf="!printer.customer_phone_hidden">
                            <br /> {{ "Phone number" | translate }} :
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                    }}{{invoice.retail_phone}}</b>
                        </ng-container>

                    </div>
                    <div style=" text-align: end; " *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-sales"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div  >
            <table  style="width: 100%;border-collapse: collapse;">
                <thead>
                    <th style="max-width: 5%; width: 5%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "No." | translate }}</th>
                    <th style="max-width: 35%; width: 35%; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Tên voucher" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Mã voucher" | translate }}
                    </th>
                    <th style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Unit price" | translate }}
                    </th>
                    <th style="max-width: 15%; width: 15; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{ "Amount"| translate }}
                    </th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of invoice_detail; let i = index">
                        <tr>
                            <td style="max-width: 5%; width: 5%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ i + 1 }}
                            </td>
                            <td style="max-width: 35%; width: 35%" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                <b>{{ item.name }}</b>

                            </td>
                            <td style="max-width: 15%; width: 15%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ item.code }}
                            </td>
                            <td style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price) }}
                            </td>
                            <td style="max-width: 25%; width: 25%; text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{ vhAlgorithm.vhcurrencyunit(item.price ) }}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4">
                                <div *ngFor="let product of item.products" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                                </div>
                            </td>
                        </tr>
                    </ng-container>

                    <tr>
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "SubTotal" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                              vhAlgorithm.vhcurrencyunit(subTotal) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_hidden">
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Giảm giá" | translate }} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.discount) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Fee" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.fee) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Tax" | translate }} ({{ vhAlgorithm.vhpercent(invoice.tax / 100) }})
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit( invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "Total" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                              vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100)) }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{ "PAYMENT" | translate }}</b>
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            <b>{{
                              vhAlgorithm.vhcurrencyunit(invoice.payment) }}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.rest_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "REST" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "cash" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ vhAlgorithm.vhcurrencyunit(invoice.cash) }}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.cash_hidden">
                        <td colspan="3" style="text-align: center;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{ "Change" | translate }}
                        </td>
                        <td colspan="2" style="text-align: end;border: 1px black solid;padding: 2px;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash - invoice.payment : 0)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div  style="padding:4px 0;" >
                <div class="content-pay-method" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{ "Payment method" | translate }}: {{ invoiceInfo.paymentName }}
                </div>
                <div class="content-pay-bank" *ngIf="invoice.payment_type == 3">
                    <div class="content-pay-bank-name" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account name" | translate }}: {{ invoiceInfo["wallet"].name }}
                    </div>
                    <div class="content-pay-bank-number" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Account number" | translate }}: {{ invoiceInfo["wallet"].number }}
                    </div>
                </div>
            </div>
            <div class="content-note" *ngIf="!printer.note_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                {{ "Note" | translate }}: {{ invoice.note }}
            </div>
            <div class="content-note" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div style=" padding: 16px;
        display: flex;
        justify-content: space-between;">
            <ng-container>
                <div style=" text-align: center;" *ngIf="!printer.signature_buyer_hidden">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{ "Buyer" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  > ({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_shipper_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Deliver" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_creator_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Prepared by" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="vhAuth.localStorageGET('show_signature_stocker')">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Stocker" | translate }}</b><br /><em
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" | translate
                        }})</em>
                </div>
                <div style=" text-align: center;" *ngIf="!printer.signature_accountancy_hidden">
                    <b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Chief accountant" | translate
                        }}</b><br /><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >({{ "Sign, write full name" |
                        translate}})</em>
                </div>
            </ng-container>

        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button color="vh-green" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">
            {{ "Print" | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
