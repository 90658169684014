<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="receipts-k80a" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div *ngIf="!printer.name_store_hidden" class="header-store-name"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}
                </div>
                <div *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header">
                <div class="content-header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Warranty, repair receipts' | translate) | uppercase}}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.getBillCode()}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{invoice.getDate() | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receptionist" | translate}}</b>: {{invoiceInfo['receptionist'] ?
                        invoiceInfo['receptionist'].name : ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Technician" | translate }}</b>: {{ invoiceInfo.technician.name | translate }}
                    </div>
                </div>
            </div>

            <div class="table" *ngIf="invoice.getInvoiceDetail().length">
                <table>
                    <thead>
                        <th style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Quantity'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice.getInvoiceDetail()">
                            <tr>
                                <td style="width: 35%; max-width: 45% ; white-space: normal" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                                <td style="width: 5%; max-width: 5%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                                <td style="width: 25%; max-width: 25%; text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span style="text-align: right ;text-decoration: line-through;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 35%; max-width: 35%;text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td colSpan="4"
                                    style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                      <ng-container *ngIf="item.combos">
                                        <span  *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="sub-table" *ngIf="invoice.getInvoiceDetail().length">
                <table>
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getSubTotal())}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Discount' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getDiscount())}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getFee())}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.getTax()/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTaxValue())}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Total'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTotalATax())}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getPayment())}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.debt_hidden">
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTotalATax() - invoice.getPayment())}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name :
                    '') | translate}}
                    {{invoice.getRetailName()
                    ?'-':''}}
                    {{invoice.getRetailName()}}</div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Date schedule" | translate }}</b>: {{ invoice.getDateSchedule() | date: "dd/MM/yyyy HH:mm" }}
                </div>
                <div>
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngFor="let item of invoice.getWarrantyProd(); let i = index">
                        + {{item.content}}
                    </div>
                </div>
                <div>
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngFor="let item of invoice.getWarrantyNote(); let i = index">
                        {{item.content}}
                    </div>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div class="payment-info" *ngIf="invoice.getPaymentType() == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name:
                        ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number:
                        ''}}
                    </div>
                </div>
            </div>
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.getNote()}}
                </div>
            </div>
        <div class="footer">
            <div *ngIf="!printer.footer_hidden" class="footer-footer" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" class="footer-barcode">
                <svg id="barcode-receipts-k80"></svg>
            </div>
        </div>
    </div>
    <div id="electron-receipts-k80a" class="K80" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style="  border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div  *ngIf="!printer.header_hidden" style="text-align: center; padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div  >
                <div  *ngIf="!printer.name_store_hidden" style="text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div   *ngIf="!printer.name_branch_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div  *ngIf="!printer.address_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div  *ngIf="!printer.phone_number_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div style="  padding: 4px 0;">
            <div style="border-bottom: 1px black dashed;">
                <div style="  text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Warranty, repair receipts' | translate) | uppercase}}</b></div>
                <div style=" text-align: start;
                ">
                    <div  *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.getBillCode()}}</div>
                    <div  *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{invoice.getDate() | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receptionist" | translate}}</b>: {{invoiceInfo['receptionist'] ?
                        invoiceInfo['receptionist'].name : ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Technician" | translate }}</b>: {{ invoiceInfo.technician.name | translate }}
                    </div>
                </div>
            </div>

            <div style="border-bottom: 1px black dashed;
            padding: 4px 0;" *ngIf="invoice.getInvoiceDetail().length">
                <table style="width: 100%;">
                    <thead>
                        <th style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Product name'|translate}}</th>
                        <th style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Quantity'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Unit price'|translate}}</th>
                        <th style="text-align: right;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{'Amount'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice.getInvoiceDetail()">
                            <tr>
                                <td style="width: 35%; max-width: 45% ; white-space: normal" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.name}}</td>
                                <td style="width: 5%; max-width: 5%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                                <td style="width: 25%; max-width: 25%; text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price)}}
                                    <br>
                                    <span style="text-align: right ;text-decoration: line-through;"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}"
                                        *ngIf="item.price_origin > item.price && item.price_origin && display_promotion_selling_price">
                                        {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                                    </span>
                                </td>
                                <td style="width: 35%; max-width: 35%;text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                    {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                                </td>
                            </tr>
                            <tr *ngIf="item.ptype == 5">
                                <td colSpan="4" 
                                    style="width: 100%; max-width: 100% ; white-space: normal;  padding-top: 0">
                                      <ng-container *ngIf="item.combos">
                                        <span  *ngFor="let data of item.combos"
                                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                            {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div style=" border-bottom: 1px black dashed;
            padding: 4px 0;" *ngIf="invoice.getInvoiceDetail().length">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Total' | translate}}</td>
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getSubTotal())}}</td>
                        </tr>
                        <tr  *ngIf="!printer.discount_hidden">
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Discount' | translate}}</td>
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getDiscount())}}</td>
                        </tr>
                        <tr  *ngIf="!printer.fee_hidden">
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Fee'|translate}}</td>
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.getFee())}}</td>
                        </tr>
                        <tr  *ngIf="!printer.tax_hidden">
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.getTax()/100)}})
                            </td>
                            <td style="text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTaxValue())}}
                            </td>
                        </tr>
                        <tr>
                            <td  style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Total'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTotalATax())}}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td  style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getPayment())}}
                            </td>
                        </tr>
                        <tr  *ngIf="!printer.debt_hidden">
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.getTotalATax() - invoice.getPayment())}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="
            text-align: start;
            padding: 4px 0;
            border-bottom: 1px black dashed;">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '')
                    | translate}}
                    {{invoice.getRetailName() ?'-':''}}
                    {{invoice.getRetailName()}}
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ "Date schedule" | translate }}</b>: {{ invoice.getDateSchedule() | date: "dd/MM/yyyy HH:mm" }}
                </div>
                <div>
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngFor="let item of invoice.getWarrantyProd(); let i = index">
                        + {{item.content}}
                    </div>
                </div>
                <div>
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}}:</b>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" *ngFor="let item of invoice.getWarrantyNote(); let i = index">
                        {{item.content}}
                    </div>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div   *ngIf="invoice.getPaymentType() == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name:
                        ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number:
                        ''}}
                    </div>
                </div>
            </div>
        </div>
        <div style="
        text-align: start;
        padding: 4px 0;
        border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>:
                    {{invoice.getNote()}}
                </div>
            </div>
        <div style="  padding: 4px 0 8px 0;">
            <div style=" 
            text-align: center;
            padding: 4px 0;"  *ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div style="  text-align: center;"  *ngIf="!printer.barcode_hidden">
                <svg id="barcode-receipts-k80"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>