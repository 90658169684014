<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div class="A4" id="warranty-a45a">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="  !printer.logo_hidden"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div  *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div class="header-store-info-contact">
                        <div  *ngIf="!printer.address_hidden" class="header-store-info-contact-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div  *ngIf="!printer.phone_number_hidden" class="header-store-info-contact-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div class="header-store-info-invoice" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{'Warranty, repair invoice' | translate | uppercase}}</div>
                </div>
            </div>
            <div class="header-invoice">
                <div class="header-invoice-line">
                    <div  *ngIf="!printer.bill_code_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div *ngIf="!printer.customer_name_hidden" class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>
                    </div>
                    <div class="header-invoice-line-right" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Receptionist" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo.receptionist.name }}</b>
                    </div>
                </div>
                <div class="header-invoice-line">
                    <div class="header-invoice-line-left" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <ng-container *ngIf="!printer.address_hidden">
                            {{ "Address" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                            invoice.retail_address
                            }}</b>
                        </ng-container>
                        
                        <br /> 
                        <ng-container *ngIf="!printer.phone_number_hidden">
                            {{ "Phone number" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                            }}{{invoice.retail_phone}}</b>
                        </ng-container>
                        
                    </div>
                    <div class="header-invoice-line-right" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-warranty-a4"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <table *ngIf="invoice_detail.length">
                <thead>
                    <th style="max-width: 5%; width: 5%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{"No."|translate}}</th>
                    <th style="max-width: 48%; width: 48%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Product name" | translate}}</th>
                    <th style="max-width: 10%; width: 10%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Unit" |
                        translate}}</th>
                    <th style="max-width: 7%; width: 7%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Quantity" |
                        translate}}</th>
                    <th style="max-width: 15%; width: 15%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Unit price" | translate}}</th>
                    <th style="max-width: 15%; width: 15%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Amount" |
                        translate}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoice_detail; let i = index">
                        <td style="max-width: 5%; width: 5%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{i + 1}}</td>
                        <td style="max-width: 48%; width: 48%">
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ item.name }}</div>
                            <ng-container *ngIf="item.combos">
                                <span  *ngFor="let data of item.combos"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                            </ng-container>
                        </td>
                        <td style="max-width: 10%; width: 10%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.unit}}</td>
                        <td style="max-width: 7%; width: 7%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                        <td style="max-width: 15%; width: 15%; text-align: end;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(item.price)}}
                            <br>
                            <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" class="price-origin"
                                *ngIf="item.price < item.price_origin && display_promotion_selling_price">
                                {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                            </span>
                        </td>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                            style="max-width: 15%; width: 15%; text-align: end;">
                            {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"SubTotal" | translate}}</b></td>
                        <td colspan="3" style="text-align: end">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(subTotal)}}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Discount" | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.discount)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Fee" |translate}}</td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td colspan="3" style="text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Tax" | translate}} ({{ vhAlgorithm.vhpercent(invoice.tax / 100)}})
                        </td>
                        <td colspan="3" style="text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr class="pay">
                        <td colspan="3" style="text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total" | translate}}</b></td>
                        <td colspan="3" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}</b>
                        </td>
                    </tr>
                    <tr class="pay">
                        <td colspan="3" style="text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"PAYMENT" | translate}}</b></td>
                        <td colspan="3" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}</b>
                        </td>
                    </tr>
                    <tr class="pay" *ngIf="!printer.debt_hidden">
                        <td colspan="3" style="text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"REST" | translate}}</b></td>
                        <td colspan="3" style="text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ vhAlgorithm.vhcurrencyunit(
                                invoice.total + invoice.total*( invoice.tax/100) - invoice.payment
                                )}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content-note">
            <div class="content-note-title">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
            </div>
            <div class="content-note-content" *ngFor="let item of warrantyProd; let i = index"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                + {{item.content}}
            </div>
        </div>
        <div class="content-note" *ngIf="!printer.note_hidden">
            <div class="content-note-title">
                <b>{{"Note" | translate}}:</b>
            </div>
            <div class="content-note-content" *ngFor="let item of warrantyNote; let i = index">
                {{item.content}}
            </div>
        </div>
        <div class="signature">
            <ng-container>
                <div class="signature-detail" *ngIf="!printer.signature_store_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Store" |
                        translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                        translate
                        }})</em></div>
                <div class="signature-detail" *ngIf="!printer.signature_buyer_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer' |
                        translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                        translate
                        }})</em></div>
            </ng-container>
        </div>
    </div>
    <div id="electron-warranty-a45a" style="display: none;">
        <div  >
            <div style="display: grid;
            grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img *ngIf="  !printer.logo_hidden" style="width: 100%;"
                        src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div >
                    <div style="display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div *ngIf="!printer.name_store_hidden" ><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div  *ngIf="!printer.name_branch_hidden"   [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoBranch['name']}}</div>
                    </div>
                    <div style="display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div  *ngIf="!printer.address_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{infoStore['address']}}
                        </div>
                        <div  *ngIf="!printer.phone_number_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                        <div *ngIf="!printer.tax_code_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            MST: {{infoStore['tax_number']}}
                        </div>
                        <div *ngIf="!printer.email_hidden"  
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Email" | translate}} : {{infoStore['email']}}
                        </div>
                    </div>
                    <div style="
                    text-align: center;
                    padding: 2px 0;
                    font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">{{'Warranty, repair invoice' | translate | uppercase}}</div>
                </div>
            </div>
            <div  >
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div  *ngIf="!printer.bill_code_hidden" style=" text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Invoice code" | translate }} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{
                            invoice.bill_code }}</b>
                    </div>
                    <div  *ngIf="!printer.date_hidden" style=" text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Date" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}</b>
                    </div>
                </div>
                <div  style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div  *ngIf="!printer.customer_name_hidden" style=" text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Customer name" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ (invoiceInfo["partner"] ?
                            invoiceInfo["partner"].name : '') | translate }} {{invoice.retail_name ?'-':''}}
                            {{invoice.retail_name}}</b>
                    </div>
                    <div  style=" text-align: end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Receptionist" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo.receptionist.name }}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div  style=" text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{ "Address" | translate }} :
                        <ng-container  *ngIf="!printer.customer_address_hidden">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                                invoice.retail_address
                                }}</b>
                        </ng-container>

                        <br /> 
                        <ng-container  *ngIf="!printer.customer_phone_hidden">
                            {{ "Phone number" | translate }} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                            }}{{invoice.retail_phone}}</b>
                        </ng-container>
                        
                    </div>
                    <div  style=" text-align: end;" *ngIf="!printer.barcode_hidden">
                        <svg id="barcode-warranty-a4"></svg>
                    </div>
                </div>
            </div>
        </div>
        <div >
            <table *ngIf="invoice_detail.length" style="width: 100%;border-collapse: collapse;">
                <thead>
                    <th style="
                    border: 1px black solid;max-width: 5%; width: 5%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                        {{"No."|translate}}</th>
                    <th style="
                    border: 1px black solid;max-width: 48%; width: 48%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Product name" | translate}}</th>
                    <th style="
                    border: 1px black solid;max-width: 10%; width: 10%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Unit" |
                        translate}}</th>
                    <th style="
                    border: 1px black solid;max-width: 7%; width: 7%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Quantity" |
                        translate}}</th>
                    <th style="
                    border: 1px black solid;max-width: 15%; width: 15%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Unit price" | translate}}</th>
                    <th style="
                    border: 1px black solid;max-width: 15%; width: 15%;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Amount" |
                        translate}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoice_detail; let i = index">
                        <td style=" padding: 2px;
                        border: 1px black solid;max-width: 5%; width: 5%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{i + 1}}</td>
                        <td style=" padding: 2px;
                        border: 1px black solid;max-width: 48%; width: 48%">
                            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ item.name }}</div>
                            <ng-container *ngIf="item.combos">
                                <span  *ngFor="let data of item.combos"
                                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">
                                    {{data.quantity}}x {{data.name}} ({{getUnit(data.units,data.ratio)}}), </span>
                            </ng-container>
                        </td>
                        <td style=" padding: 2px;
                        border: 1px black solid;max-width: 10%; width: 10%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.unit}}</td>
                        <td style=" padding: 2px;
                        border: 1px black solid;max-width: 7%; width: 7%; text-align: center;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{item.quantity}}</td>
                        <td style=" padding: 2px;
                        border: 1px black solid;max-width: 15%; width: 15%; text-align: end;"
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(item.price)}}
                            <br>
                            <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" style=" text-decoration: line-through;
                            "
                                *ngIf="item.price < item.price_origin && display_promotion_selling_price">
                                {{vhAlgorithm.vhcurrencyunit(item.price_origin)}}
                            </span>
                        </td>
                        <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                            style=" padding: 2px;
                            border: 1px black solid;max-width: 15%; width: 15%; text-align: end;">
                            {{vhAlgorithm.vhcurrencyunit(item.price * item.quantity)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"SubTotal" | translate}}</b></td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end">
                            <b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(subTotal)}}</b>
                        </td>
                    </tr>
                    <tr *ngIf="!printer.discount_hidden">
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Discount" | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.discount)}}
                        </td>
                    </tr>
                    <tr *ngIf="!printer.fee_hidden">
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Fee" |translate}}</td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                    </tr>
                    <tr *ngIf="!printer.tax_hidden">
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Tax" | translate}} ({{ vhAlgorithm.vhpercent(invoice.tax / 100)}})
                        </td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{vhAlgorithm.vhcurrencyunit(invoice.total*( invoice.tax/100)) }}
                        </td>
                    </tr>
                    <tr >
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total" | translate}}</b></td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}</b>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"PAYMENT" | translate}}</b></td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}</b>
                        </td>
                    </tr>
                    <tr  *ngIf="!printer.debt_hidden">
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: center"><b
                                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"REST" | translate}}</b></td>
                        <td colspan="3" style=" padding:2px;
                        border: 1px black solid;text-align: end">
                            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> {{ vhAlgorithm.vhcurrencyunit(
                                invoice.total + invoice.total*( invoice.tax/100) - invoice.payment
                                )}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div  >
            <div >
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Warranty, repair products" | translate}}:</b>
            </div>
            <div   *ngFor="let item of warrantyProd; let i = index"
                [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                + {{item.content}}
            </div>
        </div>
        <div   *ngIf="!printer.note_hidden">
            <div >
                <b>{{"Note" | translate}}:</b>
            </div>
            <div  *ngFor="let item of warrantyNote; let i = index">
                {{item.content}}
            </div>
        </div>
        <div style=" padding: 16px;
        display: flex;
        justify-content: space-between;">
            <ng-container>
                <div style=" text-align: center;" *ngIf="!printer.signature_store_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Store" |
                        translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                        translate
                        }})</em></div>
                <div style=" text-align: center;" *ngIf="!printer.signature_buyer_hidden"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer' |
                        translate}}</b><br><em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{ "Sign, write full name" |
                        translate
                        }})</em></div>
            </ng-container>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}</ion-button>
    </ion-toolbar>
</ion-footer>