import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-search-goods-inventory',
  templateUrl: './search-goods-inventory.component.html',
  styleUrls: ['./search-goods-inventory.component.scss']
})
export class SearchGoodsInventoryComponent implements OnInit {

  @ViewChild("inputSearchProductInventory") myInputField: ElementRef;
  @Input("visible_products") visible_products: boolean = false;
  @Output("addNewProduct") addNewProduct = new EventEmitter<any>();
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private vhQuerySales: VhQuerySales,
    private router:Router,
    private vhComponent:VhComponent,
    private languageService:LanguageService
  ) { }
  public goodList: any[] = [];
  public searchList: any[] = [];
  search: string = '';
  ngOnInit(): void {
    this.getData();
  }
  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }
  acticeFocus() {
    this.myInputField.nativeElement.focus();
  }
  goBack() {
    this.addNewProduct.emit(false);
  }
  /**
   * lọc sản phẩm theo tên hoặc barcode
   * @param value 
   * @example this.searchProduct('')
   */
  searchProduct(value: string) {
    let tempVal: string = this.vhAlgorithm.changeAlias(value.toLowerCase());
    if (value.length)
      this.searchList = this.vhAlgorithm.searchList(tempVal, this.goodList, ['name', 'barcode'])
    else this.searchList = this.goodList
  }
  /**
   * bắt sk click vào sp và ouput về component cha
   * @param item 
   * @example this.chooseProduct(product_selected)
   */
  chooseProduct(item) {
    this.addNewProduct.emit(item)
  }
  /**
   * get dữ liệu sp để show ra giao diện
   * @param event 
   * @example this.getData()
   */
  getData() {
    this.goodList = this.vhQuerySales.getlocalDetailProducts(1).map(e=> {return {...e,...this.vhQuerySales.getUnit_byRatio(e.units, 1), }});
    this.searchList = this.goodList;
    this.searchProduct('');
  }
  ngAfterViewChecked() {
    if (document.querySelector(".ant-drawer-body") && document.querySelector(".search-product-input") && document.getElementById("search-product-item")) {
      document.getElementById("search-product-item").style.height = document.querySelector(".ant-drawer-body").clientHeight
        - document.querySelector(".search-product-input").clientHeight + "px";
    }
  }
 

  barcode: string = ''
  barcode_array = [];
  @HostListener("window: keypress", ["$event"])
  /**
   * Sự kiện nhận barcode
   */
  getKeyUp(_event: KeyboardEvent) {
    if (this.router.url === "/dashboard/manage/stock/inventory/detail/cart" ) {
      if (_event.key == "Enter") {
        this.barcode_array.forEach((i, index) => {
          if (i == "Shift") {
            this.barcode_array[index + 1] = this.barcode_array[
              index + 1
            ].toUpperCase();
            this.barcode_array.splice(index, 1);
          }
        });
        this.barcode = this.barcode_array.join("");
        setTimeout(() => {
          if (this.barcode) {
            let detailproduct = this.vhQuerySales.getlocalDetailProduct_byBarcode(this.barcode);
            if (detailproduct) {
              this.chooseProduct({ ...detailproduct, ...this.vhQuerySales.getUnit_byRatio(detailproduct.units, detailproduct.ratio) });
            }
            else this.vhComponent.alertMessageDesktop("error", this.languageService.translate("Not found"))
          }
          this.barcode_array = [];
           
        }, 100);

      } else {
        this.barcode_array.push(_event.key);
      }
      // }
    }
  }

}
