<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="exchange-voucher-k80-col4" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" class="header-header" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div *ngIf="!printer.name_store_hidden" class="header-store-name"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-header" style="padding: 4px 0;">
                <div class="content-header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Biên nhận đổi voucher lấy hàng hóa'
                | translate) | uppercase}}</b></div>
                <div class="content-header-info" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}</div>

                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{"Date" | translate}}</b>: {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman" | translate}}</b>: {{ invoiceInfo["employee"] ? invoiceInfo["employee"].name : '' }}
                    </div>
                </div>
            </div>
            <div class="table">
                <table>
                    <thead>
                        <th style="width: 35%; max-width: 35%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Tên voucher'|translate}}</th>
                        <th style="width: 15%; max-width: 15%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Mã voucher'|translate}}</th>
                        <th style="width: 30%; max-width: 30%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Đợt phát hành'|translate}}</th>
                        <th style="width: 20%; max-width: 20%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Ngày phát hành'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 35%; max-width: 35%; text-align: left;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    <b>{{item['name_voucher']}} </b>
                                </td>
                                <td style="width: 15%; max-width: 15%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{ item.code }}
                                </td>
                                <td style="width: 30%; max-width: 30%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item['name_voucher_release']}}
                                </td>
                                <td style="width: 20%; max-width: 20%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item['date_validity'] |date: 'dd/MM/yyyy HH:mm'}}
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 100% ; word-break: break-all;" colspan="4" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                      {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                              </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="payment">
            <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
            <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}}
            </div>
        </div>
        <div class="footer">
            <div class="footer-footer" *ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div class="footer-barcode" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
    </div>
    <div id="electron-exchange-voucher-k80-col4" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style=" border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div style="text-align: center;
            
            padding: 4px 0;"  *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div >
                <div style="  text-align: center;
                "  *ngIf="!printer.name_store_hidden"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b></div>
                <div  style="text-align: center; 
                "  *ngIf="!printer.name_branch_hidden"
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div style="text-align: center; 
                "  *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div  *ngIf="!printer.phone_number_hidden" style="text-align: center; 
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" |
                    translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-header" style="padding: 4px 0;">
                <div style="text-align: center;"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Biên nhận đổi voucher lấy hàng hóa'
                  | translate) | uppercase}}</b></div>
                <div style="text-align: start;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}</div>

                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{"Date" | translate}}</b>: {{ invoice.date | date: "dd/MM/yyyy HH:mm" }}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman" | translate}}</b>: {{ invoiceInfo["employee"] ? invoiceInfo["employee"].name : '' }}
                    </div>
                </div>
            </div>

            <div  style="border-bottom: 1px black dashed;
            padding: 4px 0;">
                <table style="width: 100%;">
                    <thead>
                        <th style="width: 35%; max-width: 35%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Tên voucher'|translate}}</th>
                        <th style="width: 15%; max-width: 15%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Mã voucher'|translate}}</th>
                        <th style="width: 30%; max-width: 30%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Đợt phát hành'|translate}}</th>
                        <th style="width: 20%; max-width: 20%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                            {{'Ngày phát hành'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td style="width: 35%; max-width: 35%; text-align: left;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    <b>{{item['name_voucher']}} </b>
                                </td>
                                <td style="width: 15%; max-width: 15%; text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{ item.code }}
                                </td>
                                <td style="width: 30%; max-width: 30%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item['name_voucher_release']}}
                                </td>
                                <td style="width: 20%; max-width: 20%;text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    {{item['date_validity'] |date: 'dd/MM/yyyy HH:mm'}}
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 100% ; word-break: break-all;" colspan="4" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.6}">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                  {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                          </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}}
                </div>
            </div>
        </div>
        <div  style="padding: 4px 0 8px 0;">
            <div style="
            text-align: center;
            padding: 4px 0;"*ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div   style="text-align: center;" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
