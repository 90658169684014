import { LanguageService } from '../../../services/language.service';
import { VhComponent } from '../../vh-component/vh-component';
import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, SimpleChanges, ViewContainerRef } from '@angular/core';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddProductComponent } from 'src/app/menu/manage/products/products-list/add-product/add-product.component';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements OnInit {
  @ViewChild("inputSearchSales") myInputField: ElementRef;
  @Input("searchProductList") searchProductList: boolean = true;
  @Output("closeSearchProductList") closeSearchProductList = new EventEmitter<any>();
  @Input() default_price_type: any;
  indexTab: any = 0;
  indexTabCateogry: any = 0;
  id_branch: string = this.vhQuerySales.getDefaultBranch()._id;
  auto_hidden_lot: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').auto_hidden_lot
  auto_select_lot: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').auto_select_lot
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private vhQuerySales: VhQuerySales,
    private vhComponent: VhComponent,
    private languageService: LanguageService,
    private nzModalService:NzModalService,
    private viewContainerRef: ViewContainerRef
  ) { }
  public goodList: any[] = [];
  public searchList: any[] = [];
  listAllProducts: any[] = [];
  listCategories : any[]= [];
   
  id_category_selected = 'all'
  public typeGoods = 0;
  search: string = '';
  ngOnInit(): void { 
    this.changeTypeGood(this.typeGoods);

  }

  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }

  acticeFocus() {
    this.myInputField.nativeElement.focus();
  }

  goBack() {
    this.closeSearchProductList.emit(false);
  }

  tempVal: string = ''
  /**
   * lọc sản phẩm theo tên hoặc barcode
   * @param value 
   * @example this.searchProduct('')
   */
  searchProduct(value: string) {
    this.tempVal = this.vhAlgorithm.changeAlias(value.toLowerCase());
    if (value.length)
      this.searchList = this.vhAlgorithm.searchList(this.tempVal, this.goodList, ['name', 'barcode'])
    else this.searchList = this.goodList;
    // 
    
  }

  /**
   * bắt sk click vào sp và ouput về component cha
   * @param item 
   * @example this.chooseProduct(product_selected)
   */
  chooseProduct(item) {
    this.closeSearchProductList.emit({ ...item });
  }
  trackByFn(index, item) {
    return item._id;
  }
  tabs: any = ['Product', 'Service', 'Combo']
  /**
   * bắt sk thay đổi tab loại sp
   * @example this.changeTypeGood('1')
   */
  changeTypeGood(event) {
    this.indexTab = event
    let type: number = 1;
    switch (event) {
      case 0: type = 1; break;
      case 1: type = 2; break;
      default: type = 5; break;
    }
    this.listCategories = [{_id : 'all' , name : this.languageService.translate("All")}].concat(this.vhAlgorithm.sortStringbyASC( this.vhQuerySales.getlocalCategorys(type),'name'))
    this.filterByCategory(type)
  }

  /**
   * bắt sk thay đổi tab danh mục sp
   * @example this.changeCategory(1)
   */
  changeCategory(event){
    this.indexTabCateogry = event
    let type: number = 1;
    switch (this.indexTab) {
      case 0: type = 1; break;
      case 1: type = 2; break;
      default: type = 5; break;
    } 
    this.filterByCategory(type)
  }

  /**
   * lọc sp cần hiển thị theo category
   * @param type loại sp
   * @example  this.filterByCategory(1)
   */
  filterByCategory(type){
    this.id_category_selected = this.listCategories[this.indexTabCateogry]?._id;
    let products = this.id_category_selected == 'all' ?  this.vhQuerySales.getlocalDetailProducts(type) : this.vhQuerySales.getlocalDetailProducts(type).filter(pro => pro.id_category == this.id_category_selected)
    this.goodList = products.filter(e => e.allow_sell).map(item => {
      let unit = this.vhQuerySales.getUnit_byRatio(item.units, item.units.find(e => e.default) ? item.units.find(e => e.default).ratio : 1)
      if (item.type == 5) {
        this.handleLotsOfCombo(item.combos.filter(e => e.lots))
        item.subComboName = this.getAllName_fromCombos(item.combos);
      }
      if (item.type == 1 && item.manylot) {
        item.lots = this.vhAlgorithm.sortDatebyASC(item.lots, 'date_exp')
        if (this.auto_hidden_lot) item.lots = item.lots.filter(e => new Date(e.date_exp).getTime() >= new Date().getTime())
        if (this.auto_select_lot && item.lots.length) {
          let min_date_exp = this.vhAlgorithm.sortDatebyASC(item.lots.filter(e => new Date(e.date_exp).getTime() >= new Date().getTime()), 'date_exp')
          // 
          // 
          if (min_date_exp.length) {
            item['id_lotproduct'] = min_date_exp[0]._id;
            item['lot_number'] = min_date_exp[0].lot_number;
          }
        }
      }
      return { ...item, ...unit }
    });
    this.searchList = this.goodList;
    this.searchProduct(this.tempVal) 
  }



  /**
   * tự động chọn lô cho sp trong combo để dễ xử lý logic khi bán hàng
   * @param combos 
   * @example this.handleLotsOfCombo(combos)
   */
  handleLotsOfCombo(combos) {
    combos = this.vhAlgorithm.sortDatebyASC(combos, 'date_exp')
    for (let i of combos) {
      if (i.lots.length) {
        if (this.auto_hidden_lot) i.lots = i.lots.filter(e => new Date(e.date_exp).getTime() >= new Date().getTime())
        let min_date_exp = this.vhAlgorithm.sortDatebyASC(i.lots, 'date_exp')
        if (this.auto_select_lot) i['id_lotproduct'] = min_date_exp[0]._id
      }
    }
  }

  /**
   * trả về tên chuỗi sản phẩm trong combos
   * @param combos 
   * @returns string
   * @example let comboName = this.getAllName_fromCombos([])
   */
  getAllName_fromCombos(combos: any) {
    return combos.map(e => { return e.quantity + 'x ' + e.name   }).join(', ');
  }
  
  gotoAddProducts(){
    const modal = this.nzModalService.create({
      nzTitle: this.languageService.translate('Add product'),
      nzContent: AddProductComponent,
      nzWidth: '80vw',
      
      nzViewContainerRef: this.viewContainerRef,
      
      nzOnOk: () => {

      },
      nzFooter: [ 
      ]
    });
    modal.afterClose.subscribe(result =>  this.changeTypeGood(this.typeGoods));

  }

}
