import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}

import { IonicModule } from '@ionic/angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PrintModule } from './components/print/print.module';

import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import vi from '@angular/common/locales/vi';
registerLocaleData(en);
registerLocaleData(vi);

import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { VhAuth, VhQuery, VhQuerySales, VhEcommerce, VhImage, VhForFirstTime, VhAlgorithm, HttpLoaderFactory, VhDisplayLEDService } from 'ionic-vhframeworks';

import { FunctionService } from './services/function.service';
import { SearchModule } from './components/search/search.module';
import { NgZorroAntModule } from './ng-zorro-ant.module';

// import { AngVirtualKeyboardModule } from 'ang-virtual-keyboard'
@NgModule({
  declarations: [
    AppComponent,
  //  KeyboardDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
   // KeyboardModule,
    LottieModule.forRoot({ player: playerFactory }),
    IonicModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: (HttpLoaderFactory), deps: [HttpClient] }
    }),
    AppRoutingModule,
    HttpClientModule,
    PrintModule,
    NgZorroAntModule,
    SearchModule,
    IonicModule.forRoot(),
   // KeyboardModule
  ],
  providers: [
    Camera, WebView, ImagePicker, File, SocialSharing, BarcodeScanner, InAppPurchase2, BluetoothSerial,
    DatePipe, 
    VhAuth, VhQuery, VhQuerySales, VhEcommerce, VhImage, VhAlgorithm, VhForFirstTime, FunctionService,
	VhDisplayLEDService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
