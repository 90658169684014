<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="cancel()">
                <ion-icon slot="icon-only" name="close-outline" color="vh-black"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="vh-green">{{'Language' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid fixed class="ion-no-padding">
        <ion-row>
            <ion-col size="12" class="ion-no-padding">
                <ion-radio-group [(ngModel)]="lang" (ngModelChange)="selectLang($event)">
                    <ion-virtual-scroll [items]="langList" [approxItemHeight]="60">
                        <ion-item mode="md" lines="full" *virtualItem="let item">
                            <ion-avatar slot="start">
                                <img [src]="item.img ? item.img : 'assets/icon/management/noimage.svg'" />
                            </ion-avatar>
                            <ion-label>{{item.name | translate}}</ion-label>
                            <ion-radio mode="ios" slot="end" [value]="item.value"></ion-radio>
                        </ion-item>
                    </ion-virtual-scroll>
                </ion-radio-group>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>