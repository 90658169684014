import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VhAlgorithm, VhImage, VhQuerySales } from 'ionic-vhframeworks';
import { LanguageService } from 'src/app/services/language.service';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  management_product_manysize: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').management_product_manysize
  management_product_lot: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').management_product_lot
  stateObj: any
  showPopup: boolean = false;
  productForm: FormGroup;
  price: any;
  price2: any;
  price_import: any;
  list_category: any;
  list_barcode: any;
  barcode: boolean = true;
  subBarcode: boolean = true;
  createProd: boolean = false;
  dataUnits: any[] = [];                         // danh sách đơn vị quy đổi
  dataUnitMain = {}
  barcodes = []
  constructor(
    public translate: TranslateService,
    public languageService: LanguageService,
    private vhQuerySales: VhQuerySales,
    private vhImage: VhImage,
    public vhAlgorithm: VhAlgorithm,
    private vhComponent: VhComponent,
    private router: Router,
    private cdf: ChangeDetectorRef,
    private nzModalService: NzModalService
  ) {
    if (this.router.getCurrentNavigation())
      this.stateObj = this.router.getCurrentNavigation().extras.state.stateObj
  }
  ngOnInit() {
    this.initForm();
    this.initData();
  }


  ngAfterViewChecked(): void {
    this.cdf.detectChanges()
  }

  ngAfterViewInit() {
    this.vhAlgorithm.waitingStack().then(() => {
      this.price = this.vhAlgorithm.vhnumeral(".retail_price");
      this.price_import = this.vhAlgorithm.vhnumeral(".price_import");
      this.price2 = this.vhAlgorithm.vhnumeral(".price2");
    });

  }

  /**
   * khởi tạo form với các trường để lưu sp
   * @param data nếu có thì gán dữ liệu vào form
   * @example this.initForm()
   */
  initForm(data?) {
    this.productForm = new FormGroup({
      img: new FormControl(data ? data.img : ''),
      name: new FormControl(data ? data.name : '', [Validators.required]),
      barcode: new FormControl(''),
      unit: new FormControl('', [Validators.required]),
      price_import: new FormControl("", Validators.compose([Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)"), Validators.required])),
      price: new FormControl("", Validators.compose([Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)"), Validators.required])),
      price2: new FormControl("", Validators.compose([Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)"), Validators.required])),
      id_category: new FormControl(data ? data.id_category : '', [Validators.required]),
      brand: new FormControl(data ? data.brand : ''),
      allow_sell: new FormControl(true),
      warning_number: new FormControl('', Validators.compose([Validators.pattern('(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)'), Validators.required])),
      selling: new FormControl(true),
      type: new FormControl(1),
      manysize: new FormControl(false),
      manylot: new FormControl(false),
      position: new FormControl(''),
    });
  }

  goBack() {
    if (this.stateObj) {
      console.log(1);
      
      this.router.navigate(['/dashboard/manage/products/products-list'], {
        state: { stateObj: this.stateObj }
      });
    }
    else { 
      console.log(2);
      this.nzModalService.closeAll() }
  }



  ngOnDestroy() {
    // xóa hỉnh ảnh đã tải lên trên firestore nếu ko lưu  mà back về để tránh làm rác DB 
    if (!this.createProd && this.productForm.value['img']) {
      this.vhImage.deleteThumbnail([this.productForm.value['img']])
    }
  }

  /**
   * thêm sp vô DB
   * @example this.saveProduct()
   */
  saveProduct() {
    this.productForm.removeControl('subProducts');
    let product = this.cleanForm(this.productForm).value
    // nếu có manysize thì phải format lại các trường trong subs để lưu
    if (product['manysize']) {
      if (this.subProducts.length === 0) {
        this.vhComponent.showToast(1500, 'Vui lòng thông tin Add Size', 'alert-toast')
        return;
      }
      else {
        this.subProducts = this.subProducts.map(item => {
          let sub_product = {
            name: item.name,
            warning_number: item.warning_number,
            allow_sell: item.allow_sell,
            selling: item.selling,
            units: [
              {
                unit: item.unit,
                ratio: 1,
                price: parseFloat(item.price),
                price2: parseFloat(item.price2),
                price_import: parseFloat(item.price_import),
                barcode: item.barcode,
                default: item.units.filter(e => e.default).length ? false : true
              },
              ...item.units
            ],
            manylot: item.manylot,
          }
          if (item['manylot']) {
            sub_product['lots'] = item.lots;
            sub_product['days_import_warning'] = item.days_import_warning;
            sub_product['days_exp_warning'] = item.days_exp_warning;
          }
          return sub_product
        })
      }
    } else {
      this.subProducts = [];
      // xử lý units của sp
      product.units = new Array();
      product.price = parseFloat(this.price.getRawValue() ? this.price.getRawValue() : '0');
      product.price_import = parseFloat(this.price_import.getRawValue() ? this.price_import.getRawValue() : '0');
      product.price2 = parseFloat(this.price2.getRawValue() ? this.price2.getRawValue() : '0');
      product.units = [...this.dataUnits];
      product.units = [
        {
          unit: product.unit,
          ratio: 1,
          price: parseFloat(product.price),
          price2: parseFloat(product.price2),
          price_import: parseFloat(product.price_import),
          barcode: product.barcode,
          default: product.units.filter(e => e.default).length ? false : true
        },
        ...product.units,
      ]
      if (product.warning_number >= 0) product.warning_number = parseFloat(product.warning_number);
      else product.warning_number = 0
      if (product['manylot']) {
        product['lots'] = this.lot_list;
      }
    }
    // xóa các trường ko cần thiết
    Object.keys(product).forEach((key) => {
      if (key == 'unit' || key == 'ratio' || key == 'price' || key == 'price2' || key == 'price_import' || key == 'barcode')
        delete product[key]
    })
    if (!this.barcode) {
      this.vhComponent.alertMessageDesktop("error", this.languageService.translate("Barcode already exists"))
    }
    else
      this.vhComponent.showLoading('').then(() => {
        this.vhQuerySales.addProductAndSubsProduct(product, this.subProducts).then(() => {
          this.vhComponent.hideLoading(0)
          this.vhComponent.showToast(1000, product.name + " " + (this.languageService.translate("has been added successfully")), 'success-toast');
          setTimeout(() => {
            this.createProd = true
            this.goBack();
          }, 300);
        }, (err) => {

          this.vhComponent.hideLoading(0)
          // this.vhComponent.alertMessageDesktop("error",err)
          this.vhComponent.showToast(1500, this.languageService.translate("An error occurred while adding product. Please try again"), 'alert-toast')
        })
      })
  }

  // Hàm thực hiện xóa space (TRIM) các control trong form
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (key === 'name' || key === 'barcode' || key === 'brand' || key === 'unit') {
        if (formGroup.get(key).value) {
          let value = formGroup.get(key).value.toString().trim();
          formGroup.get(key).setValue(value)
        }
      }
    });
    return formGroup;
  }

  initData() {
    // lấy danh mục
    this.list_category = this.vhQuerySales.getlocalCategorys().filter(res => res.type == 1);
  }

  /**
   * kiểm tra barcode đã tồn tại hay chưa
   * @example this.checkBarcode('123456')
   */
  checkBarcode(barcode: string) {
    if (barcode) {
      let detailproduct = this.vhQuerySales.getlocalDetailProduct_byBarcode(barcode)
      if (detailproduct == null) {
        if (this.dataUnits.find(e => e.barcode == barcode) || this.lot_list.find(lot => lot.barcode == barcode)) this.barcode = false
        else this.barcode = true
      } else {
        this.barcode = false
      }
    } else {
      this.barcode = true
    }
  }

  /**
   * kiểm tra barcode của sub đã tồn tại hay chưa
   * @example this.checkBarcode('123456')
   */
  checkBarcodeSubProduct(barcode: string, sub_name) {
    let detailproduct = this.vhQuerySales.getlocalDetailProduct_byBarcode(barcode)
    if (detailproduct == null) {
      if (this.barcodes.filter(item => barcode && item == barcode).length)
        this.barcode = false;
      else this.barcode = true
    } else {
      this.barcode = false
    }
  }
  /**
   * mở giao diện chọn file để upload ảnh
   * @example getFile()
   */
  getFile() {
    document.getElementById("file-upload").click();
  }

  /** Lấy hình ảnh từ Desktop */
  onUpload(e?) {
    const file = e.target.files[0];
    this.vhImage.getThumbnailFromDesktop(file, 256, 256, "products", this.productForm.value['img'] || '').then(
      photoURL => {
        this.productForm.controls["img"].setValue(photoURL);
        this.vhComponent.showToast(2000, (this.languageService.translate("Image was loaded successfully")), 'success-toast');
      },
      () => {
        this.vhComponent.showToast(2000, (this.languageService.translate("Image was failed")), 'alert-toast');
      }
    );
  }

  /**
   * tạo barcode tự động
   * @example this.changeBarcode()
   */
  changeBarcode() {
    let newbarcode = '';
    for (let index = 0; index < 12; index++) {
      newbarcode += Math.floor(Math.random() * 10)
    }
    this.checkBarcode(newbarcode);
    if (this.barcode) {
      this.productForm.controls['barcode'].setValue(newbarcode)
    }
  }

  // CleaveJS
  tranformPrice(class_name) {
    switch (class_name) {
      case 'size-price-item':
        this.price_size = this.vhAlgorithm.vhnumeral(".size-price-item") ? this.vhAlgorithm.vhnumeral(".size-price-item") : 0;
        break;
      case 'size-price2-item':
        this.price2_size = this.vhAlgorithm.vhnumeral(".size-price2-item") ? this.vhAlgorithm.vhnumeral(".size-price2-item") : 0;
        break;
      case 'size-price-purchase-item':
        this.price_size_purchase = this.vhAlgorithm.vhnumeral(".size-price-purchase-item") ? this.vhAlgorithm.vhnumeral(".size-price-purchase-item") : 0;
        break;
      case 'price':
        this.price = this.vhAlgorithm.vhnumeral(".price") ? this.vhAlgorithm.vhnumeral(".price") : 0;
        break;
      case 'price2':
        this.price2 = this.vhAlgorithm.vhnumeral(".price2") ? this.vhAlgorithm.vhnumeral(".price2") : 0;
        break;
      case 'price_import':
        this.price_import = this.vhAlgorithm.vhnumeral(".price_import") ? this.vhAlgorithm.vhnumeral(".price_import") : 0;
        break;
    }
  }


  // Thêm units
  showDrawerUnits = false
  closeUnits(event) {
    if (typeof event != 'boolean') {
      if (!this.productForm.value.manysize)
        this.dataUnits = event
      else {
        this.formSubProduct.controls['units'].setValue(event);
      }
    }
    this.showDrawerUnits = false;
  }
  /**
   * mở drawer danh sách đơn vị
   * @example this.changeUnits()
   */
  changeUnits() {
    this.barcodes = [];
    // xử lý gán danh sách barcode để kiểm tra trùng trong local
    if (!this.productForm.value.manysize) {
      this.barcodes = [this.productForm.value.barcode].concat(this.dataUnits.map(item => { return item.barcode })).concat(this.lot_list.map(lot => { return lot.barcode }))
    }
    this.showDrawerUnits = true
    this.dataUnitMain = {
      unit: this.productForm.value.unit,
      ratio: 1,
      barcode: this.productForm.value.barcode,
      price: parseFloat(this.price.getRawValue() ? this.price.getRawValue() : '0'),
      price2: parseFloat(this.price2.getRawValue() ? this.price2.getRawValue() : '0'),
      price_import: parseFloat(this.price_import.getRawValue() ? this.price_import.getRawValue() : '0')
    }
  }

  /**
   * mở drawer danh sách đơn vị của subproduct
   * @example this.changeUnitsSubs()
   */
  changeUnitsSubs() {
    this.barcodes = [];
    for (let i in this.subProducts) {
      this.barcodes = this.barcodes.concat([...this.subProducts[i].units.map(e => { return e.barcode }), this.subProducts[i].barcode, ...this.subProducts[i].lots.map(lot => { return lot.barcode })])
    }
    this.barcodes.push(this.formSubProduct.value.barcode, ...this.formSubProduct.value.units.map(item => { return item.barcode }))
    this.showDrawerUnits = true;
    this.dataUnits = this.formSubProduct.value.units
    this.dataUnitMain = {
      unit: this.formSubProduct.value.unit,
      ratio: 1,
      barcode: this.formSubProduct.value.barcode,
      price: this.price_size.getRawValue() ? parseFloat(this.price_size.getRawValue()) : 0,
      price2: this.price2_size.getRawValue() ? parseFloat(this.price2_size.getRawValue()) : 0,
      price_import: this.price_size_purchase.getRawValue() ? parseFloat(this.price_size_purchase.getRawValue()) : 0
    }
  }

  //  cập nhật trường form khi manysize: true/false
  statusCheckBox(e) {
    if (!e) {
      this.subProducts = [];
      this.initForm(this.productForm.value)
      this.productForm.removeControl('subProducts');
    }
    else {
      this.productForm.addControl('subProducts', new FormControl('', [Validators.required]))
      this.productForm.removeControl('price');
      this.productForm.removeControl('price2');
      this.productForm.removeControl('price_import');
      this.productForm.removeControl('barcode');
      this.productForm.removeControl('warning_number');
      this.productForm.removeControl('unit');
      this.productForm.removeControl('selling');
      this.productForm.removeControl('allow_sell');
      this.productForm.removeControl('manylot');
    }
  }

  /**
   * bắt sk thay đổi thiết lập lô cho sp
   * @param event 
   * @example this.changeManyLot(true)
   */
  changeManyLot(event) {
    if (event) {
      this.productForm.addControl('days_import_warning', new FormControl(null))
      this.productForm.addControl('days_exp_warning', new FormControl(null))
    }
    else {
      this.productForm.removeControl('days_import_warning');
      this.productForm.removeControl('days_exp_warning');
    }
  }


  public subProducts = []
  public isVisibleAddSize = false;                    // mở model thêm/ sửa sub product

  /**
   * xóa subprouct local
   * @param data 
   * @example this.deleteSize(subproduct_selected)
   */
  deleteSize(data) {
    this.subProducts = this.subProducts.filter(sub => sub.name != data.name)
    if (this.subProducts.length == 0) {
      this.productForm.controls['subProducts'].setValue([])
    } else {
      this.productForm.controls['subProducts'].setValue(this.subProducts)
    }
  }
  /**
   * đóng modal thêm sub và ko lưu
   * @example this.handleCancelAdd()
   */
  handleCancelAdd() {
    this.isVisibleAddSize = false;
  }

  price_size_purchase: any;
  price_size: any;
  price2_size: any;
  public formSubProduct: FormGroup
  public titleSubProduct = '';
  public subAdd = true;                   // dùng để xác định dùng form edit/ form add
  index_sub_update: number = 0;
  // hàm thực hiện thêm hoặc sửa sub
  handleFormSubProduct(value) {
    if (!this.barcode) {
      this.vhComponent.alertMessageDesktop("error", this.languageService.translate("Barcode already exists"))
    }
    else {
      let data = {
        ...value,
        name: value.name.toString().trim(),
        barcode: value.barcode.toString().trim(),
        unit: value.unit.toString().trim(),
        units: value.units,
        lots: value.lots,
        price: this.price_size.getRawValue() ? parseFloat(this.price_size.getRawValue()) : 0,
        price2: this.price2_size.getRawValue() ? parseFloat(this.price2_size.getRawValue()) : 0,
        price_import: this.price_size_purchase.getRawValue() ? parseFloat(this.price_size_purchase.getRawValue()) : 0
      }
      if (!this.subAdd) {
        this.subProducts[this.index_sub_update] = data;
      }
      else
        this.subProducts.push(data)
      this.productForm.controls['subProducts'].setValue(this.subProducts)
      this.isVisibleAddSize = false;
    }

  }

  // hiển thị Form thực hiện add hoặc là thực hiện việc edit
  showSubProduct(data?) {
    this.isVisibleAddSize = true;
    this.barcodes = [];
    for (let i in this.subProducts) {
      this.barcodes = this.barcodes.concat([...this.subProducts[i].units.map(e => { return e.barcode }), this.subProducts[i].barcode, ...this.subProducts[i].lots.map(lot => { return lot.barcode })])
    }
    if (data) {
      this.titleSubProduct = this.languageService.translate('Cập nhật thuộc tính sản phẩm');
      this.initFormSubProduct(data);
      this.subAdd = false;
      this.vhAlgorithm.waitingStack().then(() => {
        this.price_size = this.vhAlgorithm.vhnumeral(".size-price-item") ? this.vhAlgorithm.vhnumeral(".size-price-item") : 0;
        this.price2_size = this.vhAlgorithm.vhnumeral(".size-price2-item") ? this.vhAlgorithm.vhnumeral(".size-price2-item") : 0;
        this.price_size_purchase = this.vhAlgorithm.vhnumeral(".size-price-purchase-item") ? this.vhAlgorithm.vhnumeral(".size-price-purchase-item") : 0;
      })
      this.index_sub_update = this.subProducts.findIndex(item => item.name == data.name)
    }
    else {
      this.titleSubProduct = this.languageService.translate('Thêm thuộc tính sản phẩm');
      this.initFormSubProduct();
      this.subAdd = true;

    }

  }

  /**
   * 
   * @param data 
   * @example this.duplicateSubProduct(subproduct_selected)
   */
  duplicateSubProduct(data) {
    let value = { ...data }
    delete value.name
    this.checkBarcodeSubProduct(value.barcode, '');
    this.initFormSubProduct(value);
    this.subAdd = true;
    this.titleSubProduct = this.languageService.translate('Thêm phân loại sản phẩm');
    this.isVisibleAddSize = true;
    this.vhAlgorithm.waitingStack().then(() => {
      this.price_size = this.vhAlgorithm.vhnumeral(".size-price-item") ? this.vhAlgorithm.vhnumeral(".size-price-item") : 0;
      this.price2_size = this.vhAlgorithm.vhnumeral(".size-price2-item") ? this.vhAlgorithm.vhnumeral(".size-price2-item") : 0;
      this.price_size_purchase = this.vhAlgorithm.vhnumeral(".size-price-purchase-item") ? this.vhAlgorithm.vhnumeral(".size-price-purchase-item") : 0;
    })
  }

  // Khởi tạo form sub product
  initFormSubProduct(item?: any) {
    this.formSubProduct = new FormGroup({
      name: new FormControl(item ? item['name'] : '', [Validators.required]),
      price_import: new FormControl(item ? item['price_import'] : '', Validators.compose([Validators.required, Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)")])),
      price: new FormControl(item ? item['price'] : '', Validators.compose([Validators.required, Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)")])),
      price2: new FormControl(item ? item['price2'] : '', Validators.compose([Validators.required, Validators.pattern("(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)")])),
      warning_number: new FormControl(item ? item['warning_number'] : 0, [Validators.required]),
      barcode: new FormControl(item ? item['barcode'] : ''),
      unit: new FormControl(item ? item['unit'] : ''),
      units: new FormControl(item ? item['units'] : []),
      allow_sell: new FormControl(item ? item['allow_sell'] : true),
      selling: new FormControl(item ? item['selling'] : true),
      manylot: new FormControl(item ? item['manylot'] : false),
      days_import_warning: new FormControl(item ? item['days_import_warning'] : ''),
      days_exp_warning: new FormControl(item ? item['days_exp_warning'] : ''),
      lots: new FormControl(item ? item['lots'] : []),
    });
  }

  /**
   * tạo barcode tự động cho subproduct
   * @example this.changeBarcodeSubProduct()
   */
  changeBarcodeSubProduct() {
    let newbarcode = '';
    for (let index = 0; index < 12; index++) {
      newbarcode += Math.floor(Math.random() * 10)
    }
    this.checkBarcode(newbarcode);
    if (this.barcode) {
      this.formSubProduct.controls['barcode'].setValue(newbarcode)
    }
  }

  show_lot_list = false;
  lot_list: any = [];

  /**
   * mở drawer danh sách lô sp
   * @param lots 
   * @example this.openListLot([])
   */
  openListLot(lots?) {
    this.barcodes = [];
    if (!this.productForm.value.manysize) {


      this.barcodes = [this.productForm.value.barcode].concat(this.dataUnits.map(item => { return item.barcode }))
    }
    else {
      for (let i in this.subProducts) {
        this.barcodes = this.barcodes.concat([...this.subProducts[i].units.map(e => { return e.barcode }), this.subProducts[i].barcode, ...this.subProducts[i].lots.map(lot => { return lot.barcode })])
      }
      this.barcodes.push(this.formSubProduct.value.barcode, ...this.formSubProduct.value.units.map(item => { return item.barcode }))
    }
    this.show_lot_list = true;
    if (lots) this.lot_list = lots
  }

  /**
   * bắt sk nhấn nút lưu drawer lô sp
   * @param lot_list 
   */
  handleLotList(lot_list) {
    this.show_lot_list = false;
    if (lot_list) {
      if (this.productForm.value.manysize) {
        this.formSubProduct.controls['lots'].setValue(lot_list)
      }
      else
        this.lot_list = lot_list;
    }


  }
  /**
   * bắt sk thay đổi thiết lập lô cho subproduct
   * @param event 
   * @example thsi.changeManyLotSub(true)
   */
  changeManyLotSub(event) {
    if (event) {
      this.formSubProduct.addControl('days_import_warning', new FormControl(null))
      this.formSubProduct.addControl('days_exp_warning', new FormControl(null))
    }
    else {
      this.formSubProduct.removeControl('days_import_warning');
      this.formSubProduct.removeControl('days_exp_warning');
    }
  }

  gotoConfigProduct() {
    this.router.navigate(['/dashboard/settings/configuration/products'], { state: { goback: true } })
  }
}

