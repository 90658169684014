import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'empty',
    loadChildren: () => import('./empty/empty.module').then(m => m.EmptyModule)
  },
  {
    path: 'sales-pos',
    loadChildren: () => import('./sales-pos/sales-pos.module').then(m => m.SalesPosModule)
  },
  {
    path: 'sales-desktop',
    loadChildren: () => import('./sale-desktop/sale-desktop.module').then(m => m.SaleDesktopModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path:'exchange-voucher',
    loadChildren: () => import('./exchange-voucher/exchange-voucher.module').then(m => m.ExchangeVoucherModule)
  },
  {
    path:'prepaid-card',
    loadChildren: () => import('./prepaid-card/prepaid-card.module').then(m => m.PrepaidCardModule)
  },
  
  {
    path: 'sales-voucher',
    loadChildren: () => import('./sales-voucher/sales-voucher.module').then(m => m.SalesVoucherModule)
  },
  {
    path: 'sales-payment-card',
    loadChildren: () => import('./sales-payment-card/sales-payment-card.module').then(m => m.SalesPaymentCardModule)
  },
  {
    path: 'bill-web-app',
    loadChildren: () => import('./bill-web-app/bill-web-app.module').then(m => m.BillWebAppModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
