import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import jsbarcode from 'jsbarcode';
import printJS from 'print-js';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';
declare var electron: any;

@Component({
  selector: 'app-test-expend-k80',
  templateUrl: './test-expend-k80.component.html',
  styleUrls: ['./test-expend-k80.component.scss']
})
export class TestExpendK80Component implements OnInit {

  @Input() printer: any;
  infoStore: any = {};
  infoBranch: any = {}
  billInfo: any = {
    bill_code: "123456789",
    date: new Date().toISOString(),
    name: "Công Ty TNHH GPCN VIETHAS",
    phone: "02838661111",
    address: "161/3 Ni Sư Huỳnh Liên, Tân Bình",
    total: 200000,
    employee: "Quang Nghĩa",
    content: "Thu / nhận tiền công nợ"
  }
  constructor(
    public platform: Platform,

    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    jsbarcode("#barcode-expense", "123456789", {
      displayValue: false,
      height: 30
    });
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("expenses-electron-k80a").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }
  printBrowser() {
    printJS({
      printable: "expenses-k80a",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K80 {  padding-bottom: 32px;}' +
        '.K80 .header { border-bottom: 1px black dashed; padding: 4px 0;}' +
        '.K80 .header-header { text-align: center; font-size: 2vw; padding: 4px 0;}' +
        '.K80 .header-store-name { text-align: center; font-size: 5vw;}' +
        '.K80 .header-store-address, .K80 .header-store-phone, .K80 .header-store-branch { text-align: center; font-size: 4vw;}' +
        '.K80 .content { padding: 4px 0;}' +
        '.K80 .content-header { border-bottom: 1px black dashed;}' +
        '.K80 .content-header-title { text-align: center;font-size: 6vw;}' +
        '.K80 .content-header-info { text-align: start;font-size: 4vw;}' +
        '.K80 .content-info-detail { text-align: left;}' +
        '.K80 .content-note { text-align: left;}' +
        '.K80 .signature { display: flex;justify-content: space-between;padding: 16px;}' +
        '.K80 .signature-detail { text-align: center;}' +
        '.K80 .signature-detail > b { font-size: 2.5vw;}' +
        '.K80 .signature-detail > em { font-size: 2vw;}'
    });
  }
}
