<nz-drawer [nzMaskClosable]="true" [nzWidth]="'90vw'" nzTitle='{{"Đơn vị quy đổi" | translate}}'
    [nzVisible]="showDrawerUnits" [nzFooter]="footerAddCustomer" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <form nz-form [formGroup]="formUnits">
            <!-- <div *ngIf="units.length == 0" style="text-align:center">
                <span>{{'Không có đơn vị quy đổi'|translate}}</span>
                <button class="btn-add" style="margin: auto;" nz-button nzType="default" (click)="addUnits()">
                    <i nz-icon nzType="plus" nzTheme="outline"
                        style="color: var(--ion-color-vh-white);margin-right: 8px;"></i>
                    {{'Tạo đơn vị' | translate}}
                </button>
            </div> -->
            <ng-container formArrayName="units" >
                <div nz-row [nzGutter]="24">
                    <div nz-col class="gutter-row" nzSpan="1"></div>
                    <div nz-col class="gutter-row" nzSpan="3">
                        <div class='label'><b>{{'Tên đơn vị' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="2">
                        <div class='label'><b>{{'Tỉ lệ quy đổi' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="4">
                        <div class='label'><b>{{'Giá bán lẻ' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="4">
                        <div class='label'><b>{{'Giá bán buôn' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="4">
                        <div class='label'><b>{{'Giá nhập' | translate}}*</b></div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="3">
                        <div class='label'><b>{{'Mã hàng hóa' | translate}}</b></div>
                    </div>
                    <div nz-col class="gutter-row ion-text-end" nzSpan="3">
                        <div class='label'><b>{{'Default' | translate}}</b></div>
                    </div>
                </div>
                <nz-radio-group [(ngModel)]="ratio_default" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeDefault()">
                    <div nz-row [nzGutter]="24" style="padding-bottom: 8px" >
                        <div class="gutter-row" nz-col nzSpan="1"
                            style="display: flex; justify-content: center; align-items: center; padding: 0px">
                            <!-- <i (click)="$event.stopPropagation()" nz-popconfirm nzPopconfirmTitle="{{'Xóa đơn vị này?'}}" (nzOnConfirm)="deleteUnits(i)" nzPopconfirmPlacement="topLeft" style="color: var(--ion-color-vh-red); cursor: pointer;" nz-icon nzType="close" nzTheme="outline"></i> -->

                        </div>
                        <div class="gutter-row" nz-col nzSpan="3" style="padding-left: 0px; padding-right: 0px">
                            <input nz-input disabled placeholder="{{ 'Nhập tên đơn vị'| translate}}"
                                [value]="dataUnit.unit" />
                        </div>
                        <div class="gutter-row" nz-col nzSpan="2" style="padding-left: 0px; padding-right: 0px">
                            <input nz-input disabled placeholder="{{ 'Nhập tỉ lệ quy đổi'| translate}}"
                                [value]="dataUnit.ratio" />
                        </div>
                        <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                            <input nz-input disabled placeholder="{{ vhAlgorithm.vhcurrencyunit_symbol(0)}}"
                                [value]="vhAlgorithm.vhcurrencyunit(dataUnit.price)" />
                        </div>
                        <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                            <input nz-input disabled placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}"
                                [value]="vhAlgorithm.vhcurrencyunit(dataUnit.price2)" />
                        </div>
                        <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                            <input nz-input disabled placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}"
                                [value]="vhAlgorithm.vhcurrencyunit(dataUnit.price_import)" />
                        </div>
                        <div class="gutter-row" nz-col nzSpan="4">
                            <div
                                style="padding-left: 0px; padding-right: 0px; display: flex;  justify-content: center; align-items: center">
                                <input nz-input disabled placeholder="{{ 'Nhập mã vạch'| translate}}"
                                    [value]="dataUnit.barcode" />
                            </div>
                        </div>
                        <div class="gutter-row center-all-content" nz-col nzSpan="2">
                            <label nz-radio [nzValue]="1"></label>
                        </div>
                    </div>
                    <div *ngFor="let unitForm of units.controls; let i = index">

                        <form nz-row [formGroup]="unitForm" style="padding-bottom: 8px" [nzGutter]="24">
                            <ng-container>
                                <div class="gutter-row" nz-col nzSpan="1"
                                    style="display: flex; justify-content: center; align-items: center; padding: 0px">
                                    <!-- <i (click)="$event.stopPropagation()" nz-popconfirm nzPopconfirmTitle="{{'Xóa đơn vị này?'}}" (nzOnConfirm)="deleteUnits(i)" nzPopconfirmPlacement="topLeft" style="color: var(--ion-color-vh-red); cursor: pointer;" nz-icon nzType="close" nzTheme="outline"></i> -->
                                    <ion-icon  name="close-outline" (click)="$event.stopPropagation()"
                                        nz-popconfirm nzPopconfirmTitle="{{'Xóa đơn vị này?'}}"
                                        (nzOnConfirm)="deleteUnits(i)" nzPopconfirmPlacement="topLeft"
                                        style="color: var(--ion-color-vh-red); cursor: pointer;width: 24px; height: 24px;">
                                    </ion-icon>
                                </div>
                                <div class="gutter-row" nz-col nzSpan="3" style="padding-left: 0px; padding-right: 0px">
                                    <input nz-input formControlName="unit" [id]="'unit'+i"
                                        placeholder="{{ 'Nhập tên đơn vị'| translate}}" #unit
                                        (keyup)="checkNameUnit(unit.value,i)" />
                                </div>
                                <div class="gutter-row" nz-col nzSpan="2" style="padding-left: 0px; padding-right: 0px">
                                    <input nz-input [id]="'ratio_units'+i" #unitRatio
                                        (keyup)="changeUnitsRatio(unitRatio.value, i)" [min]="2" nz-input
                                        formControlName="ratio" type="number" />
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                    <input [id]="'price_units'+i" (blur)="changeUnitsPrice('price_units'+i,i)"
                                        [min]="0" nz-input formControlName="price"
                                        placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}" />
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                    <input [id]="'price2_units'+i" (blur)="changeUnitsPrice2('price2_units'+i,i)"
                                        [min]="0" nz-input formControlName="price2"
                                        placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}" />
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                    <input [id]="'priceImport_units'+i"
                                        (blur)="changeUnitsPriceImport('priceImport_units'+i,i)" [min]="0" nz-input
                                        formControlName="price_import"
                                        placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}" />
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4">
                                    <div
                                        style="padding-left: 0px; padding-right: 0px; display: flex;  justify-content: center; align-items: center">
                                        <input nz-input formControlName="barcode"  placeholder="{{ 'Nhập mã vạch'| translate}}"
                                            (keyup)="checkBarcode(unitForm.value.barcode,i)" />
                                        <i style="padding-left: 8px; cursor: pointer;font-size: 1rem;" nz-icon nzType="reload"
                                            nzTheme="outline" (click)="setBarcodeForUnit(i)" nz-tooltip
                                            nzTooltipTitle="{{'Generate barcodes automatically' | translate}}"
                                            nzTooltipPlacement="bottom"></i>
                                    </div>
                                </div>
                                <div class="gutter-row center-all-content" nz-col nzSpan="2">
                                    <label nz-radio [nzValue]="unitForm.value.ratio"></label>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="gutter-row" nz-col nzSpan="1"
                                    style="display: flex; justify-content: center; align-items: center; padding: 0px">
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                    <div class="validation-errors" [id]="'unitError'+i">
                                        <ng-container>
                                            <div class="error-message">
                                                <ion-text color="vh-red">{{'Đơn vị đã tồn tại'|translate}}
                                                </ion-text>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="gutter-row" nz-col nzSpan="2" style="padding-left: 0px; padding-right: 0px">
                                    <div class="validation-errors" [id]="'ratioError'+i">
                                        <ng-container>
                                            <div class="error-message">
                                                <ion-text color="vh-red">{{'Tỉ lệ đã tồn tại'|translate}}
                                                </ion-text>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4" style="padding-left: 0px; padding-right: 0px">
                                </div>
                                <div class="gutter-row" nz-col nzSpan="4">
                                    <div class="validation-errors" [id]="'barcodeError'+i">
                                        <ng-container>
                                            <div class="error-message">
                                                <ion-text color="vh-red">{{'Barcode này đã tồn tại!'|translate}}
                                                </ion-text>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="gutter-row " nz-col nzSpan="1"></div>
                            </ng-container>
                        </form>


                    </div>
                </nz-radio-group>
                <div style="padding-top: 8px; float: right;">
                    <button class="btn-add" nz-button nzType="default" (click)="addUnits()">
                        <i nz-icon nzType="plus" nzTheme="outline"
                            style="color: var(--ion-color-vh-white);margin-right: 8px;"></i>
                        {{'Thêm đơn vị' | translate}}
                    </button>
                </div>
            </ng-container>

        </form>
    </ng-container>
    <ng-template #footerAddCustomer>
        <div class="footerAddCustomer" style="float: right">
            <button nz-button style="margin-right: 8px;" (click)="close()">{{"Cancel" | translate}}</button>
            <button nz-button (click)="okUnits()">{{units.length > 0 ? 'Lưu':'Ok'}}</button>
        </div>
    </ng-template>
</nz-drawer>
