<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="ios" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="receipt-k80a" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" class="header-header">{{printer.header}}</div>
            <div class="header-store">
                <div  *ngIf="!printer.name_store_hidden" class="header-store-name"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div  *ngIf="!printer.name_branch_hidden && useBranch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"  class="header-store-branch">
                    {{infoBranch['name']}}</div>
                <div  *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="header-store-address">
                    {{infoStore['address']}}</div>
                <div  *ngIf="!printer.phone_number_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="header-store-phone">{{"Phone number" |
                    translate}} : {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}</div>
                    <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                        translate}} : {{infoStore['email']}}
                    </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header" style="padding: 4px 0;">
                <div class="content-header-title">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{"Money received receipt"| translate |
                        uppercase}}</b>
                </div>
                <div class="content-header-info">
                    <div  *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>:
                        {{billInfo.bill_code}}</div>
                    <div  *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date
                        | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div  *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-header-info"><b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b> :
                    {{invoiceInfo['employee'].name}}
                </div>
            </div>


            <div class="content-info-detail" style="padding: 4px 0;">
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" | translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{'From branch' | translate}}: <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['name_branch_send'].name |
                        translate}}</b>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Receive method' | translate}}: <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] | translate}}</b>
                </div>
                <div *ngIf="billInfo.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account name" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].name:
                            ''}}</b>
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account number" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].number:
                            ''}}</b>
                    </div>
                </div>
            </div>


            <div class="content-note">
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-note-label">{{"Note" | translate}}
                    :</div>
            </div>

            <div class="content-info-barcode"  *ngIf="!printer.barcode_hidden" >
                <svg id="barcode-receipt"></svg>
            </div>

        </div>
        <div class="signature">
            <div class="signature-detail">
                <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Prepared by" | translate}}</b><br><em
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
            </div>
            <div class="signature-detail"><b
                    [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br>
                <em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div  *ngIf="!printer.footer_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" class="header-header">{{printer.footer}}</div>
    </div>
    <div style="display: none;" id="electron-receipt-k80a" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%'
                    src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style="border-bottom: 1px black dashed;
        padding: 4px 0;" *ngIf="infoStore">
            <div style="text-align: center;
            
            padding: 4px 0;" *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div>
                <div *ngIf="!printer.name_store_hidden" style=" text-align: center;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore.company}}</b></div>
                <div *ngIf="useBranch && !printer.name_branch_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" style="text-align: center;
                " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} :
                    {{infoStore['phone']}}</div>
                    <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        MST: {{infoStore['tax_number']}}</div>
                    <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                        translate}} : {{infoStore['email']}}
                    </div>
            </div>
        </div>

        <div style="padding: 4px 0;">
            <div style=" border-bottom: 1px black dashed;padding: 4px 0;">
                <div style="text-align: center;
                ">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{"Money received receipt"| translate |
                        uppercase}}</b>
                </div>
                <div style="text-align: start;
                ">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" ><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>:
                        {{billInfo.bill_code}}</div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{billInfo.date
                        | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                </div>
                <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: start;
                "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}}</b>
                    : {{invoiceInfo['employee'].name}}
                </div>
            </div>


            <div style=" text-align: left;padding: 4px 0;">
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Total amount" |
                    translate}} : <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{'From branch' | translate}}: <b
                        [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['name_branch_send'].name |
                        translate}}</b>
                </div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" |
                    translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Receive method' |
                    translate}}: <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['paymentName'] |
                        translate}}</b>
                </div>
                <div *ngIf="billInfo.payment_type == 3">
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account name" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].name:
                            ''}}</b>
                    </div>
                    <div  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        {{"Account number" | translate}}: <b
                            [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['wallet'] ?
                            invoiceInfo['wallet'].number:
                            ''}}</b>
                    </div>
                </div>
            </div>


            <div style="text-align: left;">
                <div style="align-self: flex-end; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{"Note" |
                    translate}} :</div>
            </div>

            <div style="text-align: center;" *ngIf="!printer.barcode_hidden">
                <svg id="barcode-receipt"></svg>
            </div>

        </div>
        <div style=" display: flex;
        justify-content: space-between;
        padding: 16px;">
           <div style="text-align: center;">
            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{"Prepared by" |
                translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"
                >({{'Sign, write full name'|translate}})</em>
        </div>
        <div style="text-align: center;">
            <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" >{{'cashier'|translate}}</b><br>
            <em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full
                name'|translate}})</em>
        </div>
        </div>
        <br>
        <br>
        <br>
        <div *ngIf="!printer.footer_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" style=" border-bottom: 1px black dashed;
        padding: 4px 0; text-align: center; ">{{printer.footer}}</div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md"
            expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>