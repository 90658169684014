import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode'
import { VhAlgorithm, VhQuerySales, VhAuth } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';

import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;
@Component({
  selector: 'app-transfer-money-k80-col4',
  templateUrl: './transfer-money-k80-col4.component.html',
  styleUrls: ['./transfer-money-k80-col4.component.scss']
})
export class TransferMoneyK80Col4Component implements OnInit {

  @Input() printer: any;
  @Input() billInfo: any;
  infoStore: any = {}
  invoiceInfo: any = {}
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    public platform: Platform,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales,
    private vhAuth: VhAuth,
    private lang: LanguageService,

    private fncService: FunctionService
  ) { }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
   this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
   
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.billInfo.employee),
      paymentName: this.setPaymentName(this.billInfo.payment_type),
      wallet: this.vhQuerySales.getlocalWallet(this.billInfo.id_wallet),
      name_branch_reception: this.vhQuerySales.getlocalBranch(this.billInfo.id_branch_receive),
      branch_default: this.vhQuerySales.getlocalBranch(this.billInfo.id_branch)
    }
  }
  ngAfterViewInit(): void {
    if(!this.printer.barcode_hidden)  jsbarcode("#barcode-receipt", this.billInfo['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.lang.translate("Cash")
      case 2: return this.lang.translate("Debit")
      case 3: return this.lang.translate("Account")
    }
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-transfer-money-k80a").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "receipt-k80a",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K80 { padding-right: 16px; padding-bottom: 32px;}' +
        '.K80 .header { border-bottom: 1px black dashed; padding: 4px 0;}' +
        '.K80 .header-header { text-align: center; font-size: 2vw; padding: 4px 0;}' +
        '.K80 .header-store-name { text-align: center; font-size: 5vw;}' +
        '.K80 .header-store-address, .K80 .header-store-phone,.K80 .header-store-branch {text-align: center;font-size: 1rem;}' +
        '.K80 .content { padding: 4px 0;}' +
        '.K80 .content-header { border-bottom: 1px black dashed;}' +
        '.K80 .content-header-title { text-align: center;font-size: 6vw;}' +
        '.K80 .content-header-info { text-align: start;font-size: 4vw;}' +
        '.K80 .content-info-detail { text-align: left;}' +
        '.K80 .content-note { text-align: left;}' +
        '.K80 .signature { display: flex;justify-content: space-between;padding: 16px;}' +
        '.K80 .signature-detail { text-align: center;}' +
        '.K80 .signature-detail > b { font-size: 2.5vw;}' +
        '.K80 .signature-detail > em { font-size: 2vw;}'
    });
  }

}
