import { Component, Input, OnInit } from '@angular/core';
import { VhAlgorithm, VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';
import JsBarcode from 'jsbarcode'
import PrintJs from 'print-js'
import { Platform } from '@ionic/angular';
import { VhBillType2930DetailEdit, VhBillType2930Edit } from 'src/app/components/bill-type/bill-type-29-30/bill-type2930-interface';
import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;

@Component({
  selector: 'app-receive-goods-a5',
  templateUrl: './receive-goods-a5.component.html',
  styleUrls: ['./receive-goods-a5.component.scss']
})
export class ReceiveGoodsA5Component implements OnInit {
  display_promotion_selling_price: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').display_promotion_selling_price
  @Input() printer;
 
  @Input() invoice: VhBillType2930Edit;
  @Input() invoice_detail: VhBillType2930DetailEdit[] = []
  subTotal = 0;
  infoStore: any = {}
  public invoiceInfo: any = {}
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    private vhComponent: VhComponent,
    private vhAuth: VhAuth,
    private vhQuerySales: VhQuerySales,
    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public platform: Platform,
    private fncService: FunctionService
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.invoice.id_employee),
      branch_name: this.vhQuerySales.getlocalBranch(this.invoice.id_branch),
      branch_name_reception: this.vhQuerySales.getlocalBranch(this.invoice.id_branch_receive),
    }
    this.setSubTotal()
  }
  setSubTotal() {
    let subTotal = 0;
    for (let i of this.invoice_detail) {
      subTotal += (i.price ? i.price * i.quantity : 0)
    }
    this.subTotal = subTotal;
  }
  getUnit(units: any[], ratio) {
    return this.vhQuerySales.getUnit_byRatio(units,ratio).unit
  }
  getLotNumber(lots, _id){
    let lot = lots.find(item => item._id == _id)
    return lot.lot_number 
  } 
  ngAfterViewInit(): void {
    if(!this.printer.barcode_hidden)  this.renderBarcode(this.invoice.bill_code)
  }
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.lang.translate("Cash")
      case 2: return this.lang.translate("Debit")
      case 3: return this.lang.translate("Account")
    }
  }
  Print() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printDesktop()
  }
  printElectron() {
    let html = document.getElementById("electron-receive-goods-a45a").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      a4:true,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printDesktop() {
    PrintJs({
      printable: "receive-goods-a45a",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 10pt;}' +
        '.A4 {width: 100%; font-family: Arial;}' +
        '.A4 .header-store {display: grid; grid-template-columns: 7% 93%;}' +
        '.A4 .header-store-logo {text-align: center;}' +
        '.A4 .header-store-logo img {width: 100%}' +
        '.A4 .header-store-info-contact,.A4 .header-store-info-name {display: grid;padding: 0.05vw 16px;grid-template-columns: 65% 35%;}' +
        '.A4 .header-store-info-contact-address,.A4 .header-store-info-contact-phone {font-size: 1.8vw;}' +
        '.A4 .header-store-info-name-company,.A4 .header-store-info-name-branch {font-size: 1.8vw;}' +
        '.A4 .header-store-info-invoice {font-size: 2.5vw;text-align: center;padding: 0.05vw 0;font-weight: bold;}' +
        '.A4 .header-invoice {font-size: 1.8vw;}' +
        '.A4 .header-invoice-line {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .header-invoice-line-left {text-align: start;}' +
        '.A4 .header-invoice-line-right {text-align: end;}' +
        '.A4 .content table,.A4 .content thead,.A4 .content tbody {border: none;border-collapse: collapse; }' +
        '.A4 .content table {width: 100%;}' +
        '.A4 .content table thead th {font-size: 2vw;border: 1px black solid;border-collapse: collapse;}' +
        '.A4 .content table tbody tr td {font-size: 1.8vw;border: 1px black solid;border-collapse: collapse;}' +
        '.A4 .content table tbody tr td > .price-origin {text-decoration: line-through;font-size: 1.5vw;}' +
        '.A4 .content table tbody tr.pay td {font-size: 2vw;}' +
        '.A4 .content-note {font-size: 1.8vw;}' +
        '.A4 .content-pay {font-size: 1.8vw;}' +
        '.A4 .signature {padding: 16px 5vw;display: flex;justify-content: space-between;}' +
        '.A4 .signature-detail {text-align: center;}' +
        '.A4 .signature-detail > b {font-size: 1.8vw;}' +
        '.A4 .signature-detail > em {font-size: 1.5vw;}'
    })
  }
  close() {
    this.vhComponent.hideModal()
  }

  renderBarcode(value) {
    JsBarcode("#barcode-receive-goods", value, {
      format: "CODE128",
      lineColor: "#000",
      height: 40,
      displayValue: false
    });
  }

}
