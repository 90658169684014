import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import printJS from 'print-js';
import jsbarcode from 'jsbarcode'
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';

import { LanguageService } from 'src/app/services/language.service';
declare var electron: any;

@Component({
  selector: 'app-test-expend-a4',
  templateUrl: './test-expend-a4.component.html',
  styleUrls: ['./test-expend-a4.component.scss']
})
export class TestExpendA4Component implements OnInit {
  @Input() printer: any;
  infoStore: any = {};
  infoBranch: any = {}
  billInfo: any = {
    bill_code: "123456789",
    date: new Date().toISOString(),
    name: "Công Ty TNHH GPCN VIETHAS",
    phone: "02838661111",
    address: "161/3 Ni Sư Huỳnh Liên, Tân Bình",
    total: 200000,
    employee: "Quang Nghĩa",
    content: "Thu / nhận tiền công nợ"
  }
  constructor(
    public platform: Platform,

    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    public vhQuerySales: VhQuerySales
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    jsbarcode("#barcode-receipt", "123456789", {
      displayValue: false,
      height: 30
    });
  }
  close() {
    this.vhComponent.hideModal()
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("expenses-electron-a4").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "expenses-a4",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 10pt;}' +
        '.A4 .header-store {display: grid;grid-template-columns: 7% 93%;}' +
        '.A4 .header-store-logo {text-align: center;}' +
        '.A4 .header-store-logo img {width: 100%;}' +
        '.A4 .header-store-info-name {font-size: 2vw;padding: 0.05vw 16px;}' +
        '.A4 .header-store-info-address, .A4 .header-store-info-contact, .A4 .header-store-info-branch {font-size: 1.8vw;padding: 0.05vw 16px;}' +
        '.A4 .header-title {text-align: center;font-size: 2.5vw;}' +
        '.A4 .content {font-size: 1.8vw;}' +
        '.A4 .content-invoice {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-invoice-date {text-align: end;}' +
        '.A4 .content-info {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-info-barcode {text-align: end;}' +
        '.A4 .content-payment {display: grid;grid-template-columns: 50% 50%;}' +
        '.A4 .content-payment .content-payment-number {text-align: end;}' +
        '.A4 .content-note {display: grid;grid-template-columns: max-content auto;}' +
        '.A4 .content-note-label {align-self: flex-end;}' +
        '.A4 .content-note-dot {border-bottom: 1px dotted black;height: 1.8vw;}' +
        '.A4 .signature {display: flex;justify-content: space-between;padding: 16px 5vw;}' +
        '.A4 .signature-detail {text-align: center;}' +
        '.A4 .signature-detail > b {font-size: 1.8vw;}' +
        '.A4 .signature-detail > em {font-size: 1.5vw;}'
    });
  }

}
