import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss'],
})
/** component này để thêm xóa sửa danh sách đơn vị lưu local khi thêm sp */
export class UnitsListComponent implements OnInit {
  unit_main: any;
  @Input('showDrawerUnits') showDrawerUnits: boolean = false;
  @Input('barcodes') barcodes: any = [];
  @Input('dataUnit') dataUnit: any;
  @Input('dataUnits') dataUnits: any[];
  @Output('closeUnits') closeUnits = new EventEmitter<any>();
  ratio_default: number = 1;
  public formUnits = this.formBuilderfb.group({
    // Form thêm đơn vị quy đổi
    units: this.formBuilderfb.array([], Validators.required),
  });
  public unitForm: FormGroup;
  ratioError = true;
  disabledButton = true;
  constructor(
    public vhAlgorithm: VhAlgorithm,
    private formBuilderfb: FormBuilder,
    private vhQuerySales: VhQuerySales,
    private vhComponent: VhComponent,
    private languageService: LanguageService
  ) {}
  ngOnInit(): void {
    this.initAddUnitsForm(this.dataUnits);
    this.ratio_default = this.dataUnits.find((e) => e.default)
      ? this.dataUnits.find((e) => e.default).ratio
      : 1;
  }
  ngAfterViewInit() {
    for (let i = 0; i < this.units.value.length; i++) {
      //  đơn vị quy đổi
      if (this.units.value[i].ratio != 1) {
        this.vhAlgorithm.vhnumeral('#' + 'price_units' + i);
        this.vhAlgorithm.vhnumeral('#' + 'price2_units' + i);
        this.vhAlgorithm.vhnumeral('#' + 'priceImport_units' + i);
      }
    }
  }

  initAddUnitsForm(data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        //  đơn vị quy đổi
        this.addUnits(data[i]);
      }
    }
  }

  // get mãng đơn vị quy đổi
  get units() {
    return this.formUnits.controls['units'] as FormArray;
  }
  // thêm đơn vị quy đổi
  addUnits(unit?) {
    this.unitForm = this.formBuilderfb.group({
      unit: new FormControl(
        unit ? unit.unit : '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)'
          ),
        ])
      ),
      price: new FormControl(
        unit ? unit.price : '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)'
          ),
        ])
      ),
      price2: new FormControl(
        unit ? unit.price2 : '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)'
          ),
        ])
      ),
      price_import: new FormControl(
        unit ? unit.price_import : '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)'
          ),
        ])
      ),
      barcode: new FormControl(unit ? unit.barcode : ''),
      ratio: new FormControl(unit ? unit.ratio : '', [Validators.required]),
      default: new FormControl(unit ? unit.default : false, [
        Validators.required,
      ]),
    });
    this.units.push(this.unitForm);
  }
  // xóa đơn vị quy đổi
  deleteUnits(index: number) {
    this.units.removeAt(index);
    this.barcode_exist = false;
  }

  /**
   * bắt sk nhấn nút lưu
   */
  okUnits() {
    if (this.barcode_exist) {
      this.vhComponent.alertMessageDesktop(
        'error',
        this.languageService.translate('Barcode đã tồn tại')
      );
    } else {
      this.dataUnits = this.units.value.filter(
        (item) =>
          item.unit != '' &&
          typeof item.price == 'number' &&
          typeof item.price2 == 'number' &&
          typeof item.price_import == 'number' &&
          item.ratio
      );
      this.closeUnits.emit(this.dataUnits);
    }
  }

  /**
   * đóng component
   */
  close(success?) {
    this.closeUnits.emit(success ? true : false);
  }

  /**
   * tạo barcode tự động tại vị trí position trong mảng đơn vị
   * @example setBarcodeForUnit(1)
   */
  setBarcodeForUnit(position) {
    let newbarcode = '';
    for (let index = 0; index < 12; index++) {
      newbarcode += Math.floor(Math.random() * 10);
    }
    let detailproduct =
      this.vhQuerySales.getlocalDetailProduct_byBarcode(newbarcode);
    if (detailproduct == null) {
      (this.units.at(position) as FormGroup)
        .get('barcode')
        .setValue(newbarcode);
      document.getElementById('barcodeError' + position).style.display = 'none';
    } else {
      document.getElementById('barcodeError' + position).style.display =
        'block';
    }
  }

  /**
   * bắt sk thay đổi giá bán
   * @param nameClass 
   * @param position 
   */
  changeUnitsPrice(nameClass, position) {
    let price = this.vhAlgorithm.vhnumeral('#' + nameClass);
    (this.units.at(position) as FormGroup)
      .get('price')
      .patchValue(parseFloat(price.getRawValue() ? price.getRawValue() : '0'));
    this.vhAlgorithm.vhnumeral('#' + nameClass);
  }

  /**
   * bắt sk thay đổi giá bán 2
   * @param nameClass 
   * @param position 
   */
  changeUnitsPrice2(nameClass, position) {
    let price = this.vhAlgorithm.vhnumeral('#' + nameClass);
    (this.units.at(position) as FormGroup)
      .get('price2')
      .patchValue(parseFloat(price.getRawValue() ? price.getRawValue() : '0'));
    this.vhAlgorithm.vhnumeral('#' + nameClass);
  }

  /**
   * bắt sk thay đổi giá nhập
   * @param nameClass 
   * @param position 
   */
  changeUnitsPriceImport(nameClass, position) {
    let price = this.vhAlgorithm.vhnumeral('#' + nameClass);
    (this.units.at(position) as FormGroup)
      .get('price_import')
      .patchValue(parseFloat(price.getRawValue() ? price.getRawValue() : '0'));
    this.vhAlgorithm.vhnumeral('#' + nameClass);
  }

  /** 
   * bắt sk thay đổi unit default
   * @example this.changeDefault()
   */
  changeDefault() {
    this.units.value.forEach((unit) => {
      if (unit.ratio == this.ratio_default) unit.default = true;
      else unit.default = false;
    });
  }

  /**
   * bắt sk thay đổi giá trị ratio của đơn vị
   * @param ratio 
   * @param position 
   */
  changeUnitsRatio(ratio, position) {
    let value = parseFloat(ratio);
    // Thực hiện lọc dữ liệu units khác với vị trí nhập hiện tại
    // Và giá trị nhập vào phải khác với bất cứ giá trị Ratio nào trong mảng vừa lọc
    //  Tồn tại mảng có chiều dài thì giá trị đó đã tồn tại
    if (value == 1) {
      document.getElementById('ratioError' + position).style.display = 'block';
    } else {
      const unitsDef: any[] = this.units.value.filter(
        (item, index) => index != position
      );
      if (unitsDef.length) {
        if (unitsDef.find((item) => item.ratio == value)) {
          document.getElementById('ratioError' + position).style.display =
            'block';
        } else {
          (this.units.at(position) as FormGroup).get('ratio').setValue(value);
          document.getElementById('ratioError' + position).style.display =
            'none';
        }
      } else {
        document.getElementById('ratioError' + position).style.display = 'none';
      }
    }
  }

  barcode_exist = false;
  /**
   * kiểm tra barcode đã tồn tại hay chưa
   * @param barcode 
   * @param position 
   *  
   */
  checkBarcode(barcode, position) {
    if (barcode.length) {
      if (this.barcodes.find((item) => item == barcode)) {
        this.barcode_exist = false;
        document.getElementById('barcodeError' + position).style.display =
          'block';
        return;
      } else {
        if (this.vhQuerySales.getlocalDetailProduct_byBarcode(barcode)) {
          this.barcode_exist = true;
          document.getElementById('barcodeError' + position).style.display =
            'block';
          return;
        } else {
          if (barcode == this.dataUnit.barcode) {
            this.barcode_exist = true;
            document.getElementById('barcodeError' + position).style.display =
              'block';
            return;
          } else {
            const unitsNotPosition: any[] = this.units.value.filter(
              (item, index) => index != position
            );
            if (unitsNotPosition.length) {
              unitsNotPosition.forEach((element) => {
                if (element.barcode != barcode) {
                  this.barcode_exist = false;
                  document.getElementById(
                    'barcodeError' + position
                  ).style.display = 'none';
                  return;
                } else {
                  this.barcode_exist = true;
                  document.getElementById(
                    'barcodeError' + position
                  ).style.display = 'block';
                  return;
                }
              });
            } else {
              this.barcode_exist = false;
              document.getElementById('barcodeError' + position).style.display =
                'none';
              return;
            }
          }
        }
      }
    } else {
      this.barcode_exist = false;
      document.getElementById('barcodeError' + position).style.display = 'none';
      return;
    }
  }

  //  kiểm tra đơn vị khi thêm
  checkNameUnit(unit: string, position: number) {
    if (unit.length) {
      if (unit == this.dataUnit.unit) {
        document.getElementById('unitError' + position).style.display = 'block';
        return;
      } else {
        const unitsNotPosition: any[] = this.units.value.filter(
          (item, index) => index != position
        );
        if (unitsNotPosition.length) {
          unitsNotPosition.forEach((element) => {
            if (element.unit != unit) {
              document.getElementById('unitError' + position).style.display =
                'none';
              return;
            } else {
              document.getElementById('unitError' + position).style.display =
                'block';
              return;
            }
          });
        } else {
          document.getElementById('unitError' + position).style.display =
            'none';
          return;
        }
      }
    }
  }
}
