import { Component, Input, OnInit } from '@angular/core';
import { VhAlgorithm, VhAuth, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';
import { LanguageService } from 'src/app/services/language.service';
import JsBarcode from 'jsbarcode'
import PrintJs from 'print-js'
import { Platform } from '@ionic/angular';
import { VhBillType2930DetailEdit, VhBillType2930Edit } from 'src/app/components/bill-type/bill-type-29-30/bill-type2930-interface';

import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;
@Component({
  selector: 'app-delivery-goods-k80-col3',
  templateUrl: './delivery-goods-k80-col3.component.html',
  styleUrls: ['./delivery-goods-k80-col3.component.scss']
})
export class DeliveryGoodsK80Col3Component implements OnInit {
  @Input() printer;
  @Input() invoice: VhBillType2930Edit;
  @Input() invoice_detail: VhBillType2930DetailEdit[] = []
  subTotal = 0;
  infoStore: any = {}
  public invoiceInfo: any
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  display_promotion_selling_price: boolean = this.vhQuerySales.getLocalAppSettingNameBranch('permission_branch').display_promotion_selling_price
  constructor(
    private vhComponent: VhComponent,
    private vhAuth: VhAuth,
    private vhQuerySales: VhQuerySales,
    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    private platform: Platform,

    private fncService: FunctionService
  ) { }

  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id)
    this.invoiceInfo = {
      employee: this.vhAuth.getlocalEmployee(this.invoice.id_employee),
      branch_name: this.vhQuerySales.getlocalBranch(this.invoice.id_branch),
      branch_name_reception: this.vhQuerySales.getlocalBranch(this.invoice.id_branch_receive),
    }
    this.setSubTotal()
  }
  setSubTotal() {
    let subTotal = 0;
    for (let i of this.invoice_detail) {
      subTotal += (i.price ? i.price * i.quantity : 0)
    }
    this.subTotal = subTotal;
  }
  getUnit(units: any[], ratio) {
    return this.vhQuerySales.getUnit_byRatio(units,ratio).unit
  }
  getLotNumber(lots, _id){
    let lot = lots.find(item => item._id == _id)
    return lot.lot_number 
  } 
  getDateLotNumber(lots, _id) {
    let lot = lots.find(item => item._id == _id)
    return this.fncService.formatDate(lot.date_mfg) + ' - ' + this.fncService.formatDate(lot.date_exp)
  }
  ngAfterViewInit(): void {
    if(!this.printer.barcode_hidden)  this.renderBarcode(this.invoice.bill_code)
  }
  public setPaymentName(type: 1 | 2 | 3) {
    switch (type) {
      case 1: return this.lang.translate("Cash")
      case 2: return this.lang.translate("Debit")
      case 3: return this.lang.translate("Account")
    }
  }
  Print() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printDesktop()
  }
  printElectron() {
    let html = document.getElementById("electron-delivery-goods-k57a").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }
  printDesktop() {
    PrintJs({
      printable: "delivery-goods-k57a",
      type: "html",
      scanStyles: false,
      style:
        'html { font-size: 8.5pt;}' +
        '@page { margin: 0 !important;}' +
        '.K57 {padding-right: 8px; padding-bottom: 64px;}' +
        '.K57 .header {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .header-header {text-align: center;font-size: 0.9rem;padding: 0.5vw 0;}' +
        '.K57 .header-store-name {text-align: center;font-size: 1.1rem;}' +
        '.K57 .header-store-address, .K57 .header-store-phone,.K57 .header-store-branch{text-align: center;font-size: 1rem;}' +
        '.K57 .content {padding: 0.5vw 0;}' +
        '.K57 .content-header {border-bottom: 1px black dashed;}' +
        '.K57 .content-header-title {text-align: center;font-size: 1.2rem;}' +
        '.K57 .content-header-info {text-align: start;font-size: 1rem;}' +
        '.K57 .content .table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .content .table table {width: 100%;}' +
        '.K57 .content .table table thead th {font-size: 1rem;}' +
        '.K57 .content .table table tbody tr td {font-size: 1rem;}' +
        '.K57 .content .sub-table {border-bottom: 1px black dashed;padding: 0.5vw 0;}' +
        '.K57 .content .sub-table table {width: 100%;}' +
        '.K57 .content .sub-table table tbody tr {padding: 0.5vw 0;}' +
        '.K57 .content .sub-table table tbody tr td {width: 50%;max-width: 50%;font-size: 1rem;text-align: end;}' +
        '.K57 .content .sub-table table tbody tr > .pay {font-size: 1rem;font-weight: bold;}' +
        '.K57 .content .payment {font-size: 1rem;padding: 0.5vw 0;border-bottom: 1px black dashed;}' +
        '.K57 .footer {padding: 0.5vw 0 1vw 0;}' +
        '.K57 .footer-footer {font-size: 0.9rem;text-align: center;padding: 0.5vw 0;}' +
        '.K57 .footer-barcode {text-align: center;}'
    })
  }
  close() {
    this.vhComponent.hideModal()
  }

  renderBarcode(value) {
    JsBarcode("#barcode-delivery-goods", value, {
      format: "CODE128",
      lineColor: "#000",
      height: 40,
      displayValue: false
    });
  }

}
