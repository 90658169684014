<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollX="true">
    <div id="transfer-money-a4" class="A4">
        <div class="header">
            <div class="header-store">
                <div class="header-store-logo">
                    <img *ngIf="  !printer.logo_hidden" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div class="header-store-info">
                    <div class="header-store-info-name">
                        <div *ngIf="!printer.name_store_hidden" class="header-store-info-name-company"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div *ngIf="!printer.name_branch_hidden" class="header-store-info-name-branch" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    </div>
                    <div class="header-store-info-contact">
                        <div *ngIf="!printer.address_hidden" class="header-store-info-contact-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}
                        </div>
                        <div *ngIf="!printer.phone_number_hidden" class="header-store-info-contact-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-title"><b  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">
                    {{"Money transfer receipt"| translate | uppercase}}</b></div>

            <div class="content">
                <div class="content-invoice">
                    <div *ngIf="!printer.bill_code_hidden" class="content-invoice-bill-code" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div  *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" class="content-invoice-date">{{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div class="content-info">
                    <div class="content-info-detail">
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{'To branch' | translate}}: <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['name_branch_reception'] |
                                translate}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Transfer method' | translate}}: <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Cash' | translate}}</b>
                        </div>
                         
                    </div>
                    <div class="content-info-barcode">
                        <div  *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['employee']}}</b></div>
                        <svg  *ngIf="!printer.barcode_hidden" id="barcode-transfer-money"></svg>
                    </div>
                </div>
                <div class="content-note"   >
                    <div class="content-note-label" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                    <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div>
                    <div class="content-note-dot"></div>
                </div>
            </div>

            <div class="signature">
                <div class="signature-detail">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver" | translate}}</b><br><em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div   class="signature-detail"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by' |translate}}</b><br>
                    <em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div class="signature-detail"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br>
                    <em [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
            </div>
        </div>
    </div>
    <div id="electron-transfer-money-a4" class="A4" style="display: none;">
        <div  >
            <div style="display: grid;
            grid-template-columns: 7% 93%;">
                <div style="text-align: center;">
                    <img *ngIf="  !printer.logo_hidden" style="width: 100%;" src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
                </div>
                <div  >
                    <div style="  display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div *ngIf="!printer.name_store_hidden" ><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['company']}}</b></div>
                        <div  *ngIf="!printer.name_branch_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                    </div>
                    <div  style="  display: grid;
                    padding: 2px 16px;
                    grid-template-columns: 70% 30%;">
                        <div  *ngIf="!printer.address_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoStore['address']}}
                        </div>
                        <div  *ngIf="!printer.phone_number_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{"Phone number" | translate}} : {{infoStore['phone']}}
                        </div>
                    </div>
                </div>
            </div>
            <div style=" text-align: center;
            "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size}">
                    {{"Money transfer receipt"| translate | uppercase}}</b></div>

            <div >
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div *ngIf="!printer.bill_code_hidden"  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.bill_code}}</b>
                    </div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{"Date" | translate}} :
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ billInfo.date | date: 'dd/MM/yyyy HH:mm'}}</b>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: 50% 50%;">
                    <div  >
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{"Total amount" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">{{vhAlgorithm.vhcurrencyunit(billInfo.total)}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                            {{'To branch' | translate}}: <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['name_branch_reception'] |
                                translate}}</b>
                        </div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Content" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{billInfo.content}}</b></div>
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Transfer method' | translate}}: <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Cash' | translate}}</b>
                        </div>
                        
                    </div>
                    <div style=" text-align: end;">
                        <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Employee" | translate}} : <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{invoiceInfo['employee']}}</b></div>
                        <svg id="barcode-transfer-money"></svg>
                    </div>
                </div>
                <div style=" display: grid;
                grid-template-columns: max-content auto;"  >
                    <div  style="  align-self: flex-end;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Note" | translate}} :</div>
                    <div style="border-bottom: 1px dotted black;
                    height: 30px;"></div>
                    <div  style="border-bottom: 1px dotted black;
                    height: 30px;"></div>
                    <div   style="border-bottom: 1px dotted black;
                    height: 30px;"></div>
                </div>
            </div>

            <div style="display: flex;
            justify-content: space-between;
            padding: 16px;">
                <div style=" text-align: center;">
                    <b   [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Receiver" | translate}}</b><br><em   [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div   style=" text-align: center;"><b   [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Prepared by' |translate}}</b><br>
                    <em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
                <div  style=" text-align: center;"><b   [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'cashier'|translate}}</b><br>
                    <em  [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">({{'Sign, write full name'|translate}})</em>
                </div>
            </div>
        </div>
    </div>

</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="checkPrint()" shape="round">{{'Print' | translate}}</ion-button>
    </ion-toolbar>
</ion-footer>