import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { VhAlgorithm, VhQuerySales } from 'ionic-vhframeworks';
import { VhComponent } from 'src/app/components/vh-component/vh-component';

import { LanguageService } from 'src/app/services/language.service';
import jsbarcode from 'jsbarcode';
import printJS from 'print-js';
import { FunctionService } from 'src/app/services/function.service';
declare var electron: any;
@Component({
  selector: 'app-inventory-a45',
  templateUrl: './inventory-a45.component.html',
  styleUrls: ['./inventory-a45.component.scss']
})
export class InventoryA45Component implements OnInit {

  @Input() billInfo: any;
  @Input() billDetails: any;
  @Input() printer: any;
  infoStore: any = {}
  title: string = ''
  useBranch: boolean = this.fncService.checkUsingBranch()
  infoBranch: any = {}
  constructor(
    private vhQuerySales: VhQuerySales,
    public platform: Platform,

    private lang: LanguageService,
    public vhAlgorithm: VhAlgorithm,
    public vhComponent: VhComponent,
    private fncService: FunctionService
  ) { }
  ngOnInit(): void {
    this.infoStore = this.vhQuerySales.getLocalAppSettingNameBranch('store_info');
    this.infoBranch = this.vhQuerySales.getlocalBranch(this.vhQuerySales.getDefaultBranch()._id);
  }
  close() {
    this.vhComponent.hideModal();
  }
  ngAfterViewInit(): void {
    if(!this.printer.barcode_hidden)  jsbarcode("#barcode-inventory-a4", this.billInfo['bill_code'], {
      displayValue: false,
      height: 30
    });
  }
  checkPrint() {
    if (this.platform.is("electron")) this.printElectron()
    else this.printBrowser()
  }
  printElectron() {
    let html = document.getElementById("electron-inventory-a4").innerHTML;
    electron.ipcRenderer.send("print-start", {
      html: html,
      deviceName: this.printer.printer_name,
      a4:true,
      copies:this.printer.copies
    });
    electron.ipcRenderer.on("print-done", (event, data) => {
      if (!data) this.vhComponent.alertMessageDesktop('warning', this.lang.translate('Printing failed'), 3000)
      else this.close()
    });
  }

  printBrowser() {
    printJS({
      printable: "inventory-a4",
      type: "html",
      scanStyles: false,
      style:
        '.A4 {text-align: justify;}' +
        '.A4 p {margin: 0;font-size: 1.8vw;}' +
        '.A4 .title {text-align: center; font-size: 2.5vw;}' +
        '.A4 th,td {border: 1px solid gray;padding: 2px;font-size: 1.8vw; border-bottom:0;padding: 0.05vw 16px;}' +
        '.A4 .bill-body-sub{margin:10px 0;padding:5px 0;font-size: 1.8vw;}' +
        '.A4 .footer { font-size: 1.8vw; text-align:center;}' +
        '.A4 h3 {font-size: 2.5vw; font-weight:bold; margin:10px 0}' +
        '.A4 i {font-size: 1.8vw;}'
    });
  }

}
