<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button mode="md" color="vh-black" (click)="close()" fill="clear">
                    <ion-icon slot="icon-only" color="vh-red" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div id="sales-voucher-k80-col4" class="K80">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div class="header" *ngIf="infoStore">
            <div class="header-header" *ngIf="!printer.header_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div class="header-store">
                <div class="header-store-name" *ngIf="!printer.name_store_hidden"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" class="header-store-branch" style="text-align: center;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}
                </div>
                <div *ngIf="!printer.address_hidden" class="header-store-address" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" class="header-store-phone" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div class="content">
            <div class="content-header" style="padding: 4px 0;">
                <div class="content-header-title"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Hóa đơn bán voucher'
                      | translate) | uppercase}}</b></div>
                <div class="content-header-info">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}
                    </div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Date" | translate}}</b>: {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman" | translate}}</b>: {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div class="table">
                <table>
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style=" text-align: start">{{'Tên voucher'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: center;">{{'Mã Voucher'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: right;">{{'Đơn giá '|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: right;">{{'Tổng'|translate}}</th>
                    </thead>

                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 35%; max-width: 35% ; white-space: normal">{{item.name}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 5%; max-width: 5%; text-align: center;">{{item.code}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 30%; max-width: 30%; text-align: end;"> {{vhAlgorithm.vhcurrencyunit(item.price )}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 30%; max-width: 30%;text-align: end;">
                                    {{vhAlgorithm.vhcurrencyunit(item.price )}}
                                </td>
                            </tr>
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 100% ; white-space: normal" colspan="4">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                    {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                            </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="sub-table">
                <table>
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Giảm giá' | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{vhAlgorithm.vhcurrencyunit(invoice.discount)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit( invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'Total'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td class="pay" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.rest_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'REST'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'cash'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{'Change'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash - invoice.payment : 0)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="payment">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"> <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}} {{invoice.retail_name ?'-':''}}
                    {{invoice.retail_name}}

                    <ng-container *ngIf="!printer.customer_address_hidden && renderAddress(invoiceInfo['partner']) || invoice.retail_address">
                        <br> {{ "Address" | translate }} :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                      invoice.retail_address
                      }}</span>
                    </ng-container>


                    <ng-container *ngIf="!printer.customer_phone_hidden &&  invoiceInfo['partner'].phone || invoice.retail_phone">
                        <br /> {{ "Phone number" | translate }} :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                      }}{{invoice.retail_phone}}</span>
                    </ng-container>
                </div>
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div class="payment-info" *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}}
                    </div>
                </div>
            </div>
            <div class="payment" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>: {{invoice.note}}
                </div>
            </div>
            <div style="font-size: 12pt;padding: 4px 0;text-align: start;">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div class="footer">
            <div *ngIf="!printer.footer_hidden" class="footer-footer" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" class="footer-barcode">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
    </div>
    <div id="electron-sales-voucher-k80-col4" style="display: none;">
        <div *ngIf="!printer.logo_hidden" style="display:flex; justify-content: center; height:70pt">
            <img height='100%' src="{{infoStore.img ? infoStore.img : '/assets/icon/management/noimage.svg'}}" alt="Logo">
        </div>
        <div style=" border-bottom: 1px black dashed;
      padding: 4px 0;" *ngIf="infoStore">
            <div *ngIf="!printer.header_hidden" style="text-align: center;

          padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.header}}</div>
            <div>
                <div *ngIf="!printer.name_store_hidden" style="  text-align: center;
              "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{infoStore.company}}</b></div>
                <div *ngIf="!printer.name_branch_hidden" style="text-align: center;
              " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{infoBranch['name']}}</div>
                <div *ngIf="!printer.address_hidden" style="text-align: center;
              " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    {{infoStore['address']}}</div>
                <div *ngIf="!printer.phone_number_hidden" style="text-align: center;
              " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Phone number" | translate}} : {{infoStore['phone']}}
                </div>
                <div *ngIf="!printer.tax_code_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    MST: {{infoStore['tax_number']}}</div>
                <div *ngIf="!printer.email_hidden" style="text-align: center; " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Email" |
                    translate}} : {{infoStore['email']}}
                </div>
            </div>
        </div>

        <div>
            <div style="border-bottom: 1px black dashed; padding: 4px 0;">
                <div style="text-align: center;
              "><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.9}">{{('Hóa đơn bán voucher'
                      | translate) | uppercase}}</b></div>
                <div style="text-align: start;
              " [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <div *ngIf="!printer.bill_code_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Invoice code" | translate}}</b>: {{invoice.bill_code}}
                    </div>
                    <div *ngIf="!printer.date_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b>{{"Date" | translate}}</b>: {{invoice.date | date: "dd/MM/yyyy HH:mm"}}
                    </div>
                    <div *ngIf="!printer.employee_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Salesman" | translate}}</b>: {{invoiceInfo['employee'] ? invoiceInfo['employee'].name : ''}}
                    </div>
                </div>
            </div>

            <div style=" border-bottom: 1px black dashed;
          padding: 4px 0;">
                <table style="width: 100%;">
                    <thead>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style=" text-align: start">{{'Tên voucher'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: center;">{{'Mã Voucher'|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: right;">{{'Đơn giá '|translate}}</th>
                        <th [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.75}" style="text-align: right;">{{'Tổng'|translate}}</th>
                    </thead>

                    <tbody>
                        <ng-container *ngFor="let item of invoice_detail">
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 35%; max-width: 35% ; white-space: normal">{{item.name}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 5%; max-width: 5%; text-align: center;">{{item.code}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 30%; max-width: 30%; text-align: end;"> {{vhAlgorithm.vhcurrencyunit(item.price )}}</td>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt': printer.font_size*0.675}" style="width: 30%; max-width: 30%;text-align: end;">
                                    {{vhAlgorithm.vhcurrencyunit(item.price )}}
                                </td>
                            </tr>
                            <tr>
                                <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="width: 100% ; white-space: normal" colspan="4">
                                    <ng-container *ngIf="item.products">
                                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}" *ngFor="let product of item.products">
                                    {{ product.name}} {{product.unit ? '('+ product.unit +')':''}} x {{product.quantity}} <br>
                            </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div style="border-bottom: 1px black dashed;
          padding: 4px 0;">
                <table style="width: 100%;">
                    <tbody>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'SubTotal' | translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(subTotal)}}</td>
                        </tr>
                        <tr *ngIf="!printer.discount_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Giảm giá' | translate}} ({{(invoice.discount/subTotal)*100 | number:'1.0-2'}}%)</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{vhAlgorithm.vhcurrencyunit(invoice.discount)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.fee_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">{{'Fee'|translate}}</td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.fee)}}</td>
                        </tr>
                        <tr *ngIf="!printer.tax_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'Tax'|translate}} ({{vhAlgorithm.vhpercent(invoice.tax/100)}})
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit( invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}" style="text-align: end;font-weight: bold;">
                                {{'Total'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100))}}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{'PAYMENT'|translate}}
                            </td>
                            <td style="text-align: end;font-weight: bold;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.75}">
                                {{vhAlgorithm.vhcurrencyunit(invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.rest_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'REST'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.total + invoice.total*( invoice.tax/100) - invoice.payment)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'cash'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash)}}
                            </td>
                        </tr>
                        <tr *ngIf="!printer.cash_hidden">
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{'Change'|translate}}
                            </td>
                            <td [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}" style="text-align: end;">
                                {{vhAlgorithm.vhcurrencyunit(invoice.cash - invoice.payment > 0 ? this.invoice.cash - invoice.payment : 0)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="
          text-align: start;
          padding: 4px 0;
          border-bottom: 1px black dashed;">
                <!--KHÁCH HÀNG NHÀ CUNG CẤP-->
                <div *ngIf="!printer.customer_name_hidden" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Customer name'|translate}}</b>: {{(invoiceInfo["partner"] ? invoiceInfo["partner"].name : '') | translate}} {{invoice.retail_name ?'-':''}}
                    {{invoice.retail_name}}

                    <ng-container *ngIf="!printer.customer_address_hidden && renderAddress(invoiceInfo['partner']) || invoice.retail_address">
                        <br> <b>{{ "Address" | translate }}</b> :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ renderAddress(invoiceInfo["partner"]) ||
                      invoice.retail_address
                      }}</span>
                    </ng-container>


                    <ng-container *ngIf="!printer.customer_phone_hidden &&  invoiceInfo['partner'].phone || invoice.retail_phone">
                        <br /> <b>{{ "Phone number" | translate }}</b> :
                        <span [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{ invoiceInfo["partner"].phone
                      }}{{invoice.retail_phone}}</span>
                    </ng-container>
                </div>

                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}"><b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Payment method' | translate}}</b>: {{invoiceInfo['paymentName'] | translate}} </div>
                <div *ngIf="invoice.payment_type == 3">
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account name" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].name: ''}}
                    </div>
                    <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                        <b [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{"Account number" | translate}}</b>: {{invoiceInfo['wallet'] ? invoiceInfo['wallet'].number: ''}}
                    </div>
                </div>
            </div>
            <div style="
          text-align: start;
          padding: 4px 0;
          border-bottom: 1px black dashed;" *ngIf="!printer.note_hidden">
                <!--GHI chú-->
                <div [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">
                    <i [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{'Note'|translate}}</i>: {{invoice.note}}
                </div>
            </div>
            <div style="
          text-align: start;
          padding: 4px 0;" *ngIf="printer.notice">
                <pre [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.675}">{{printer.notice}}</pre>
            </div>
        </div>
        <div style=" padding: 4px 0 8px 0;">
            <div *ngIf="!printer.footer_hidden" style="
          text-align: center;
          padding: 4px 0;" [ngStyle]="{'font-family': printer.font_family,'font-size.pt' : printer.font_size*0.5}">{{printer.footer}}</div>
            <div *ngIf="!printer.barcode_hidden" style="text-align: center;">
                <svg id="barcode-sales"></svg>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer mode="ios" color="vh-white" class="ion-no-border">
    <ion-toolbar mode="ios">
        <ion-button style="--background: var(--ion-color-vh-green); --color: var(--ion-color-vh-white);" mode="md" expand="full" fill="solid" (click)="Print()" shape="round">{{'Print' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
