import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core'
import { IonicModule } from '@ionic/angular';
import { SearchLangComponent } from './search-lang/search-lang.component';
import { FormsModule } from '@angular/forms';
import { SearchPhoneCodeComponent } from './search-phone-code/search-phone-code.component';
import { SearchProductComponent } from './search-product/search-product.component';
import { NgZorroAntModule } from 'src/app/ng-zorro-ant.module';
import { SearchProductImportComponent } from './search-product-import/search-product-import.component';
import { SearchProductWarrantyInvoiceComponent } from './search-product-warranty-invoice/search-product-warranty-invoice.component';
import { SearchGoodsInventoryComponent } from './search-goods-inventory/search-goods-inventory.component';



@NgModule({
  declarations: [
    SearchLangComponent,
    SearchPhoneCodeComponent,
    SearchProductComponent,
    SearchProductImportComponent,
    SearchProductWarrantyInvoiceComponent,
    SearchGoodsInventoryComponent
  ],
  entryComponents: [
    SearchLangComponent,
    SearchPhoneCodeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    FormsModule,
    NgZorroAntModule,
    ScrollingModule
  ],
  exports :[SearchProductComponent,SearchProductImportComponent, SearchProductWarrantyInvoiceComponent, SearchGoodsInventoryComponent]
})
export class SearchModule { }
