<nz-drawer [nzMaskClosable]="true" [nzWidth]="500" [nzTitle]="null" [nzVisible]="searchProductList"
  (nzOnClose)="goBack()">
  <ng-container *nzDrawerContent>
    <div nz-row>
      <div nz-col nzSpan="24"><input class="nz-hover-border"  
          placeholder="{{ 'Search for product name, barcode' | translate}}" [(ngModel)]="search"
          (ngModelChange)="searchProduct($event)" [ngModelOptions]="{standalone: true}" nz-input />
      </div>
    </div>
    <div nz-row style="padding-top: 8px;">
      <div nz-col nzSpan="24">
        <cdk-virtual-scroll-viewport itemSize="67.2" class="infinite-container">
          <nz-list>
            <nz-list-item   *cdkVirtualFor="let item of searchList"
              style="cursor: pointer;">
              <nz-list-item-meta nzAvatar="{{item.img ? item.img : 'assets/icon/management/noimage.svg'}}">
                <nz-list-item-meta-title (click)="chooseProduct(item)">
                  <span style="font-size: 0.9rem; color: var(--ion-color-vh-black)">
                    {{item.name}}
                  </span>
                  <div>{{'Purchase price' | translate}}:
                    {{vhAlgorithm.vhcurrencyunit_symbol(item.price_import)}}
                  </div>
                  <div>
                    {{'Stock' | translate}}:
                    <span style=" color: var(--ion-color-vh-orange)">{{item.quantity_branch ? item.quantity_branch[id_branch]/item.ratio : 0 | number:'1.0-2'}} ({{item.unit}})</span>
                  </div>
                </nz-list-item-meta-title> 
              </nz-list-item-meta>
            </nz-list-item>
            <nz-list-empty *ngIf="searchList.length === 0"></nz-list-empty>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </ng-container>
</nz-drawer>