<div *ngIf="stateObj" nz-row class="row-back medium-size" (click)="goBack()">
    <i nz-icon nzType="left" nzTheme="outline"></i>{{'Back' | translate}}
</div>
<nz-layout>
    <nz-content>
        <div nz-row class="main" style="height: 100%">
            <div nz-col nzSpan="21" class="title left-all-content">
                <b>{{'Add product' | translate}}</b>
            </div>
            <div nz-col nzSpan="3" class="add-button">
                <button class="btn-add center-all-content" nz-button nzType="default" (click)="saveProduct()"
                    [disabled]="productForm.invalid" [ngStyle]="{opacity: productForm.invalid ? 0.5 : 1}">
                    <i nz-icon nzType="save" nzTheme="outline"></i>
                    {{'Save' | translate}}</button>
            </div>
            <div style="overflow-x: hidden; overflow-y: auto; margin: 8px 0;height: 92%;width: 100%;"
                class="custom-scroll-bar">
                <form nz-form [formGroup]="productForm">
                    <div class="grid-product">
                        <div nz-col nzSpan="24" class="center-all-content">
                            <div class="bg-img center-all-content" (click)="getFile()" style="cursor: pointer;">
                                <input style="display: none;" id="file-upload" type="file" accept=".png, .jpg"
                                    (change)="onUpload($event)" />
                                <img [ngStyle]="{width: productForm.value.img ? '100%' : '60px'}"
                                    onerror="this.src='./assets/icon/management/image_default.svg'"
                                    [src]="productForm.value.img" />
                            </div>
                        </div>
                        <div>
                            <div class="small-size">
                                <div><b>{{'Product name' | translate}}*</b></div>
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="combineTplName">
                                        <nz-input-group>
                                            <input style="width:95%" formControlName="name" nz-input
                                                placeholder="{{'Enter product name' | translate}}" required />
                                            <ng-template #combineTplName let-control>
                                                <ng-container *ngIf="control.hasError('required')">{{'Product name is
                                                    required' | translate}}
                                                </ng-container>
                                            </ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="small-size" nz-row>
                                <div nz-col nzSpan="8">
                                    <div><b class='label'>{{'Category'| translate}}*</b></div>
                                    <nz-form-control nzErrorTip="{{'Please select category'|translate}}">
                                        <nz-select nzShowSearch nzAllowClear formControlName="id_category"
                                            nzPlaceHolder="{{'Please select category' |translate}}">
                                            <nz-option *ngFor="let option of list_category" nzLabel="{{option.name}}"
                                                nzValue="{{option._id}}">
                                            </nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </div>
                                <div nz-col nzSpan="8">
                                    <div><b class='label'>{{'Brand' | translate}}</b></div>
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input formControlName="brand" nz-input
                                                    placeholder="{{'Enter brand' |translate}}" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col nzSpan="8" >
                                    <div><b class='label'>{{'Vị trí để hàng' | translate}}</b></div>
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input  style="width:85%" formControlName="position" type="text" nz-input 
                                                    placeholder="{{'VD: Khu A, kệ số 4 tầng 2' |translate}}" /> 
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-row class="small-size" *ngIf="management_product_manysize">
                                <div nz-col nzSpan="12">
                                    <label nz-checkbox formControlName="manysize" (ngModelChange)="statusCheckBox($event)">
                                        {{'Phân theo thuộc tính hàng hóa' | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div nz-row>
                        <div nz-col nzSpan="12" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Purchase price' | translate}}*</b></div>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="combineTplPriceImport">
                                    <nz-input-group>
                                        <input (keyup)="tranformPrice('price_import')" class="price_import" type="text"
                                            maxlength="15" formControlName="price_import" nz-input
                                            placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}" />
                                        <ng-template #combineTplPriceImport let-control>
                                            <ng-container *ngIf="control.hasError('required')">{{'Cost is required' |
                                                translate}}
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' |
                                                translate}}
                                            </ng-container>
                                        </ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="11" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Unit' | translate}}*</b></div>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="combineTplUnit">
                                    <nz-input-group style="display:flex;justify-content: space-between;">
                                        <input formControlName="unit" nz-input style="width:65%;"
                                            placeholder="{{'Enter unit'|translate}}" />
                                        <div class="  center-all-content"
                                            (click)="changeUnits()" style="margin-right: 8px;border-radius : 5px;border: 1px solid #c9c9c9;width:30%;cursor: pointer;">
                                            <i nz-icon nzType="ordered-list" nzTheme="outline"  style="margin-right: 4px;"></i>
                                            {{dataUnits.length ?'Danh sách đơn vị':'Thêm đơn vị'| translate}}</div>
                                        <!-- <i style="padding: 8px 0 0 8px; cursor: pointer" nz-icon
                                            [nzType]="dataUnits.length ? 'unordered-list':'plus'" nzTheme="outline"
                                            (click)="changeUnits()" nz-tooltip
                                            [nzTooltipTitle]="dataUnits.length ?'Xem đơn vị quy đổi':'Thêm đơn vị quy đổi'"
                                            nzTooltipPlacement="bottom"></i> -->
                                        <ng-template #combineTplUnit let-control>
                                            <ng-container *ngIf="control.hasError('required')">{{'Đơn vị là bắt
                                                buộc'|translate}}
                                            </ng-container>
                                        </ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Wholesale price' | translate}}</b>*</div>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="combineTplPrice2">
                                    <nz-input-group>
                                        <input (keyup)="tranformPrice('price2')" class="price2" type="text"
                                            maxlength="15" formControlName="price2" nz-input
                                            placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}"
                                            pattern="(^[0?=[.]] |^[^.,-][,0-9]*)(([.](?=[0-9]){1}[0-9]{0,5})|)" />
                                        <ng-template #combineTplPrice2 let-control>
                                            <ng-container *ngIf="control.hasError('required')">{{'Price is required' |
                                                translate}}
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' |
                                                translate}}
                                            </ng-container>
                                        </ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Barcode' | translate}}</b></div>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group>
                                        <input #box formControlName="barcode" nz-input style="width: 86%;"
                                            placeholder="{{'Enter manually or use the barcode scanner' | translate}}"
                                            (blur)="checkBarcode(box.value)" />
                                        <i style="padding: 8px 0 0 8px;" nz-icon nzType="reload" nzTheme="outline"
                                            (click)="changeBarcode()" nz-tooltip
                                            nzTooltipTitle="{{'Generate barcodes automatically' | translate}}"
                                            nzTooltipPlacement="bottom"></i>
                                    </nz-input-group>
                                    <div class="validation-errors">
                                        <ng-container>
                                            <div class="error-message" *ngIf="!barcode">
                                                <ion-text color="vh-red">{{'Barcode already exists'|translate}}
                                                </ion-text>
                                            </div>
                                        </ng-container>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Retail price' | translate}}*</b></div>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="combineTplPrice">
                                    <nz-input-group>
                                        <input (keyup)="tranformPrice('retail_price')" class="retail_price" type="text" maxlength="15"
                                            formControlName="price" nz-input
                                            placeholder="{{vhAlgorithm.vhcurrencyunit_symbol(0)}}" />
                                        <ng-template #combineTplPrice let-control>
                                            <ng-container *ngIf="control.hasError('required')">{{'Price is required' |
                                                translate}}
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' |
                                                translate}}
                                            </ng-container>
                                        </ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12" *ngIf="!productForm.value.manysize">
                            <div><b class='label'>{{'Minimum quantity to alert' | translate}}*</b></div>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="combineTplWarning_number">
                                    <nz-input-group>
                                        <input formControlName="warning_number" type="number" nz-input required
                                            placeholder="{{'Nhập số lượng cảnh báo' |translate}}" />
                                        <ng-template #combineTplWarning_number let-control>
                                            <ng-container *ngIf="control.hasError('required')">{{'Giá trị không hợp
                                                lệ'|translate}}
                                            </ng-container>
                                        </ng-template>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                       

                        <div nz-col nzSpan="24" style="display: flex; align-items: center;" *ngIf="!productForm.value.manysize" >
                            <div nz-col nzSpan="24" flex>
                                <label nz-checkbox
                                    formControlName="allow_sell">{{'Available to sell'|translate}}</label>
                                <label nz-checkbox
                                    formControlName="selling">{{'Quick sale' | translate}}</label>
                            </div>
                        </div>
                        <ng-container *ngIf="management_product_lot">
                            <div nz-col nzSpan="24" style="display: flex; align-items: center;" *ngIf="!productForm.value.manysize">
                                <div nz-col nzSpan="24" flex>
                                    <label  nz-checkbox formControlName="manylot"
                                        (ngModelChange)="changeManyLot($event)">{{'Lô, hạn sử dụng' |
                                        translate}}</label>
                                </div>
                            </div>
                            <div nz-col nzSpan="12" *ngIf="productForm.value.manylot">
                                <div><b class='label'>{{'Số ngày HSD còn lại được phép nhập hàng' | translate}}*</b></div>
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="combineTplPrice3">
                                        <nz-input-group>
                                            <input required type="number" maxlength="15"
                                                formControlName="days_import_warning" nz-input placeholder="0" />
                                            <ng-template #combineTplPrice3 let-control>
                                                <ng-container *ngIf="control.hasError('required')">{{'Trường này là bắt
                                                    buộc' | translate}}
                                                </ng-container>
                                            </ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzSpan="12" *ngIf="productForm.value.manylot">
                                <div><b class='label'>{{'Số ngày HSD còn lại được cảnh báo' | translate}}*</b></div>
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="combineTplPrice4">
                                        <nz-input-group>
                                            <input required type="number" maxlength="15" formControlName="days_exp_warning"
                                                nz-input placeholder="0" />
                                            <ng-template #combineTplPrice4 let-control>
                                                <ng-container *ngIf="control.hasError('required')">{{'Trường này là bắt
                                                    buộc' | translate}}
                                                </ng-container>
                                            </ng-template>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzSpan="12" *ngIf="productForm.value.manylot">
                                <button class="  center-all-content" nz-button nzType="default" (click)="openListLot()">
                                    <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
                                    {{'Danh sách lô' | translate}}</button>
                            </div>
                        </ng-container>


                    </div>

                </form>
                <div nz-row style="margin-bottom: 12px;" *ngIf="productForm.value.manysize">
                    <div class="title-sub" nz-col nzSpan="24">
                        <div class="large-size hide-overflow"><b>{{'Phân loại hàng hóa' | translate}}</b></div>
                        <div>
                            <button class="btn-add" nz-button nzType="default" (click)="showSubProduct()"
                                [disabled]="!productForm.value.manysize"
                                [ngStyle]="{opacity: (!productForm.value.manysize) ? 0.5 : 1}">
                                <i nz-icon nzType="plus" nzTheme="outline"></i>
                                {{'Add'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <nz-table #basicTable [nzData]="subProducts" *ngIf="productForm.value.manysize" [nzNoResult]="null">
                    <thead>
                        <tr>
                            <th>{{'Tên thuộc tính' | translate}}</th>
                            <th nzAlign="right">{{'Purchase price' | translate}}</th>
                            <th nzAlign="right">{{'Wholesale price' | translate}}</th>
                            <th nzAlign="right">{{'Retail price' | translate}}</th>
                            <th>{{'Status' | translate}}</th>
                            <th>{{'Lô hàng' | translate}}</th>
                            <th>{{'Functions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of subProducts">
                            <td nzAlign="center">{{ data.name }}</td>
                            <td nzAlign="right">{{vhAlgorithm.vhcurrencyunit(data.price_import)}}</td>
                            <td nzAlign="right">{{vhAlgorithm.vhcurrencyunit(data.price2) }}</td>
                            <td nzAlign="right">{{vhAlgorithm.vhcurrencyunit(data.price) }}</td>
                            <td>{{ (data.allow_sell ? 'Allow sale' : 'Not allow sale') | translate }}</td>
                            <td>
                                <button *ngIf="data.manylot" class="btn-add center-all-content" nz-button
                                    nzType="default" (click)="openListLot(data.lots)">
                                    <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
                                    {{'Danh sách lô' | translate}}</button>
                            </td>
                            <td style="    display: flex; justify-content: space-between; font-size: 1.2rem;">
                                <a (click)="showSubProduct(data)">
                                    <i style="color: var(--ion-color-vh-green)" class="cus-icon" nz-icon nzType="form"
                                        nzTheme="outline" nz-tooltip nzTooltipTitle="{{'Edit' | translate}}"></i>
                                </a>
                                <!-- <a (click)="duplicateSubProduct(data)">
                                    <i style="color: var(--ion-color-vh-blue)" class="cus-icon" nz-icon
                                        nzType="snippets" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="{{'Tạo bản sao' | translate}}"  ></i>
                                </a> -->
                                <a nz-popconfirm [nzPopconfirmTitle]="('Delete ' | translate) + data.name"
                                    nzCancelText="{{'Cancel' | translate}}" (nzOnConfirm)="deleteSize(data)">
                                    <i style="color: var(--ion-color-vh-red)" class="cus-icon" nz-icon nzType="delete"
                                        nzTheme="outline" nz-tooltip nzTooltipTitle="{{'Delete' | translate}}"></i>
                                </a>
                            </td>

                        </tr>
                    </tbody>
                </nz-table>
                <div *ngIf="!management_product_manysize" (click)="gotoConfigProduct()" style="color:var(--ion-color-vh-green);margin-top:32px;cursor:pointer;">
                    + Mở chức năng tạo sản phẩm có nhiều thuộc tính
                </div>
                <div  *ngIf="!management_product_lot" (click)="gotoConfigProduct()"  style="color:var(--ion-color-vh-green);cursor:pointer;">
                    + Mở chức năng quản lý sản phẩm theo lô, theo hạn sử dụng
                </div>
            </div>
        </div>

        <!-- <div class="sub-content" *ngIf="productForm.value.manysize">
            <div class="many-size">
                <div nz-row style="margin-bottom: 12px;">
                    <div class="title-sub" nz-col nzSpan="24">
                        <div class="large-size hide-overflow"><b>{{'Add size' | translate}}</b></div>
                        <div>
                            <button class="btn-add" nz-button nzType="default" (click)="showSubProduct()"
                                [disabled]="!productForm.value.manysize"
                                [ngStyle]="{opacity: (!productForm.value.manysize) ? 0.5 : 1}">
                                <i nz-icon nzType="plus" nzTheme="outline"></i>
                                {{'Add'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div nz-row class="content-size ant-table-body">
                    <div nz-col nzSpan="24">
                        <nz-list>
                            <ng-container *ngFor="let data of subProducts">
                                <nz-list-item>
                                    <nz-list-item-meta-title style="width:calc(100% - 110px)">
                                        <b>{{data.name}}</b>
                                        <div class="quantity">
                                            <span>{{this.vhAlgorithm.vhcurrencyunit(data.price)}}</span>
                                        </div>
                                    </nz-list-item-meta-title>
                                    <ul nz-list-item-actions>
                                        <nz-list-item-action>
                                            <a (click)="showSubProduct(data)">
                                                <i style="color: var(--ion-color-vh-black)" class="cus-icon" nz-icon
                                                    nzType="form" nzTheme="outline"></i>
                                            </a>
                                        </nz-list-item-action>
                                        <nz-list-item-action>
                                            <a nz-popconfirm [nzPopconfirmTitle]="('Delete ' | translate) + data.name"
                                                nzCancelText="{{'Cancel' | translate}}"
                                                (nzOnConfirm)="deleteSize(data)">
                                                <i style="color: var(--ion-color-vh-black)" class="cus-icon" nz-icon
                                                    nzType="delete" nzTheme="outline"></i>
                                            </a>
                                        </nz-list-item-action>
                                    </ul>

                                </nz-list-item>
                            </ng-container>
                        </nz-list>
                        <div class="no-data" *ngIf="!productForm.value.manysize">{{'Not sub yet'| translate}}</div>
                    </div>
                </div>
            </div>
        </div> -->
    </nz-content>
</nz-layout>

<!--Modal add name, price size-->
<nz-modal [nzFooter]="modalFooterAddUnitPrice" [(nzVisible)]="isVisibleAddSize" [nzTitle]="titleSubProduct"
    (nzOnCancel)="handleCancelAdd()" [nzStyle]="{ top: '20px' }">
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="formSubProduct" style="overflow: auto; height: 450px;padding-bottom: 16px;"
            >
            <div nz-row>
                <div nz-col [nzSpan]="24"  style="padding-right: 8px;">
                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Tên thuộc tính (màu sắc, kích cỡ,...)' | translate}}*</b></nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorSubsName">
                            <input nz-input formControlName="name" placeholder="{{'Enter name' | translate}}" />
                            <ng-template #errorSubsName let-control>
                                <ng-container *ngIf="control.hasError('required')">{{'Vui lòng nhập tên' | translate}}
                                </ng-container>
                                <ng-container *ngIf="control.hasError('pattern')">{{'Invalid name' | translate}}
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Barcode' | translate}}</b></nz-form-label>
                        <nz-form-control [nzSpan]="20">
                            <input formControlName="barcode"
                                (blur)="checkBarcodeSubProduct(barcodesize.value,formSubProduct.value.name)"
                                #barcodesize maxlength="15" nz-input placeholder="{{'Barcode' | translate}}" />
                        </nz-form-control>
                        <div nz-col [nzSpan]="4">
                            <i style="margin: 8px 0 0 8px;" nz-icon nzType="reload" nzTheme="outline"
                                (click)="changeBarcodeSubProduct()" nz-tooltip
                                nzTooltipTitle="{{'Generate barcodes automatically' | translate}}"
                                nzTooltipPlacement="bottom"></i>
                        </div>
                        <div class="validation-errors" nz-col  [nzSpan]="24">
                            <ng-container>
                                <div class="error-message" *ngIf="!barcode">
                                    <ion-text color="vh-red">{{'Barcode already exists'|translate}}
                                    </ion-text>
                                </div>
                            </ng-container>
                        </div>
                    </nz-form-item>
                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Purchase price' | translate}}*</b></nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorSubsPriceImport">
                            <input nz-input class="size-price-purchase-item"
                                (keyup)="tranformPrice('size-price-purchase-item')" type="text"
                                formControlName="price_import" placeholder="{{'Enter price' | translate}}" />
                            <ng-template #errorSubsPriceImport let-control>
                                <ng-container *ngIf="control.hasError('required')">{{'Cost is required' | translate}}
                                </ng-container>
                                <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' | translate}}
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Wholesale price' | translate}}*</b></nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorSubsPrice2">
                            <input nz-input class="size-price2-item" (keyup)="tranformPrice('size-price2-item')"
                                type="text" formControlName="price2" placeholder="{{'Enter price' | translate}}" />
                            <ng-template #errorSubsPrice2 let-control>
                                <ng-container *ngIf="control.hasError('required')">{{'Price is required' | translate}}
                                </ng-container>
                                <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' | translate}}
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Retail price' | translate}}*</b></nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorSubsPrice">
                            <input nz-input class="size-price-item" (keyup)="tranformPrice('size-price-item')"
                                type="text" formControlName="price" placeholder="{{'Enter price' | translate}}" />
                            <ng-template #errorSubsPrice let-control>
                                <ng-container *ngIf="control.hasError('required')">{{'Price is required' | translate}}
                                </ng-container>
                                <ng-container *ngIf="control.hasError('pattern')">{{'Invalid price' | translate}}
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="margin:0 0 4px;width: 100%;">
                        <nz-form-label [nzSpan]="24"><b>{{'Unit' | translate}}*</b></nz-form-label>
                        <nz-form-control [nzErrorTip]="combineTplUnit2">
                            <nz-input-group style="display:flex;justify-content: space-between;">
                                <input style="width: 60%;" formControlName="unit" nz-input
                                    placeholder="{{'Enter unit'|translate}}" />
                                <div class="btn-add center-all-content" style="margin-right: 8px; border-radius: 5px; border: 1px solid #c9c9c9; width:30%; cursor: pointer; width:35%;"
                                    (click)="changeUnitsSubs()">
                                    <i nz-icon nzType="ordered-list" nzTheme="outline" style="margin-right: 4px;"></i>
                                    {{dataUnits.length ?'Danh sách đơn vị':'Thêm đơn vị'| translate}}</div>

                                <ng-template #combineTplUnit2 let-control>
                                    <ng-container *ngIf="control.hasError('required')">{{'Đơn vị là bắt
                                        buộc'|translate}}
                                    </ng-container>
                                </ng-template>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item style="margin:0 0 4px;">
                        <nz-form-label [nzSpan]="24"><b>{{'Minimum quantity to alert' | translate}}*</b>
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorSubsWarning_number">
                            <input nz-input type="number" formControlName="warning_number"
                                placeholder="{{'Enter number' | translate}}" />
                            <ng-template #errorSubsWarning_number let-control>
                                <ng-container *ngIf="control.hasError('pattern')">{{'Giá trị không hợp
                                    lệ'|translate}}
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <div nz-col nzSpan="24" flex>
                        <label nz-checkbox formControlName="allow_sell">{{'Available to sell'|translate}}</label>
                        <label nz-checkbox formControlName="selling">{{'Quick sale' | translate}}</label>
                    </div>

                    <div nz-col nzSpan="24" flex *ngIf="management_product_lot">
                        <label nz-checkbox formControlName="manylot" (ngModelChange)="changeManyLotSub($event)">{{'Lô, hạn sử dụng' |
                            translate}}</label>

                    </div>
                    <div nz-col nzSpan="24" *ngIf="formSubProduct.value.manylot">
                        <div><b class='label'>{{'Số ngày HSD còn lại được phép nhập hàng' | translate}}*</b></div>
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="combineTplPrice3">
                                <nz-input-group>
                                    <input required type="number" maxlength="15" formControlName="days_import_warning"
                                        nz-input placeholder="0" />
                                    <ng-template #combineTplPrice3 let-control>
                                        <ng-container *ngIf="control.hasError('required')">{{'Trường này là bắt
                                            buộc' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24" *ngIf="formSubProduct.value.manylot">
                        <div><b class='label'>{{'Số ngày HSD còn lại được cảnh báo' | translate}}*</b></div>
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="combineTplPrice4">
                                <nz-input-group>
                                    <input required type="number" maxlength="15" formControlName="days_exp_warning"
                                        nz-input placeholder="0" />
                                    <ng-template #combineTplPrice4 let-control>
                                        <ng-container *ngIf="control.hasError('required')">{{'Trường này là bắt
                                            buộc' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24" *ngIf="formSubProduct.value.manylot">
                        <button class="btn-add center-all-content" nz-button nzType="default"
                            (click)="openListLot(formSubProduct.value.lots)">
                            <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
                            {{'Danh sách lô' | translate}}</button>
                    </div>

                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #modalFooterAddUnitPrice>
        <button nz-button class="nz-hover-border"
            style="background-color: var(--ion-color-vh-white); color: var(--ion-color-vh-black)" nzType="default"
            (click)="handleCancelAdd()">
            {{"Cancel" |translate}}
        </button>
        <button [disabled]="formSubProduct.invalid" [ngStyle]="{opacity: formSubProduct.invalid ? 0.5 : 1}" nz-button
            class="nz-hover-border"
            style="background-color: var(--ion-color-vh-green); color: var(--ion-color-vh-white)"
            class="nz-hover-border-green" (click)="handleFormSubProduct(formSubProduct.value)">
            {{"Save" | translate}}
        </button>
    </ng-template>
</nz-modal>

<app-units-list *ngIf="showDrawerUnits" [dataUnits]="dataUnits" [dataUnit]="dataUnitMain"  [barcodes]="barcodes"
    [showDrawerUnits]="showDrawerUnits" (closeUnits)="closeUnits($event)" >
</app-units-list>
<app-lot-list *ngIf="show_lot_list" [data]="lot_list" [show_lot_list]="show_lot_list" [barcodes]="barcodes" (close)="handleLotList($event)">
</app-lot-list>
